import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

const constraints = {

}

export default class FBPost extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
    }



    static sendToGPTForVariable(datas, questions, model_id, step, user_response, user_options) {
        let formData = new FormData;
        formData.append('datas', JSON.stringify(datas))
        formData.append('questions', JSON.stringify(questions))
        formData.append('model_id', JSON.stringify(model_id))
        formData.append('step', JSON.stringify(step))
        formData.append('user_response', JSON.stringify(user_response))
        formData.append('user_options', JSON.stringify(user_options))
        formData.append('timeDiff', JSON.stringify(window.timeDiff))
        questions.forEach(element => {
            formData.append(element['variable'], datas[element['variable']])
        });

        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/chatgpt/sendVariable`,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
            // data: {
            //     datas: datas,
            //     questions: questions,
            //     model_id: model_id,
            //     step: step,
            //     user_response: user_response,
            //     user_options: user_options
            // }
            data: formData
        })
    }

    static sendToGPTForDemo(datas, questions, model_id, step, user_response, user_options) {
        let formData = new FormData;
        formData.append('datas', JSON.stringify(datas))
        formData.append('questions', JSON.stringify(questions))
        formData.append('model_id', JSON.stringify(model_id))
        formData.append('step', JSON.stringify(step))
        formData.append('user_response', JSON.stringify(user_response))
        formData.append('user_options', JSON.stringify(user_options))
        formData.append('timeDiff', JSON.stringify(window.timeDiff))
        questions.forEach(element => {
            formData.append(element['variable'], datas[element['variable']])
        });

        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/chatgpt/sendVariableDemo`,
            data: formData
        })
    }

    


    static sendToGPT(datas, questions, type) {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/chatgpt/send`,
            headers: {
                // "Authorization": "Bearer " + window.JWTAuth,
            },
            data: {
                datas: datas,
                questions: questions,
                type: type
            }
        })
    }

    static createArticle(datas, questions, firstAns, type, photoInfo) {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/chatgpt/create/article`,
            headers: {
                // "Authorization": "Bearer " + window.JWTAuth,
            },
            data: {
                firstAns: firstAns,
                datas: datas,
                questions: questions,
                type: type,
                photoInfo: photoInfo
            }
        })
    }

}
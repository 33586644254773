import m from 'mithril'
import {
    Dialog
} from 'components'

class ConfirmDialog {
    constructor() {

    }
    confirm(event) {
        setTimeout(() => {
            Dialog.close(true)
            m.redraw()
        }, 200)
    }
    close(event) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }
    view(vnode) {
        return m('.modal-dialog', [
            m('.modal-content', [
                m(".modal-body", [
                    m('.text-center', [
                        m('i.material-icons.text-warning', {
                            style: {
                                fontSize: '6rem'
                            }
                        }, 'warning'),
                        m('h4', [
                            m('span', '您是否確定?')
                        ])
                    ]),
                    m('.text-center', [
                        vnode.children
                    ]),
                    m('.text-right.pt-3', [
                        m('button.btn.btn-primary.rounded-0.px-4', {
                            onclick: this.confirm
                        }, [
                            '確定'
                        ]),
                        m('button.btn.btn-default.rounded-0.ml-2', {
                            onclick: this.close
                        }, [
                            '取消'
                        ])
                    ])
                ])
            ])
        ])
    }
}

export default ConfirmDialog
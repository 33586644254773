export const ManagementItems = [{
    href: '/',
    title: '首頁',
    icon: 'lnr.lnr-home',
    regex: /\//,
    auth: 'dashboard',
},
{
    title: '會員相關',
    icon: 'lnr.lnr-users',
    regex: /\/logRecord/,
    auth: '會員相關',
    children: [
        {
            href: '/master',
            title: '客戶列表',
            icon: 'lnr.lnr-user',
            regex: /\/master/,
            auth: '客戶列表'
        },
    ]
},

]



// {
//     href: '/production',
//     title: '產品',
//     icon: 'lnr.lnr-license',
//     regex: /\/production/,
//     auth: '產品'
// },
// {
//     href: '/customer',
//     title: '聯絡我們',
//     icon: 'lnr.lnr-envelope',
//     regex: /\/customer/,
//     auth: '聯絡我們'
// },

// {
//     href: '/account',
//     title: '系統設置',
//     icon: 'lnr.lnr-cog',
//     regex: /\/account/,
//     auth: '系統設置'
// },
// {
//     href: '/service',
//     title: '服務條款',
//     icon: 'lnr.lnr-bullhorn',
//     regex: /\/service/,
//     auth: '服務條款'
// },
// {
//     href: '/faq',
//     title: '問與答',
//     icon: 'lnr.lnr-question-circle',
//     regex: /\/faq/,
//     auth: '問與答'
// },




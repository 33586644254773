import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea
} from 'components'
import {
    Authority,
} from "app/models"
import moment from 'moment'


class ModelDialog {
    constructor(vnode) {
        this.model = vnode.attrs.model
        this.categories = vnode.attrs.categories
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    save() {
        this.model.save().then((response) => {
            Dialog.close(true)
        })
    }

    view({
        attrs
    }) {
        return m('.modal-dialog.modal-lg', [
            m('.modal-content', [
                m('.modal-header', [
                    m('h5.modal-title', [
                        m('span', this.model.id == 0 ? "新增模板" : "編輯模板")
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body", [
                    m('.container-fluid.px-0', [
                        m('.row.m-0.w-100', [
                            m('.col-8.pb-3',
                                m('.row.m-0.w-100', [
                                    m('.col-8.pb-3',
                                        m(TextBox, {
                                            oninput: (e) => {
                                                this.model.name = e.target.value
                                            },
                                            value: this.model.name,
                                            type: 'text',
                                            label: "模板主題",

                                            required: true,
                                            validate: () => {
                                                this.model.valid('name')
                                            },
                                            error: this.model.error('name'),
                                        })
                                    ),
                                    m('.col-4.pb-3',
                                        m(".form-group.m-0", [
                                            m("label.text-dark.mb-1.pl-1", {
                                                style: {
                                                    "font-weight": 700
                                                }
                                            }, "類別"),
                                            m("select.form-control", {
                                                onchange: (e) => {
                                                    this.model.category_id = e.target.value
                                                    if (e.target.value == "null"){
                                                        this.model.category_id = null
                                                    }
                                                }
                                            }, [
                                                m("option[selected]", {
                                                    value: null,
                                                }, "請選擇"),
                                                this.categories.map((_category) => {
                                                    return m("option", {
                                                        value: _category.id,
                                                        selected: this.model.category_id == _category.id
                                                    }, _category.name)
                                                })
                                            ])
                                        ])
                                    ),
                                    m('.col-4.pb-3',
                                        m(TextBox, {
                                            oninput: (e) => {
                                                this.model.role = e.target.value
                                            },
                                            value: this.model.role,
                                            type: 'text',
                                            label: "GPT角色",
                                            // required: true,
                                            // validate: () => {
                                            //     this.model.valid('role')
                                            // },
                                            // error: this.model.error('role'),
                                        })
                                    ),
                                    m('.col-4.pb-3',
                                        m(TextBox, {
                                            oninput: (e) => {
                                                this.model.sort = e.target.value
                                            },
                                            value: this.model.sort,
                                            type: 'number',
                                            label: "Sort",
                                        })
                                    ),
                                    m('.col-4.pb-3',
                                        m(TextBox, {
                                            oninput: (e) => {
                                                this.model.icon = e.target.value
                                            },
                                            value: this.model.icon,
                                            type: 'text',
                                            label: "ICON",
                                        })
                                    ),
                                    m('.col-12.pb-3.pt-1',
                                        m(TextArea, {
                                            oninput: (e) => {
                                                this.model.subtitle = e.target.value
                                            },
                                            value: this.model.subtitle,
                                            type: 'text',
                                            label: "描述",
                                            required: false,
                                        })
                                    ),

                                    // m('.col-12.pb-3',
                                    //     m(TextArea, {
                                    //         oninput: (e) => {
                                    //             this.model.rule = e.target.value
                                    //         },
                                    //         value: this.model.rule,
                                    //         type: 'text',
                                    //         label: "GPT任務內容",
                                    //         required: true,
                                    //         validate: () => {
                                    //             this.model.valid('rule')
                                    //         },
                                    //         error: this.model.error('rule'),
                                    //     })
                                    // ),


                                    m('.col-3',
                                        m(".form-radio.form-radio-flat.my-2.d-flex.justify-content-center.align-items-center",
                                            m("label.form-check-label.py-1", {
                                                style: {
                                                    fontSize: "1.2rem",
                                                    cursor: "pointer",
                                                    fontWeight: 700,
                                                }
                                            },
                                                [
                                                    m("input.form-check-input[type='radio']", {
                                                        checked: this.model.is_view,
                                                        onclick: () => {
                                                            this.model.is_view = !this.model.is_view
                                                        },
                                                        style: {
                                                            cursor: "pointer",
                                                        }
                                                    }),
                                                    "顯示於前台",
                                                    m("i.input-helper")
                                                ]
                                            )
                                        ),
                                    ),
                                    m('.col-3',
                                        m(".form-radio.form-radio-flat.my-2.d-flex.justify-content-center.align-items-center",
                                            m("label.form-check-label.py-1", {
                                                style: {
                                                    fontSize: "1.2rem",
                                                    cursor: "pointer",
                                                    fontWeight: 700,
                                                }
                                            },
                                                [
                                                    m("input.form-check-input[type='radio']", {
                                                        checked: this.model.is_free,
                                                        onclick: () => {
                                                            this.model.is_free = !this.model.is_free
                                                        },
                                                        style: {
                                                            cursor: "pointer",
                                                        }
                                                    }),
                                                    "是否免費",
                                                    m("i.input-helper")
                                                ]
                                            )
                                        ),
                                    ),
                                    // m('.col-3',
                                    //     m(".form-radio.form-radio-flat.my-2.d-flex.justify-content-center.align-items-center",
                                    //         m("label.form-check-label.py-1", {
                                    //             style: {
                                    //                 fontSize: "1.2rem",
                                    //                 cursor: "pointer",
                                    //                 fontWeight: 700,
                                    //             }
                                    //         },
                                    //             [
                                    //                 m("input.form-check-input[type='radio']", {
                                    //                     checked: this.model.is_gpt4,
                                    //                     onclick: () => {
                                    //                         this.model.is_gpt4 = !this.model.is_gpt4
                                    //                     },
                                    //                     style: {
                                    //                         cursor: "pointer",
                                    //                     }
                                    //                 }),
                                    //                 "使用GPT4",
                                    //                 m("i.input-helper")
                                    //             ]
                                    //         )
                                    //     ),
                                    // ),
                                ])
                            ),
                            m('.col-4.pb-3',
                                m('.row.m-0.w-100', [
                                    m('.col-12.pb-3',
                                        m(TextArea, {
                                            oninput: (e) => {
                                                this.model.preview = e.target.value
                                            },
                                            value: this.model.preview,
                                            type: 'text',
                                            label: "預覽結果",
                                            required: false,
                                            minHeight: "30rem"
                                        })
                                    ),

                                ])
                            ),



                            m('.w-100'),
                        ])
                    ])
                ]),
                m('.modal-footer', [
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#41BCC3",
                            borderRadius: "25px",
                            border: "2px solid #41BCC3",
                            fontWeight: 700,
                            fontSize: "1.25rem",
                            cursor: "pointer",
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            if (this.model.validate()) {
                                return false
                            }
                            this.save()
                        }
                    }, '儲存'),
                    m(".p-1"),
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            fontWeight: 700,
                            fontSize: "1.25rem",
                            cursor: "pointer",
                        },
                        onclick: this.close.bind(this)
                    }, '關閉')
                ])
            ])
        ])
    }
}

export default ModelDialog
import m from 'mithril'
import {
    Textbox
}
    from 'components'
import Dialog from 'components/dialog'
import {
    User
} from 'app/models'

class PasswordDialog {
    constructor(vnode) {
        this.model = vnode.attrs.model
        this.model.changePassword = true

    }
    save() {
        // if (this.model.validate()) {
        //     return false
        // }
        // else {
        //     console.log('check done1111')
        
        // }
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view(vnode) {
        return m('.modal-dialog', [
            m('.modal-content.shadow-lg', [
                m('.modal-header', [
                    m('h5.modal-title', [
                        `變更個人密碼`
                    ]),
                    m('button[type="button"].close', {
                        onclick: (e) => {
                            this.close()
                        }
                    }, [
                            m('span', m.trust('&times;'))
                        ])
                ]),
                m(".modal-body.py-3", [
                    m('.container-fluid', [
                        m('.row', [
                            m('.col-10.mx-auto', [
                                m(Textbox, {
                                    label: '新密碼',
                                    model: this.model,
                                    field: 'password',
                                    input: {
                                        type: 'password',
                                        maxlength: 100
                                    }
                                })
                            ]),
                            m('.col-10.mx-auto', [
                                m(Textbox, {
                                    label: "密碼確認",
                                    model: this.model,
                                    field: 'confirmPassword',
                                    input: {
                                        type: 'password',
                                        maxlength: 100
                                    }
                                })
                            ])
                        ])
                    ])
                ]),
                m('.modal-footer', [
                    m('button[type="button"].btn.btn-outline-secondary', {
                        onclick: this.close
                    }, '關閉'),
                    m('button[type="button"].btn.btn-primary', {
                        onclick: (e)=>{
                            e.preventDefault()
                            User.changePassword(this.model.id, this.model.password).then((response) => {
                                setTimeout(() => {
                                    Dialog.close(true)
                                    m.redraw()
                                }, 150)
                            })

                        }
                    }, '儲存')
                ])
            ])
        ])


    }
}

export default PasswordDialog
import m from 'mithril';
import stream from 'mithril/stream';
import * as Components from 'components'
import classNames from 'classnames';
import Uploader from 'components/uploader/core'

//Googlt GTM
export default class QuestionBox {
    constructor(vnode) {
        this.question_type = vnode.attrs.question_type;

        this.model = vnode.attrs.model;
        this.question = vnode.attrs.question;

        this.uploader = new Uploader()

        //錄音用 檢查
        this.record_status = vnode.attrs.record_status
        this.audioElement
        this.audioElementSource
        this.audioElementType
        this.elapsedTimeTimer
        this.timer_check
        this.audioRecordStartTime

        this.timer_check = null
        this.elapsedTimeTimer = "00:00";
        this.audioRecordStartTime;
        this.maximumRecordingTimeInHours = 1;
        this.recorderAudioAsBlob;
        this.temp_upload_name;


        this.audioRecorder = null
        if (this.question['type'] == "Recording") {
            document.addEventListener("customer_recording", (e) => {
                if (this.record_status() == "recording") {
                    this.record_status("stop")
                    clearInterval(this.timer_check);
                    this.audioRecorder.stop().then(audioAsblob => {
                        let reader = new FileReader();
                        reader.onload = (e) => {
                            let base64URL = e.target.result;
                            this.audioElementSource = base64URL;
                            let BlobType = audioAsblob.type.includes(";") ?
                                audioAsblob.type.substr(0, audioAsblob.type.indexOf(';')) : audioAsblob.type;
                            this.audioElementType = BlobType
                            this.audioElement.load();
                            let base64Audio = base64URL.split(',')[1]; // 去掉 base64 前缀
                            this.model[this.question['variable']] = base64Audio
                            this.model[this.question['question']] = this.question['variable']

                            var event = new CustomEvent("customer_recording_done");
                            document.dispatchEvent(event);
                        };
                        reader.readAsDataURL(audioAsblob);
                    })
                } else {
                    var event = new CustomEvent("customer_recording_done");
                    document.dispatchEvent(event);
                }
            });

            this.audioRecorder = {
                audioBlobs: [],/*of type Blob[]*/
                mediaRecorder: null, /*of type MediaRecorder*/
                streamBeingCaptured: null, /*of type MediaStream*/
                /** Start recording the audio 
                 * @returns {Promise} - returns a promise that resolves if audio recording successfully started
                 */
                start: () => {
                    const audioConstraints = {
                        audio: true,
                        vedio: false
                    };
                    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia(audioConstraints))) {
                        return Promise.reject(new Error('mediaDevices API or getUserMedia method is not supported in this browser.'));
                    }
                    else {
                        return navigator.mediaDevices.getUserMedia(audioConstraints).then(stream => {
                            this.audioRecorder.streamBeingCaptured = stream;
                            if (MediaRecorder.isTypeSupported('audio/webm; codecs=vp9')) {
                                var options = { audioBitrateMode: "constant", audioBitsPerSecond: 128000, mimeType: 'audio/webm; codecs=vp9' };
                            } else if (MediaRecorder.isTypeSupported('audio/webm')) {
                                var options = { audioBitrateMode: "constant", audioBitsPerSecond: 128000, mimeType: 'audio/webm' };
                            } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
                                var options = { audioBitrateMode: "constant", audioBitsPerSecond: 128000, mimeType: 'audio/mp4', videoBitsPerSecond: 100000 };
                            } else {
                                console.error("no suitable mimetype found for this device");
                            }
                            this.audioRecorder.mediaRecorder = new MediaRecorder(stream, options);
                            this.audioRecorder.audioBlobs = [];
                            this.audioRecorder.mediaRecorder.addEventListener("dataavailable", event => {
                                this.audioRecorder.audioBlobs.push(event.data);
                            });
                            this.audioRecorder.mediaRecorder.start(1000);
                        }).catch(error => {
                            console.log(error)
                        });
                    }
                },
                stop: () => {
                    return new Promise(resolve => {
                        let mimeType = this.audioRecorder.mediaRecorder.mimeType;
                        this.audioRecorder.mediaRecorder.addEventListener("stop", () => {
                            let audioBlob = new Blob(this.audioRecorder.audioBlobs, { type: mimeType });
                            resolve(audioBlob);
                        });
                        this.audioRecorder.cancel();
                    });
                },
                cancel: () => {
                    this.audioRecorder.mediaRecorder.stop();
                    this.audioRecorder.stopStream();
                    this.audioRecorder.resetRecordingProperties();
                },
                stopStream: () => {
                    this.audioRecorder.streamBeingCaptured.getTracks() //get all tracks from the stream
                        .forEach(track => track.stop()); //stop each one
                },
                resetRecordingProperties: () => {
                    this.audioRecorder.mediaRecorder = null;
                    this.audioRecorder.streamBeingCaptured = null;
                }
            }
        }
    }
    handleElapsedRecordingTime() {
        this.timer_check = setInterval(() => {
            let elapsedTime = this.computeElapsedTime(this.audioRecordStartTime);
            this.elapsedTimeTimer = elapsedTime
            m.redraw()
        }, 1000);
    }

    computeElapsedTime(startTime) {
        let endTime = new Date();
        let timeDiff = endTime - startTime;
        window.timeDiff = timeDiff
        timeDiff = timeDiff / 1000;
        let seconds = Math.floor(timeDiff % 60);
        seconds = seconds < 10 ? "0" + seconds : seconds;
        timeDiff = Math.floor(timeDiff / 60);
        let minutes = timeDiff % 60;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        timeDiff = Math.floor(timeDiff / 60);
        let hours = timeDiff % 24;
        timeDiff = Math.floor(timeDiff / 24);
        let days = timeDiff;
        let totalHours = hours + (days * 24);
        totalHours = totalHours < 10 ? "0" + totalHours : totalHours;
        if (totalHours === "00") {
            return minutes + ":" + seconds;
        } else {
            return totalHours + ":" + minutes + ":" + seconds;
        }
    }

    view(vnode) {
        if (this.question['type'] == "TextBox" || this.question['type'] == "TextArea") {
            return m('.col-12', {
                class: classNames({
                    "pb-3": this.question['type'] == "TextBox",
                    "pb-1": this.question['type'] == "TextArea",
                })
            }, [
                m(Components[this.question['type']], {
                    oninput: (e) => {
                        this.model[this.question['variable']] = e.target.value
                        this.model[this.question['question']] = this.question['variable']
                    },
                    value: this.model[this.question['variable']],
                    type: 'text',
                    label: this.question['question'],
                    required: this.question['required'],
                    tips: (this.question.hasOwnProperty('tips')) ? this.question['tips'] : null,
                })
            ])
        }
        if (this.question['type'] == "CheckBox") {
            return [
                m(".col-12.px-0", [
                    m("label.text-dark.mb-1.pl-2", {
                        style: {
                            "font-weight": 700
                        }
                    }, this.question['question']),
                    (this.question['required']) ? m("span.text-danger.pl-2", { style: { fontWeight: 700 } }, '*必填') : "",

                ]), m('.col-12.pb-3', [
                    m(".form-group.m-0", [
                        this.question.options.map((_options, index) => {
                            return m(".col-12.py-2.px-0", [
                                m(".input-group", [
                                    m(".input-group-text", {
                                        style: {
                                            backgroundColor: "#FFFFFF"
                                        }
                                    }, [
                                        m("input.mt-0", {
                                            type: "checkbox",
                                            value: _options.gpt,
                                            checked: (this.model.hasOwnProperty(this.question['variable']) && this.model[this.question['variable']].includes(_options.gpt)) ? true : false,
                                            onclick: (e) => {
                                                e.preventDefault()
                                                if (!this.model.hasOwnProperty(this.question['variable'])) {
                                                    this.model[this.question['variable']] = []
                                                }
                                                if (this.model[this.question['variable']].includes(_options.gpt)) {
                                                    this.model[this.question['variable']].splice(this.model[this.question['variable']].indexOf(_options.gpt), 1)
                                                } else {
                                                    this.model[this.question['variable']].push(e.target.value)
                                                }
                                                m.redraw()
                                            }
                                        })
                                    ]),
                                    m("input.form-control", {
                                        type: 'text',
                                        value: _options.key,
                                        disable: true,
                                        readonly: true,
                                        backgroundColor: "#FFFFFF"
                                    })
                                ]),
                            ])
                        })
                    ])

                ])
            ]
        }
        if (this.question['type'] == "Radio") {
            return [
                m('.col-12.pb-3', [
                    m(".form-group.m-0", [
                        m("label.text-dark.mb-1.pl-2", {
                            style: {
                                "font-weight": 700
                            }
                        }, this.question['question']),
                        (this.question['required']) ? m("span.text-danger.pl-2", { style: { fontWeight: 700 } }, '*必填') : "",
                        this.question.options.map((_options, index) => {
                            return m(".col-12.py-2.px-0", [
                                m(".input-group", [
                                    m(".input-group-text", {
                                        style: {
                                            backgroundColor: "#FFFFFF"
                                        }
                                    }, [
                                        m("input.mt-0", {
                                            type: "radio",
                                            value: _options.gpt,
                                            checked: (this.model.hasOwnProperty(this.question['variable']) && this.model[this.question['variable']] == _options.gpt) ? true : false,
                                            onclick: (e) => {
                                                e.preventDefault()
                                                if (this.model.hasOwnProperty(this.question['variable']) && this.model[this.question['variable']] == _options.gpt) {
                                                    delete this.model[this.question['variable']]
                                                    delete this.model[this.question['question']]
                                                } else {
                                                    this.model[this.question['variable']] = e.target.value
                                                    this.model[this.question['question']] = this.question['variable']
                                                }
                                                m.redraw()
                                            },
                                            style: {
                                                width: "20px",
                                                height: "20px",
                                            }
                                        })
                                    ]),
                                    m("input.form-control", {
                                        type: 'text',
                                        value: _options.key,
                                        disable: true,
                                        readonly: true,
                                        style: {
                                            backgroundColor: "#FFFFFF"
                                        }
                                    })
                                ]),
                            ])
                        })
                    ])
                ])
            ]
        }
        if (this.question['type'] == "Select") {
            return [
                m(".col-12.px-0", [
                    m("label.text-dark.mb-1.pl-2", {
                        style: {
                            "font-weight": 700
                        }
                    }, this.question['question']),
                ]),
                m('.col-12.pb-3', [
                    m(".form-group.m-0", [

                        (this.question['required']) ? m("span.text-danger.pl-2", { style: { fontWeight: 700 } }, '*必填') : "",
                        m("select.form-control", {
                            onchange: (e) => {
                                if (this.model[this.question['variable']] == e.target.value) {
                                    delete this.model[this.question['variable']]
                                    delete this.model[this.question['question']]
                                } else {
                                    this.model[this.question['variable']] = e.target.value
                                    this.model[this.question['question']] = this.question['variable']
                                }
                            }
                        }, [
                            m("option[selected]", {
                                value: null,
                            }, "請選擇(或選擇此項取消)"),
                            this.question['options'].map((_item) => {
                                return [m(`optgroup`, {
                                    label: _item.key
                                }),
                                _item.datas.map((_data) => {
                                    if (_data.hasOwnProperty('value') || _data.hasOwnProperty('gpt')) {
                                        return m("option", {
                                            value: _data.gpt,
                                            selected: this.model[this.question['variable']] == _data.gpt
                                        }, _data.value)
                                    } else {
                                        return m("option", {
                                            value: _data,
                                            selected: this.model[this.question['variable']] == _data
                                        }, _data)
                                    }
                                })

                                ]
                            })
                        ])
                    ])
                ])
            ]
        }
        if (this.question['type'] == "MP4Upload") {
            return [
                m(".col-12.px-0", [
                    m("label.text-dark.mb-1.pl-2", {
                        style: {
                            "font-weight": 700
                        }
                    }, this.question['question']),
                ]),
                (this.model.hasOwnProperty(this.question['variable'])) ?
                    [m(".col-11.p-2.text-center", [
                        (this.model[this.question['variable']] != null) ?
                            m("span", this.model[this.question['variable']].name) : ""
                    ]),
                    m(".col-1.d-flex.align-items-center.justify-content-center", [
                        m("i.fa.fa-trash", {
                            style: {
                                color: "#EC5C5C",
                                cursor: "pointer",
                                fontSize: "1.5rem"
                            },
                            onclick: (e) => {
                                e.preventDefault()
                                delete this.model[this.question['variable']]
                                delete this.model[this.question['question']]
                            }
                        })
                    ])] : "",
                // m(`input.g-file_browser.file_${this.question['variable']}[type="file"][name="files[]"][accept="video/mp4,video/x-m4v,video/*"]`, {
                m(`input.g-file_browser.file_${this.question['variable']}[type="file"][name="files[]"][accept="video/mp4,video/x-m4v,audio/*,audio/flac,audio/mp3,audio/x-m4a"]`, {
                    onchange: (e) => {
                        if (e.target.files.length === 0) {
                            return false
                        }
                        this.model[this.question['variable']] = e.target.files[0]
                        this.model[this.question['question']] = this.question['variable']
                    }
                }),
                m('.col-12.pb-4', [
                    m(".w-100.text-center.py-2", {
                        style: {
                            color: "#000000",
                            // backgroundColor: "#000000",
                            // borderRadius: "15px",
                            border: "1px solid #ced4da",
                            fontWeight: 700
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            $(`.file_${this.question['variable']}`).click()
                        }
                    }, m("i.fa-solid.fa-cloud-arrow-up.pr-2"), "上傳影音")
                ])
            ]
        }
        if (this.question['type'] == "Photo") {
            return [
                (this.model.hasOwnProperty(this.question['variable'])) ?
                    [m(".col-12.p-2.text-center", [
                        (this.model[this.question['variable']] != null) ?
                            m("img.img-fluid", {
                                src: this.model[this.question['variable']],
                                style: {
                                    maxHeight: "240px"
                                }
                            }) : ""
                    ]),
                    m(".col-1.d-flex.align-items-center.justify-content-center", [
                        m("i.fa.fa-trash", {
                            style: {
                                color: "#EC5C5C",
                                cursor: "pointer",
                                fontSize: "1.5rem"
                            },
                            onclick: (e) => {
                                e.preventDefault()
                                delete this.model[this.question['variable']]
                                delete this.model[this.question['question']]
                            }
                        })
                    ])] : "",
                m(`input.g-file_browser.file_${this.question['variable']}[type="file"][name="files[]"][accept="image/*"]`, {
                    onchange: (e) => {
                        if (e.target.files.length === 0) {
                            return false
                        }

                        if (!this.uploader.filterImageExtension(e.target.files)) {
                            alert('不支援上傳的檔案格式, 請選擇圖片檔!')
                            return false
                        }

                        this.uploader.uploadImage(e.target.files[0], {
                            url: `${window.BASE_URL}/api/upload/photo`,
                            width: 480,
                            height: 480,
                            folder: "photo",
                            type: "photo",
                            preview: "photo",
                            user: window.USER_ID,
                        }).then((response) => {
                            this.model[this.question['variable']] = response.path
                            this.model[this.question['question']] = this.question['variable']
                            m.redraw()
                        })
                    }
                }),
                m(".col-12.pt-2", [
                    m(Components['TextBox'], {
                        oninput: (e) => {
                            this.question.options[0].title = e.target.value
                        },
                        value: this.question.options[0].title,
                        type: 'text',
                        label: this.question['question'],
                        tips: (this.question.hasOwnProperty('tips')) ? this.question['tips'] : null,
                        required: this.question['required'],
                        class: ".mb-0",
                    })
                ]),

                m('.col-12.pb-4', [
                    m(".w-100.text-center.py-2", {
                        style: {
                            color: "#000000",
                            // backgroundColor: "#000000",
                            // borderRadius: "15px",
                            border: "1px solid #ced4da",
                            fontWeight: 700
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            $(`.file_${this.question['variable']}`).click()
                        }
                    }, m("i.fa-solid.fa-cloud-arrow-up.pr-2"), "上傳圖片")
                ])
            ]
        }
        if (this.question['type'] == "DallE2") {
            return [
                (this.model.hasOwnProperty(this.question['variable'])) ?
                    m(".col-12.p-2.text-center", [
                        (this.model[this.question['variable']] != null) ?
                            m("img.img-fluid", {
                                src: this.model[this.question['variable']],
                                style: {
                                    maxHeight: "240px"
                                }
                            }) : ""
                    ]) : "",
                m(".col-12.p-2.text-center", [
                    (this.question.options[0]['mask'] != null) ?
                        m("img.img-fluid", {
                            src: this.question.options[0]['mask'],
                            style: {
                                maxHeight: "240px"
                            }
                        }) : ""
                ]),
                m(`input.g-file_browser.file_${this.question['variable']}[type="file"][name="files[]"][accept="image/*"]`, {
                    onchange: (e) => {
                        if (e.target.files.length === 0) {
                            return false
                        }

                        if (!this.uploader.filterImageExtensionByType(e.target.files, [".png"])) {
                            alert('不支援上傳的檔案格式, 請選擇圖片檔!')
                            return false
                        }

                        this.uploader.uploadImage(e.target.files[0], {
                            url: `${window.BASE_URL}/api/upload/photo`,
                            width: 480,
                            height: 480,
                            folder: "DallE2",
                            type: "photo",
                            preview: "DallE2",
                            user: window.USER_ID,
                        }).then((response) => {
                            this.model[this.question['variable']] = response.data
                            this.model[this.question['question']] = this.question['variable']
                            m.redraw()
                        })
                    }
                }),
                m(`input.g-file_browser.file_${this.question['variable']}_mask[type="file"][name="files[]"][accept="image/*"]`, {
                    onchange: (e) => {
                        if (e.target.files.length === 0) {
                            return false
                        }

                        if (!this.uploader.filterImageExtension(e.target.files)) {
                            alert('不支援上傳的檔案格式, 請選擇圖片檔!')
                            return false
                        }
                        this.uploader.uploadImage(e.target.files[0], {
                            url: `${window.BASE_URL}/api/upload/photo`,
                            width: 480,
                            height: 480,
                            folder: "DallE2",
                            type: "photo",
                            preview: "DallE2",
                            user: window.USER_ID,
                        }).then((response) => {
                            this.question.options[0].mask = response.data
                            m.redraw()
                        })
                    }
                }),
                m('.col-12.pb-4', [
                    m(".w-100.text-center.py-2", {
                        style: {
                            color: "#000000",
                            // backgroundColor: "#000000",
                            // borderRadius: "15px",
                            border: "1px solid #ced4da",
                            fontWeight: 700
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            $(`.file_${this.question['variable']}`).click()
                        }
                    }, m("i.fa-solid.fa-cloud-arrow-up.pr-2"), "上傳圖片")
                ]),
                m('.col-12.pb-4', [
                    m(".w-100.text-center.py-2", {
                        style: {
                            color: "#000000",
                            border: "1px solid #ced4da",
                            fontWeight: 700
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            $(`.file_${this.question['variable']}_mask`).click()
                        }
                    }, m("i.fa-solid.fa-cloud-arrow-up.pr-2"), "上傳Mask")
                ]),
            ]
        }
        if (this.question['type'] == "GoogleTransferIamge") {
            return [
                (this.model.hasOwnProperty(this.question['variable'])) ?
                    [
                        m(".col-11.p-2.text-center", [
                            (this.model[this.question['variable']] != null) ?
                                m("img.img-fluid", {
                                    src: this.model[this.question['variable']],
                                    style: {
                                        maxHeight: "240px"
                                    }
                                }) : ""
                        ]),
                        m(".col-1.d-flex.align-items-center.justify-content-center", [
                            m("i.fa.fa-trash", {
                                style: {
                                    color: "#EC5C5C",
                                    cursor: "pointer",
                                    fontSize: "1.5rem"
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    delete this.model[this.question['variable']]
                                    delete this.model[this.question['question']]
                                }
                            })
                        ])
                    ] : "",
                m(`input.g-file_browser.file_${this.question['variable']}[type="file"][name="files[]"][accept="image/*"]`, {
                    onchange: (e) => {
                        if (e.target.files.length === 0) {
                            return false
                        }

                        if (!this.uploader.filterImageExtension(e.target.files)) {
                            alert('不支援上傳的檔案格式, 請選擇圖片檔!')
                            return false
                        }
                        this.uploader.uploadImage(e.target.files[0], {
                            url: `${window.BASE_URL}/api/upload/photo`,
                            width: 480,
                            height: 480,
                            folder: "GoogleTransferIamge",
                            type: "photo",
                            preview: "GoogleTransferIamge",
                            user: window.USER_ID,
                        }).then((response) => {
                            this.model[this.question['variable']] = response.path
                            this.model[this.question['question']] = this.question['variable']
                            m.redraw()
                        })
                    }
                }),
                m('.col-12.pb-4', [
                    m(".w-100.text-center.py-2", {
                        style: {
                            color: "#000000",
                            // backgroundColor: "#000000",
                            // borderRadius: "15px",
                            border: "1px solid #ced4da",
                            fontWeight: 700
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            $(`.file_${this.question['variable']}`).click()
                        }
                    }, m("i.fa-solid.fa-cloud-arrow-up.pr-2"), "上傳圖片 - 圖片文字辨識")
                ])
            ]
        }
        if (this.question['type'] == "PhotoSelect") {
            return m(".col-12.pb-1", [
                m("label.text-dark.mb-1.pl-2", {
                    style: {
                        "font-weight": 700
                    }
                }, this.question['question']),
                m(".w-100", {
                    style: {
                        "overflow-x": "auto",
                        "white-space": "nowrap",
                        "webkit-overflow-scrolling": "touch",
                        "-ms-overflow-style": "none",
                        "scrollbar-width": (!window.isMobile) ? "auto" : "none",
                        "-ms-overflow-style": "-ms-autohiding-scrollbar",
                    }
                }, [
                    this.question['options'].map((_option) => {
                        return m(".pr-3.d-inline-block.pb-2", {
                            onclick: (e) => {
                                e.preventDefault()
                                if (this.model[this.question['variable']] == _option.gpt) {
                                    delete this.model[this.question['variable']]
                                    delete this.model[this.question['question']]
                                } else {
                                    this.model[this.question['variable']] = _option.gpt
                                    this.model[this.question['question']] = this.question['variable']
                                }
                            }
                        }, [
                            m(".justify-content-center.align-items-center", {
                                style: {
                                    maxHeight: "210px",
                                    minHeight: "210px",
                                    minWidth: "240px",
                                    minWidth: "240px",
                                    backgroundImage: `url(${_option.url})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    backgroundSize: "contain",
                                    borderRadius: "5px"
                                },
                            }),
                            m(".w-100.d-flex.justify-content-center.align-itens-center", {
                                style: {
                                    color: (this.model[this.question['variable']] == _option.gpt) ? "#FFFFFF" : "#828282",
                                    backgroundColor: (this.model[this.question['variable']] == _option.gpt) ? "#828282" : "#FFFFFF",
                                    borderRadius: "10px",
                                    border: (this.model[this.question['variable']] == _option.gpt) ? "1px solid #FFFFFF" : "1px solid #828282",
                                    fontWeight: 400,
                                    fontSize: "1.1rem",
                                    cursor: "pointer",
                                }
                            }, [
                                m("span", _option.value),
                            ])
                        ])
                    })
                ])
            ])
        }
        if (this.question['type'] == "ColorSelect") {
            if (this.model.hasOwnProperty(this.question['variable'])) {
                return [m('.col-11.pb-3', [
                    m(Components['TextBox'], {
                        oninput: (e) => {
                            this.model[this.question['variable']] = e.target.value
                            this.model[this.question['question']] = this.question['variable']
                        },
                        style: {
                            "min-height": "40px"
                        },
                        value: this.model[this.question['variable']],
                        type: 'color',
                        label: this.question['question'],
                        required: this.question['required'],
                        tips: (this.question.hasOwnProperty('tips')) ? this.question['tips'] : null,
                    })
                ]),
                m(".col-1.pb-3.d-flex.align-items-end.justify-content-center", [
                    m("i.fa.fa-trash", {
                        style: {
                            color: "#EC5C5C",
                            cursor: "pointer",
                            fontSize: "1.5rem"
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            delete this.model[this.question['variable']]
                            delete this.model[this.question['question']]
                        }
                    })
                ])
                ]

            } else {
                return m('.col-12.pb-3', [
                    m('label.mb-1.pl-1', {

                        style: {
                            "font-weight": 700
                        }
                    },
                        m("span", this.question['question']),
                    ),
                    m("span.d-block.text-center.py-2", {
                        style: {
                            fontWeight: 600,
                            fontSize: "0.9rem",
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            cursor: "pointer",
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            this.model[this.question['variable']] = "#000000"
                            this.model[this.question['question']] = this.question['variable']
                        }
                    }, "選擇顏色")
                ])
            }

        }
        if (this.question['type'] == "Recording" && this.question_type == "production") {
            if (this.record_status() != null) {
                return [m('.col-11.pb-3', [
                    m(".row.w-100.m-0", [
                        (this.record_status() == "stop") ?
                            m("audio[controls].audio-element.hide", {
                                src: this.audioElementSource,
                                type: this.audioElementType,
                                style: {
                                    display: "none"
                                },
                                oncreate: (e) => {
                                    this.audioElement = e.dom
                                    console.log(this.audioElement)
                                }
                            }) : "",
                        m(".col-12.px-0", [
                            m("label.text-dark.mb-1.pl-2", {
                                style: {
                                    "font-weight": 700
                                }
                            }, this.question['question']),
                        ]),
                        m(".col-12.px-0", [
                            m(".row.w-100.m-0.d-flex.justify-content-center", [
                                m(".col-1.px-0.text-center.d-flex.justify-content-center.align-items-center", [
                                    m("span", {
                                        class: classNames({
                                            "red-recording-dot": this.record_status() == "recording"
                                        }),
                                        style: {
                                            fontSize: "1.25rem",
                                            color: "red"
                                        }
                                    }, "●")
                                ]),
                                m(".col-3.d-flex.justify-content-center.align-items-center", [
                                    m("span", {
                                        style: {
                                            fontSize: "1.25rem"
                                        }
                                    }, this.elapsedTimeTimer)
                                ]),
                                (this.record_status() == "recording") ? [
                                    m(".col-3.d-flex.justify-content-center.align-items-center", {
                                        onclick: (e) => {
                                            e.preventDefault()
                                            this.record_status("stop")
                                            clearInterval(this.timer_check);
                                            this.audioRecorder.stop().then(audioAsblob => {
                                                let reader = new FileReader();
                                                reader.onload = (e) => {
                                                    let base64URL = e.target.result;
                                                    this.audioElementSource = base64URL;
                                                    let BlobType = audioAsblob.type.includes(";") ?
                                                        audioAsblob.type.substr(0, audioAsblob.type.indexOf(';')) : audioAsblob.type;
                                                    this.audioElementType = BlobType
                                                    this.audioElement.load();
                                                    let base64Audio = base64URL.split(',')[1]; // 去掉 base64 前缀

                                                    // var base64 = base64URL.substr(base64URL.lastIndexOf(',') + 1);
                                                    // var decoded = atob(base64);
                                                    // var reconstructedAudioBlob = new Blob([decoded], { type: "audio/webm;codecs=opus" });

                                                    // this.model[this.question['variable']] = reconstructedAudioBlob

                                                    this.model[this.question['variable']] = base64Audio
                                                    this.model[this.question['question']] = this.question['variable']

                                                    m.redraw()
                                                };
                                                reader.readAsDataURL(audioAsblob);
                                            }).catch(error => {
                                                console.log(error)
                                            });
                                        },
                                    }, [
                                        m("i.fa.fa-stop.text-center.py-2", {
                                            style: {
                                                fontSize: "1.25rem",
                                                borderRadius: "25px",
                                                border: "2px solid #000000",
                                                fontWeight: 700,
                                                cursor: "pointer",
                                                width: "125px"
                                            }
                                        })
                                    ]),
                                ] : [
                                    m(".col-3.d-flex.justify-content-center.align-items-center", {
                                        onclick: (e) => {
                                            e.preventDefault()
                                            this.audioElement.play();
                                        },
                                    }, [
                                        m("i.fa.fa-play.text-center.py-2", {
                                            style: {
                                                fontSize: "1.25rem",
                                                borderRadius: "25px",
                                                border: "2px solid #000000",
                                                fontWeight: 700,
                                                cursor: "pointer",
                                                width: "125px"
                                            }
                                        })
                                    ]),
                                ],
                                m(".col-1.d-flex.align-items-center.justify-content-center", [
                                    m("i.fa.fa-trash", {
                                        style: {
                                            color: "#EC5C5C",
                                            cursor: "pointer",
                                            fontSize: "1.5rem"
                                        },
                                        onclick: (e) => {
                                            e.preventDefault()
                                            // this.audioRecorder.cancel();
                                            this.record_status(null)
                                            delete this.model[this.question['variable']]
                                            delete this.model[this.question['question']]
                                        }
                                    })
                                ])

                            ]),
                        ]),
                    ])
                ]),

                ]

            } else {
                return m('.col-12.pb-3', [
                    m('label.mb-1.pl-1', {

                        style: {
                            "font-weight": 700
                        }
                    },
                        m("span", this.question['question']),
                    ),
                    m("span.d-block.text-center.py-2", {
                        style: {
                            fontWeight: 600,
                            fontSize: "1.2rem",
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            cursor: "pointer",
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            this.record_status("recording")
                            this.audioRecorder.start().then(() => {
                                this.audioRecordStartTime = new Date()
                                this.handleElapsedRecordingTime()
                            }).catch(error => {
                                console.log(error)
                            });

                        }
                    }, m("i.fa.fa-microphone"))
                ])
            }
        }
        if (this.question['type'] == "DATA") {
            return [
                m(".col-4.px-0", [
                    m("label.text-dark.mb-1.pl-2", {
                        style: {
                            "font-weight": 700
                        }
                    }, this.question['question']),
                ]),
                m('.col-8.text-right.px-0', [
                    m("label.text-danger.mb-1.pl-2", {
                        style: {
                            "font-weight": 700
                        }
                    }, "*僅支援PDF,Word,Excel,PowerPoint"),
                ]),
                (this.temp_upload_name != null) ?
                    [m(".col-12.p-2.text-center", [
                        m("span", this.temp_upload_name)
                    ]),
                    m(".col-1.d-flex.align-items-center.justify-content-center", [
                        m("i.fa.fa-trash", {
                            style: {
                                color: "#EC5C5C",
                                cursor: "pointer",
                                fontSize: "1.5rem"
                            },
                            onclick: (e) => {
                                e.preventDefault()
                                this.temp_upload_name = null
                                delete this.model[this.question['variable']]
                                delete this.model[this.question['question']]
                            }
                        })
                    ])] : "",
                m(`input.g-file_browser.file_${this.question['variable']}[type="file"][name="files[]"][accept="application/pdf,.docx,.pptx,.xlsx,.xls,.ods,.csv,.xml"]`, {
                    onchange: (e) => {
                        if (e.target.files.length === 0) {
                            return false
                        }

                        if (!this.uploader.filterImageExtensionByType(e.target.files, ['.pdf', '.docx', '.pptx', '.xlsx', '.xls','.ods','.csv','.xml'])) {
                            alert('不支援上傳的檔案格式, 請選擇圖片檔!')
                            return false
                        }

                        this.temp_upload_name = e.target.files[0].name

                        this.uploader.uploadDataToServer(`${window.BASE_URL}/api/upload/document`, e.target.files[0], "no").then((response) => {
                            if (response['status'] == false) {
                                if (response['isTransfer']) {
                                    Swal.fire({
                                        title: "此PDF需進行轉換",
                                        text: response['message'],
                                        showCancelButton: true,
                                        confirmButtonText: "確認",
                                        cancelButtonText: "取消",
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.uploader.uploadDataToServer(`${window.BASE_URL}/api/upload/document`, e.target.files[0], "confirm").then((response) => {
                                                // this.model.append(this.question['variable'], e.target.files[0])
                                                this.model[this.question['variable']] = response.message
                                                this.model[this.question['question']] = this.question['variable']

                                            })
                                        }
                                    });
                                } else {
                                    Swal.fire({
                                        title: "發生錯誤",
                                        text: response['message'],
                                        showCancelButton: true,
                                        confirmButtonText: "確認",
                                        cancelButtonText: "取消",
                                    })
                                }

                            } else {
                                this.model[this.question['variable']] = response.message
                                this.model[this.question['question']] = this.question['variable']
                            }
                        })
                    }
                }),
                m('.col-12.pb-4', [
                    m(".w-100.text-center.py-2", {
                        style: {
                            color: "#000000",
                            // backgroundColor: "#000000",
                            // borderRadius: "15px",
                            border: "1px solid #ced4da",
                            fontWeight: 700
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            $(`.file_${this.question['variable']}`).click()
                        }
                    }, m("i.fa-solid.fa-cloud-arrow-up.pr-2"), "上傳文件")
                ])
            ]
        }
        if (this.question['type'] == "Template") {
            if (this.question.hasOwnProperty('options') && this.question['options'].length > 0 && this.question['options'][0].hasOwnProperty('isDefault') && !this.question['options'][0].isDefault) {
                return [m(".col-4.px-0", [
                    m("label.text-dark.mb-1.pl-2", {
                        style: {
                            "font-weight": 700
                        }
                    }, this.question['question']),
                ]),
                m('.col-8.text-right.px-0', [
                    m("label.text-danger.mb-1.pl-2", {
                        style: {
                            "font-weight": 700
                        }
                    }, "*僅支援Word,Excel"),
                ]),
                (this.temp_upload_name != null) ?
                    [
                        m(".col-11.p-2.text-center", [
                            m("span", this.temp_upload_name)
                        ]),
                        m(".col-1.d-flex.align-items-center.justify-content-center", [
                            m("i.fa.fa-trash", {
                                style: {
                                    color: "#EC5C5C",
                                    cursor: "pointer",
                                    fontSize: "1.5rem"
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.temp_upload_name = null
                                    delete this.model[this.question['variable']]
                                    delete this.model[this.question['question']]
                                }
                            })
                        ])
                    ] : "",
                m(`input.g-file_browser.file_${this.question['variable']}[type="file"][name="files[]"][accept=".docx,.xlsx,.xls"]`, {
                    onchange: (e) => {
                        if (e.target.files.length === 0) {
                            return false
                        }

                        if (!this.uploader.filterImageExtensionByType(e.target.files, ['.docx', '.xlsx', '.xls'])) {
                            alert('不支援上傳的檔案格式, 請選擇正確的文件檔!')
                            return false
                        }
                        this.temp_upload_name = e.target.files[0].name
                        this.model[this.question['variable']] = e.target.files[0]
                        this.model[this.question['question']] = this.question['variable']
                    },

                }),
                m('.col-12.pb-4', [
                    m(".w-100.text-center.py-2", {
                        style: {
                            color: "#000000",
                            // backgroundColor: "#000000",
                            // borderRadius: "15px",
                            border: "1px solid #ced4da",
                            fontWeight: 700
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            $(`.file_${this.question['variable']}`).click()
                        }
                    }, m("i.fa-solid.fa-cloud-arrow-up.pr-2"), "上傳文件")
                ])
                ]
            }

        }
    }
}

import m from 'mithril'
import {
    Dialog,
} from 'components'
import ModelComponent from './dialogs/model_edit'
import {
    Setting,
    Model,
    Category
} from 'app/models'
import { GoHome } from '../../../components'

export default class SettingPage {
    constructor() {
        this.models = []
        this.else_models = []
        Category.fetch().then(() => {
            Setting.fetchModels().then(() => {
                this.SortByCategory()
            })
        })
        this.open_category = false
    }
    SortByCategory() {
        this.models = []
        this.else_models = []
        Category.datas.map((_category) => {
            this.models.push({
                categoryId: _category.id,
                categoryName: _category.name,
                datas: [],
                is_view: true
            })
        })
        Setting.datas.map((_model) => {
            let _find_index = this.models.map(e => e.categoryId).indexOf(_model.category_id);
            if (_find_index > -1) {
                this.models[_find_index].datas.push(_model)
            } else {
                this.else_models.push(_model)
            }
        })
        m.redraw()
    }
    view() {
        return [
            m(GoHome),
            m(".px-2.py-4.position-relative.container",
                m(".row.w-100.m-0", [
                    m(".col-12.text-center.pb-3", [
                        m("span.font-weight-bold", {
                            style: {
                                fontSize: "1.75rem"
                            }
                        }, "模板設定")
                    ]),
                    //種類設定
                    m(".position-absolute", {
                        style: {
                            top: "20px",
                            left: "20px",
                            cursor: "pointer"
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            this.open_category = !this.open_category
                        }
                    }, [
                        m(".row.m-0.w-100", [
                            m(".col-12.text-center", [
                                m("span.px-3", {
                                    style: {
                                        color: "#000000",
                                        // backgroundColor: "#64AECC",
                                        borderRadius: "25px",
                                        border: "2px solid #000000",
                                        fontWeight: 700,
                                    },

                                }, "種類設定")
                            ]),
                        ]),
                    ]),
                    //種類設定
                    m(".position-absolute", {
                        style: {
                            top: "60px",
                            left: "20px",
                            zIndex: 2048,
                            width: "50%",
                            backgroundColor: "#FAFAFA",
                        }
                    }, [
                        (this.open_category) ?
                            m(".row.w-100.h-100.m-0", {
                                style: {
                                    borderRadius: "25px",
                                    border: "2px solid #478291"
                                }
                            }, [
                                m(".col-md-12.col-12.p-1", [
                                    m(".row.w-100.m-0", [
                                        Category.datas.map((_category, index) => {
                                            return [
                                                m(".col-md-12.col-12.py-3", [
                                                    m(".row.w-100.m-0", [
                                                        m(".col-md-1.col-1.p-1", [
                                                            m("span", { style: { fontSize: "1.25rem", fontWeight: 500 } }, index + 1),
                                                        ]),
                                                        m(".col-md-8.col-8.p-1", [
                                                            m("span.py-1.px-3", {
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                },
                                                                style: { fontSize: "1.25rem", fontWeight: 500 }
                                                            }, _category.name),
                                                        ]),
                                                        m(".col-md-3.col-3.p-1", [
                                                            m("i.fa.fa-pencil.py-1.text-primary.px-2", {
                                                                style: {
                                                                    fontSize: "1.5rem",
                                                                    // color: "#A7A3E2"
                                                                },
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    Swal.fire({
                                                                        input: "text",
                                                                        inputLabel: "請輸入要修改的名字",
                                                                        inputPlaceholder: "請輸入文字",
                                                                        inputValue: _category.name,
                                                                        confirmButtonText: "確認",
                                                                        showCancelButton: true,
                                                                        cancelButtonText: "取消",
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            Swal.fire({
                                                                                input: "text",
                                                                                inputLabel: "請輸入要修改的順序",
                                                                                inputPlaceholder: "請輸入數字",
                                                                                inputValue: _category.sort,
                                                                                confirmButtonText: "確認",
                                                                                showCancelButton: true,
                                                                                cancelButtonText: "取消",
                                                                            }).then((response) => {
                                                                                if (response.isConfirmed) {
                                                                                    const _old_category = new Category({
                                                                                        id: _category.id,
                                                                                        name: result.value,
                                                                                        sort: response.value
                                                                                    })
                                                                                    _old_category.save().then((_re) => {
                                                                                        Category.fetch().then(() => {
                                                                                            this.SortByCategory()
                                                                                        })
                                                                                    })

                                                                                }
                                                                            });
                                                                        }
                                                                    });
                                                                }
                                                            }),
                                                            m("i.fa.fa-trash-o.py-1.text-danger.px-2", {
                                                                style: {
                                                                    fontSize: "1.5rem",
                                                                    // color: "#A7A3E2"
                                                                },
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    Swal.fire({
                                                                        title: "你確定要刪除此流程嗎？",
                                                                        text: "注意：此流程刪除後，該流程所附屬咒語及問題皆會消失！",
                                                                        showCancelButton: true,
                                                                        confirmButtonText: "確認",
                                                                        cancelButtonText: "取消",
                                                                        icon: "warning"

                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            Category.remove(_category.id).then((_re) => {
                                                                                Category.fetch().then(() => {
                                                                                    this.SortByCategory()
                                                                                })
                                                                            })
                                                                        }
                                                                    });
                                                                }
                                                            }),
                                                        ]),
                                                    ])
                                                ]),
                                            ]
                                        }),
                                        m(".col-md-12.col-12.d-flex.justify-content-center.align-items-center.py-2", [
                                            m(".btn.py-1.px-3", {
                                                style: {
                                                    color: "#000000",
                                                    // backgroundColor: "#64AECC",
                                                    borderRadius: "25px",
                                                    border: "2px solid #000000",
                                                    fontWeight: 700,
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    Swal.fire({
                                                        input: "text",
                                                        inputLabel: "請輸入要新增的名字",
                                                        inputPlaceholder: "請輸入文字",
                                                        // inputValue: _step.f,
                                                        confirmButtonText: "確認",
                                                        showCancelButton: true,
                                                        cancelButtonText: "取消",
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            Swal.fire({
                                                                input: "text",
                                                                inputLabel: "請輸入排序",
                                                                inputPlaceholder: "請輸入數字",
                                                                // inputValue: _step.f,
                                                                confirmButtonText: "確認",
                                                                showCancelButton: true,
                                                                cancelButtonText: "取消",
                                                            }).then((response) => {
                                                                if (response.isConfirmed) {
                                                                    const _category = new Category({
                                                                        name: result.value,
                                                                        sort: response.value
                                                                    })
                                                                    _category.save().then((_re) => {
                                                                        Category.fetch().then(() => {
                                                                            this.SortByCategory()
                                                                        })
                                                                    })

                                                                }
                                                            });
                                                        }
                                                    });

                                                }
                                            }, "新增種類"),
                                        ]),
                                    ])
                                ]),
                            ]) : [],
                    ]),
                    //新增模板
                    m(".position-fixed", {
                        style: {
                            bottom: "30px",
                            right: "35px",
                            cursor: "pointer",
                            zIndex: 1024
                        },
                        onclick: (e) => {
                            e.preventDefault()
                        }
                    }, [
                        m("div.w-100.px-4.py-2", {
                            onclick: (e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                Dialog.show(ModelComponent, {
                                    attrs: {
                                        model: new Model(),
                                        categories: Category.datas
                                    },
                                    didHide: (returnValue) => {
                                        Setting.fetchModels().then(() => {
                                            this.SortByCategory()
                                        })
                                    }
                                })
                            },
                            style: {
                                color: "#FFFFFF",
                                borderRadius: "25px",
                                border: "1px solid #0f2027",
                                fontWeight: 500,
                                fontSize: "1.25rem",
                                background: "#0f2027",
                                background: "-webkit-linear-gradient(to right, #0f2027, #203a43, #2c5364)",
                                background: "linear-gradient(to right, #0f2027, #203a43, #2c5364)",

                            }
                        }, "+"),
                    ]),
                    //全部縮小
                    // m(".position-fixed", {
                    //     style: {
                    //         top: "30px",
                    //         right: "35px",
                    //         cursor: "pointer",
                    //         zIndex: 2048
                    //     },
                    //     onclick: (e) => {
                    //         e.preventDefault()

                    //     }
                    // }, [
                    //     m("div.w-100.px-4.py-2", {
                    //         onclick: (e) => {
                    //             e.preventDefault()
                    //             e.stopPropagation()
                    //             this.models.map((_model, index) => {
                    //                 this.models[index].is_view = false
                    //             })                             
                    //         },
                    //         style: {
                    //             color: "#000000",
                    //             borderRadius: "25px",
                    //             fontWeight: 500,
                    //             fontSize: "1.25rem",
                    //             backgroundImage: "linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%)"
                    //         }
                    //     }, "全部縮小"),
                    // ]),

                    m(".col-12.text-center", [
                        m(".row.w-100.m-0", [
                            this.models.map((_type) => {
                                return [
                                    m(".col-md-12.col-12.pt-3.pb-2.text-left", {
                                        onclick: (e) => {
                                            e.preventDefault()
                                            _type.is_view = !_type.is_view
                                        }
                                    }, [
                                        m(".d-flex.justify-content-between", [
                                            m("", [
                                                m("span", { style: { fontSize: "1.5rem", fontWeight: 700, color: "#2A6D71" } }, "▏"),

                                                m("span", { style: { fontSize: "1.5rem", fontWeight: 700 } }, _type.categoryName),
                                            ]),
                                            m("span", { style: { fontSize: "1.5rem", fontWeight: 700 } }, "(" + _type.datas.length + ")"),
                                        ])
                                    ]),
                                    m(".col-md-12.col-12", {
                                        style: {
                                            borderBottom: "2px solid #2A6D71",
                                            height: "2px"
                                        }
                                    }),
                                    m(".col-md-12.col-12", {
                                        style: {
                                            height: "6px"
                                        }
                                    }),
                                    (_type.is_view) ? [
                                        _type.datas.map((_data) => {
                                            return [
                                                m(".col-md-3.col-12.p-1", [
                                                    m(".position-relative", {
                                                        style: {
                                                            borderRadius: "25px",
                                                            border: (!_data.is_view) ? "2px solid #FBDFDF" : "2px solid #000000"
                                                        },
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            m.route.set(`/rebalanceSetting/detail/${_data.id}`)
                                                        }
                                                    }, [
                                                        m(".card-body", {
                                                            style: {
                                                                borderRadius: "25px",
                                                                backgroundColor: "#FFFFFF"
                                                            }
                                                        }, [
                                                            m("h5.card-title.pb-1", {
                                                                style: {
                                                                    fontWeight: 700,
                                                                    fontSize: (_data.name.length >= 12) ? "0.9rem" : "1.3rem"
                                                                }
                                                            }, _data.id + "." + _data.name),
                                                            // m("h6.card-subtitle.mb-2.text-muted", _data.role),

                                                            m("p.card-text.text-muted.py-3", {
                                                                style: {
                                                                    fontSize: "0.9rem",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    "-webkit-line-clamp": 2,

                                                                }
                                                            }, _data.subtitle),
                                                            m(".py-1.d-inline.px-3", {
                                                                style: {
                                                                    color: "#5780C6",
                                                                    borderRadius: "25px",
                                                                    border: "2px solid #5780C6",
                                                                    fontWeight: 700,
                                                                    fontSize: "1rem",
                                                                    cursor: "pointer",
                                                                },
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    Dialog.show(ModelComponent, {
                                                                        attrs: {
                                                                            model: new Model(_data),
                                                                            categories: Category.datas
                                                                        },
                                                                        didHide: (returnValue) => {
                                                                            Setting.fetchModels().then(() => {
                                                                                this.SortByCategory()
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            }, "基本"),
                                                            m(".px-2.d-inline"),
                                                            m(".py-1.d-inline.px-3", {
                                                                style: {
                                                                    color: "#59B3A7",
                                                                    borderRadius: "25px",
                                                                    border: "2px solid #59B3A7",
                                                                    fontWeight: 700,
                                                                    fontSize: "1rem",
                                                                    cursor: "pointer",
                                                                },
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    m.route.set(`/rebalanceSetting/detail/${_data.id}`)
                                                                }
                                                            }, "內容"),
                                                            m(".px-2.d-inline"),
                                                            m(".py-1.d-inline.px-3", {
                                                                style: {
                                                                    color: "#D53C3C",
                                                                    borderRadius: "25px",
                                                                    border: "2px solid #D53C3C",
                                                                    fontWeight: 700,
                                                                    fontSize: "1rem",
                                                                    cursor: "pointer",
                                                                },
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    Swal.fire({
                                                                        title: "你確定要刪除此模板嗎？",
                                                                        showCancelButton: true,
                                                                        confirmButtonText: "確認",
                                                                        cancelButtonText: "取消",
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            Model.remove(_data.id).then((_res) => {
                                                                                Setting.fetchModels()
                                                                            })
                                                                            m.redraw()
                                                                        }
                                                                    });

                                                                }
                                                            }, "刪除"),
                                                        ]),
                                                        m(".position-absolute", {
                                                            style: {
                                                                top: "10px",
                                                                left: "20px",
                                                                fontSize: "1.25rem",
                                                                color: "#EAEAEA"
                                                            }
                                                        }, m(`i.fa.${_data.icon}`)),
                                                        (!_data.is_view) ?
                                                            m(".position-absolute", {
                                                                style: {
                                                                    top: "10px",
                                                                    right: "10px",
                                                                    fontSize: "1.25rem",
                                                                    color: "#BC1D1D"
                                                                }
                                                            }, m("i.fa-regular.fa-eye-slash")) : "",
                                                        (_data.is_free) ?
                                                            m(".position-absolute", {
                                                                style: {
                                                                    top: "10px",
                                                                    right: "10px",
                                                                    fontSize: "1.25rem",
                                                                    boxShadow: "0 0 0 9px #FF0000",
                                                                    background: "#FF0000",
                                                                    clipPath: "inset(0-100%)",
                                                                    inset: "0 0 auto auto",
                                                                    transformOrigin: " 0 0",
                                                                    transform: "translate(6.3%) rotate(30deg)",
                                                                    color: "#FFFFFF"

                                                                }
                                                            }, "Free") : ""
                                                    ]),
                                                ]),
                                            ]
                                        })
                                    ] : ""
                                ]
                            }),
                            m(".col-md-12.col-12.pt-3.pb-2.text-left", [
                                m(".d-flex.justify-content-between", [
                                    m("", [
                                        m("span", { style: { fontSize: "1.5rem", fontWeight: 700, color: "#2A6D71" } }, "▏"),
                                        m("span", { style: { fontSize: "1.5rem", fontWeight: 700 } }, "其他"),
                                    ]),
                                    m("span", { style: { fontSize: "1.5rem", fontWeight: 700 } }, "(" + this.else_models.length + ")"),
                                ])
                            ]),
                            m(".col-md-12.col-12", {
                                style: {
                                    borderBottom: "2px solid #2A6D71",
                                    height: "2px"
                                }
                            }),
                            m(".col-md-12.col-12", {
                                style: {
                                    height: "6px"
                                }
                            }),
                            this.else_models.map((_data) => {
                                return m(".col-md-3.col-12.p-1", [
                                    m(".position-relative", {
                                        style: {
                                            borderRadius: "25px",
                                            border: (!_data.is_view) ? "2px solid #FBDFDF" : "2px solid #000000"
                                        },
                                        onclick: (e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            m.route.set(`/rebalanceSetting/detail/${_data.id}`)
                                        }
                                    }, [
                                        m(".card-body", {
                                            style: {
                                                borderRadius: "25px",
                                                backgroundColor: "#FFFFFF"
                                            }
                                        }, [
                                            m("h5.card-title.pb-1", {
                                                style: {
                                                    fontWeight: 700,
                                                    fontSize: (_data.name.length >= 12) ? "0.9rem" : "1.3rem"
                                                }
                                            }, _data.id + "." +  _data.name),
                                            // m("h6.card-subtitle.mb-2.text-muted", _data.role),

                                            m("p.card-text.text-muted.py-3", {
                                                style: {
                                                    fontSize: "0.9rem",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    "-webkit-line-clamp": 2,

                                                }
                                            }, _data.subtitle),
                                            m(".py-1.d-inline.px-3", {
                                                style: {
                                                    color: "#5780C6",
                                                    borderRadius: "25px",
                                                    border: "2px solid #5780C6",
                                                    fontWeight: 700,
                                                    fontSize: "1rem",
                                                    cursor: "pointer",
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    Dialog.show(ModelComponent, {
                                                        attrs: {
                                                            model: new Model(_data),
                                                            categories: Category.datas
                                                        },
                                                        didHide: (returnValue) => {
                                                            Setting.fetchModels().then(() => {
                                                                this.SortByCategory()
                                                            })
                                                        }
                                                    })
                                                }
                                            }, "基本"),
                                            m(".px-2.d-inline"),
                                            m(".py-1.d-inline.px-3", {
                                                style: {
                                                    color: "#59B3A7",
                                                    borderRadius: "25px",
                                                    border: "2px solid #59B3A7",
                                                    fontWeight: 700,
                                                    fontSize: "1rem",
                                                    cursor: "pointer",
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    m.route.set(`/rebalanceSetting/detail/${_data.id}`)
                                                }
                                            }, "內容"),
                                            m(".px-2.d-inline"),
                                            m(".py-1.d-inline.px-3", {
                                                style: {
                                                    color: "#D53C3C",
                                                    borderRadius: "25px",
                                                    border: "2px solid #D53C3C",
                                                    fontWeight: 700,
                                                    fontSize: "1rem",
                                                    cursor: "pointer",
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    Swal.fire({
                                                        title: "你確定要刪除此模板嗎？",
                                                        showCancelButton: true,
                                                        confirmButtonText: "確認",
                                                        cancelButtonText: "取消",
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            Model.remove(_data.id).then((_res) => {
                                                                Setting.fetchModels()
                                                            })
                                                            m.redraw()
                                                        }
                                                    });

                                                }
                                            }, "刪除"),
                                        ]),
                                        m(".position-absolute", {
                                            style: {
                                                top: "10px",
                                                left: "20px",
                                                fontSize: "1.25rem",
                                                color: "#EAEAEA"
                                            }
                                        }, m(`i.fa.${_data.icon}`)),
                                        (!_data.is_view) ?
                                            m(".position-absolute", {
                                                style: {
                                                    top: "10px",
                                                    right: "10px",
                                                    fontSize: "1.25rem",
                                                    color: "#BC1D1D"
                                                }
                                            }, m("i.fa-regular.fa-eye-slash")) : "",
                                        (_data.is_free) ?
                                            m(".position-absolute", {
                                                style: {
                                                    top: "10px",
                                                    right: "10px",
                                                    fontSize: "1.25rem",
                                                    boxShadow: "0 0 0 9px #FF0000",
                                                    background: "#FF0000",
                                                    clipPath: "inset(0-100%)",
                                                    inset: "0 0 auto auto",
                                                    transformOrigin: " 0 0",
                                                    transform: "translate(6.3%) rotate(30deg)",
                                                    color: "#FFFFFF"

                                                }
                                            }, "Free") : ""
                                    ]),
                                ])
                            }),

                        ])
                    ]),

                ])
            ),
            m(Dialog),
        ]
    }
}
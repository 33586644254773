import m from 'mithril'
import classNames from 'classnames'

class Pagination {
    constructor(vnode) {
        this.hasFirst = false
        this.hasMoreNext = false
        this.hasMorePrev = false
        this.hasLast = false
        this.pages = []
        this.pageRange = 3
        this.computePages(vnode.attrs)
    }
    getPageRanges(start, end) {
        let ranges = []
            for (let i = start; i <= end; i++) {
                ranges.push({
                    is_view: true,
                    value: i
                })
            }
      
        this.pages = ranges
    }
    computePages(attrs) {
        let pageRange = this.pageRange
        let totalPage = attrs.pageCount

        let rangeStart = attrs.pageNo - pageRange
        let rangeEnd = attrs.pageNo + pageRange

        if (rangeEnd > totalPage) {
            rangeEnd = totalPage
            rangeStart = totalPage - pageRange * 2;
            rangeStart = rangeStart < 1 ? 1 : rangeStart;
        }
        if (rangeStart <= 1) {
            rangeStart = 1;
            rangeEnd = Math.min(pageRange * 2 + 1, totalPage);
        }
        
        this.hasFirst = (rangeStart == 1)
        this.hasLast = (rangeEnd == totalPage)
        this.getPageRanges(rangeStart, rangeEnd)
    }
    onbeforeupdate({ attrs }) {
        this.computePages(attrs)
    }
    view({ attrs }) {
        return [
            (attrs.pageCount > 0) ? [
                m('nav.d-flex.justify-content-center', [
                    m('ul.pagination', [
                        m('li', {
                            class: classNames({
                                'disabled': this.hasFirst
                            })
                        }, [
                            m('a.page-link.py-1', {
                                style: {
                                    borderRadius: "15px 0px 0px 15px",
                                    color: (this.hasFirst) ? "#000000" : "#ADADAD",
                                },
                                href: '#',
                                onclick: (e) => {
                                    e.preventDefault()
                                    attrs.pageUrl(1)
                                }
                            }, [
                                m('span', m.trust('&laquo;'))
                            ])
                        ]),
                        this.pages.map((item) => {
                            return [
                                m('li', {
                                    class: classNames({
                                        'active': item.value == attrs.pageNo
                                    })
                                }, [
                                    m('a.page-link.py-1', {
                                        style: {
                                            color: (item.value == attrs.pageNo) ? "#FFFFFF" : "#000000",
                                            backgroundColor: (item.value == attrs.pageNo) ? "#000000" : "#FFFFFF",
                                        },
                                        href: '#',
                                        onclick: (e) => {
                                            e.preventDefault()
                                            if (item.is_view) {
                                                attrs.pageUrl(item.value)
                                            }
                                        }
                                    }, item.value)
                                ]),
                            ]
                        }),
                        m('li', {
                            class: classNames({
                                'disabled': this.hasLast
                            })
                        }, [
                            m('a.page-link.py-1', {
                                style: {
                                    borderRadius: "0px 15px 15px 0px",
                                    color: (this.hasLast) ? "#000000" : "#ADADAD",
                                },
                                href: '#',
                                onclick: (e) => {
                                    e.preventDefault()
                                    attrs.pageUrl(attrs.pageCount)
                                }
                            }, [
                                m('span', m.trust('&raquo;'))
                            ])
                        ])
                    ])
                ])
            ] : ''

        ]
    }
}

export default Pagination
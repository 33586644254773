import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _datas = []

const constraints = {
    title: {
        presence: {
            allowEmpty: false,
            message: "^請輸入資訊主題"
        }
    },
    content: {
        presence: {
            allowEmpty: false,
            message: "^請輸入資訊內容"
        }
    },
}

class UserOption extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.user_id = args.user_id || null;
        this.title = args.title || '';
        this.content = args.content || '';
        
    }

    static get datas() {
        return _datas;
    }

    static fetchOptions() {
        UserOption.loading = true
        return m.request({
            method: 'POST',
            url: `/api/setting/option/fetch`,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },

        }).then((response) => {
            _datas = response
            UserOption.loading = false
            return response
        })
    }

    save() {
        UserOption.loading = true
        return m.request({
            method: 'POST',
            url: `/api/setting/option/save`,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },

            data: this
        }).then((response) => {
            UserOption.loading = false
            return response
        })
    }

    static remove (id){
        UserOption.loading = true
        return m.request({
            method: 'POST',
            url: `/api/setting/option/remove`,

            data: {
                id: id
            }
        }).then((response) => {
            UserOption.loading = false
        })
    }
    

}

export default UserOption
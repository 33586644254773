import m from 'mithril'
import classNames from 'classnames'

class TextArea {
    constructor(vnode) {
        this.label = false
        this.active = false
        this.required = false
        this.tips = null
        this.minHeight = null
        this.classes = {}
        this.errors = []

        if (vnode.attrs.hasOwnProperty('label')) {
            this.label = vnode.attrs.label
        }
        if (vnode.attrs.hasOwnProperty('required')) {
            this.required = vnode.attrs.required
            vnode.attrs.required = undefined
        }
        if (vnode.attrs.hasOwnProperty('tips')) {
            this.tips = vnode.attrs.tips
            vnode.attrs.tips = undefined
        }
        if (vnode.attrs.hasOwnProperty('class')) {
            this.classes = vnode.attrs.class
            vnode.attrs.class = undefined
        }
        if (vnode.attrs.hasOwnProperty('minHeight')) {
            this.minHeight = vnode.attrs.minHeight
            vnode.attrs.minHeight = undefined
        }
    }
    oncreate(vnode) {
        const target = vnode.dom.querySelector('textarea')
        this.resizeHandle(target)
    }
    onupdate(vnode) {

    }

    hasError(error) {
        if (this.errors && this.errors.length > 0) {
            return this.errors
        }
        if (error) {
            return Array.isArray(error) ? error[0] : error
        }
        return false
    }

    resizeHandle(target) {

        if (target.scrollHeight == 0) {
            return
        }
        const overflows = this.getParentOverflows(target)

        let heightOffset = null;
        let cachedHeight = null;

        const docTop = document.documentElement && document.documentElement.scrollTop;
        target.style.height = '';
        target.style.height = (target.scrollHeight) + 'px';

        // used to check if an update is actually necessary on window.resize
        let clientWidth = target.clientWidth;

        // prevents scroll-position jumping
        overflows.forEach(el => {
            el.node.scrollTop = el.scrollTop
        });

        if (docTop) {
            document.documentElement.scrollTop = docTop;
        }
        const styleHeight = Math.round(parseFloat(target.style.height));
        const computed = window.getComputedStyle(target, null);

        // Using offsetHeight as a replacement for computed.height in IE, because IE does not account use of border-box
        var actualHeight = computed.boxSizing === 'content-box' ? Math.round(parseFloat(computed.height)) : target.offsetHeight;

        // The actual height not matching the style height (set via the resize method) indicates that 
        // the max-height has been exceeded, in which case the overflow should be allowed.

        if (actualHeight < styleHeight) {
            if (computed.overflowY === 'hidden') {
                target.style.overflowY = 'scroll'
                // this.changeOverflow('scroll');
                this.resizeHandle(target)
                actualHeight = computed.boxSizing === 'content-box' ? Math.round(parseFloat(window.getComputedStyle(ta, null).height)) : target.offsetHeight;
            }
        } else {
            // Normally keep overflow set to hidden, to avoid flash of scrollbar as the textarea expands.
            if (computed.overflowY !== 'hidden') {
                target.style.overflowY = 'hidden'
                // this.changeOverflow('hidden');
                this.resizeHandle(target)
                actualHeight = computed.boxSizing === 'content-box' ? Math.round(parseFloat(window.getComputedStyle(ta, null).height)) : target.offsetHeight;
            }
        }

        if (cachedHeight !== actualHeight) {
            cachedHeight = actualHeight;
        }
    }
    getParentOverflows(el) {
        const arr = [];

        while (el && el.parentNode && el.parentNode instanceof Element) {
            if (el.parentNode.scrollTop) {
                arr.push({
                    node: el.parentNode,
                    scrollTop: el.parentNode.scrollTop,
                })
            }
            el = el.parentNode;
        }

        return arr;
    }
    resize(e) {
        this.resizeHandle(e.target)
    }
    view(vnode) {
        return m('.form-group', [
            (this.label) ? [
                m(".d-flex.justify-content-between", [
                    m('label.mb-1.pl-1', {
                        class: classNames({
                            'active': vnode.attrs.value || this.active
                        }),
                        style: {
                            "font-weight": 700
                        }
                    },
                        m("span", this.label),
                        (this.required) ?
                            m("span.text-danger.pl-2",{
                                style:{
                                    fontSize: "0.85rem"
                                }
                            }, '*必填') : "",
                    ),
                    m("i.fa.fa-trash", {
                        style:{
                            color:"#EC5C5C",
                            cursor: "pointer",
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            vnode.attrs.oninput({ target: { value: "" } })
                        }
                    })
                ])
            ] : '',

            m('textarea.form-control.w-100', {
                placeholder: (this.tips) ? "例如:" + this.tips : "",
                class: classNames(this.classes, {
                    'is-invalid': this.hasError(vnode.attrs.error)
                }),
                style: {
                    minHeight: (this.minHeight) ? this.minHeight : '10rem'
                },
                disabled: vnode.attrs.disabled,
                readonly: vnode.attrs.readonly,
                oninput: (e) => {
                    if (this.mask) {
                        this.onTextMaskInput(e)
                    }
                    vnode.attrs.oninput(e)
                    // const errors = this.validate(e.target.value)
                    // if (errors) {
                    //     this.errors = errors
                    // } else {
                    //     this.errors = []
                    // }
                    this.resize(e)
                },
            }, vnode.attrs.value),

            // m('.helper-text.pl-2.pb-1', {
            //     'data-error': this.hasError(vnode.attrs.error),
            //     style: {
            //         fontSize: "0.8rem",
            //         color: "red"
            //     }
            // }, [
            //     vnode.attrs.helper
            // ], vnode.attrs.error)
        ])
    }
}

export default TextArea
import m from 'mithril';
import moment from 'moment'
import {
    UserSpellRecord,
} from 'app/models'
import {
    Paging,
} from 'components'
import classNames from 'classnames';

export default class AdminControl {
    constructor(vnode) {
        UserSpellRecord.getSpellRecord()
        this.view_title = true
        this.view_spell = true
    }
    view({
        attrs
    }) {
        if (attrs.open_admin_control()) {
            return m(".row.m-0.position-absolute.border", {
                style: {
                    backgroundColor: "#FAFAFA",
                    zIndex: 1026,
                    top: "20px",
                    left: "20px",
                    maxWidth: (window.isMobile) ? "90%" : "25%",
                    width: (window.isMobile) ? "90%" : "25%"
                }
            }, [
                m(".col-12.py-2.px-4.d-flex.justify-content-between", {
                    onclick: (e) => {
                        attrs.open_admin_control(false)
                        localStorage.setItem("admin_menu_open", "close")
                    }
                }, [
                    m("span", {
                        style: {
                            fontSize: "1.1rem",
                            fontWeight: 800
                        },
                    }, "管理員選單"),
                    m('span', m.trust('&times;'))
                ]),
                m(".row.m-0.w-100.pt-2", [
                    m(".col-12", [
                        m(".row.m-0.w-100.py-1", [
                            m(".col-4.pr-1", [
                                m("span.d-block.text-center", {
                                    style: {
                                        fontSize: "1rem",
                                        color: (this.view_title) ? "#FFFFFF" : "#000000",
                                        borderRadius: "25px",
                                        backgroundColor: (this.view_title) ? "#000000" : "#FFFFFF",
                                        border: (this.view_title) ? "2px solid #FFFFFF" : "2px solid #000000",
                                        fontWeight: 700,
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.view_title = !this.view_title
                                        m.redraw()
                                    }
                                }, "名稱"),
                            ]),
                            m(".col-4.pr-1", [
                                m("span.d-block.text-center", {
                                    style: {
                                        fontSize: "1rem",
                                        color: (this.view_spell) ? "#FFFFFF" : "#000000",
                                        borderRadius: "25px",
                                        backgroundColor: (this.view_spell) ? "#000000" : "#FFFFFF",
                                        border: (this.view_spell) ? "2px solid #FFFFFF" : "2px solid #000000",
                                        fontWeight: 700
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.view_spell = !this.view_spell
                                        m.redraw()
                                    }
                                }, "咒語"),
                            ]),
                        ]),
                    ]),
                    UserSpellRecord.datas.map((_record, _index) => {
                        return [
                            m(".col-12.p-1", [
                                m(".row.m-0.w-100.border.py-1", [
                                    (this.view_title) ?
                                        m(".col-12.d-flex.justify-content-start.align-items-center.pb-3", [
                                            m(`span`, { style: { fontSize: "1.1rem", fontWeight: 500 } }, _record.model_name + " - " + _record.step)
                                        ]) : "",
                                    (this.view_spell) ? [
                                        m(".col-12", [
                                            m(`span`, { style: { fontSize: "0.9rem", fontWeight: 500 } }, "User:"),
                                            m(`p.m-0`, { style: { fontSize: "0.9rem", fontWeight: 300, overflowX: "auto" } }, m.trust(_record.user_spell.replace(/ /g, "&nbsp;").replace(/\n/g, "<br>").replace(/\t/g, "&nbsp;&nbsp;")))
                                        ]),
                                        (_record.revise_prompt == null) ?
                                            m(".col-12", [
                                                m(`span`, { style: { fontSize: "0.9rem", fontWeight: 500 } }, "System:"),
                                                m(`p.m-0`, { style: { fontSize: "0.9rem", fontWeight: 300, overflowX: "auto" } }, m.trust(_record.system_spell.replace(/ /g, "&nbsp;").replace(/\n/g, "<br>").replace(/\t/g, "&nbsp;&nbsp;")))
                                            ]) : "",
                                        (_record.revise_prompt != null) ?
                                            m(".col-12.pt-3", { class: classNames({ "pt-3": this.view_spell }) }, [
                                                m(`span`, { style: { fontSize: "0.9rem", fontWeight: 500 } }, "Revised:"),

                                                m(`p.m-0`, { style: { fontSize: "0.9rem", fontWeight: 300, overflowX: "auto" } }, _record.revise_prompt)
                                            ]) : "",
                                    ] : "",
                                    m(".col-12.px-0.pt-3", [
                                        m(".row.w-100.m-0", [
                                            m(".col-md-4.col-6", [
                                                m(`span`, { style: { fontSize: "0.9rem", fontWeight: 500 } }, _record.model)
                                            ]),
                                            m(".col-md-4.col-6", [
                                                m(`span.pr-3`, { style: { fontSize: "0.9rem", fontWeight: 500 } }, "N:"),
                                                m(`span`, { style: { fontSize: "0.9rem", fontWeight: 300 } }, _record.n)
                                            ]),
                                            m(".col-md-4.col-6", [
                                                m(`span`, { style: { fontSize: "0.9rem", fontWeight: 300 } }, moment(_record.created_at).format("Y-MM-DD hh:mm:ss"))
                                            ]),
                                            m(".col-md-4.col-6", [
                                                m(`span.pr-3`, { style: { fontSize: "0.9rem", fontWeight: 500 } }, "Prompt:"),
                                                m(`span`, { style: { fontSize: "0.9rem", fontWeight: 300 } }, _record.prompt_tokens)
                                            ]),
                                            m(".col-md-4.col-6", [
                                                m(`span.pr-3`, { style: { fontSize: "0.9rem", fontWeight: 500 } }, "Completion:"),
                                                m(`span`, { style: { fontSize: "0.9rem", fontWeight: 300 } }, _record.completion_tokens)
                                            ]),
                                            m(".col-md-4.col-6", [
                                                m(`span.pr-3`, { style: { fontSize: "0.9rem", fontWeight: 500 } }, "Total:"),
                                                m(`span`, { style: { fontSize: "0.9rem", fontWeight: 300 } }, _record.total_tokens)
                                            ]),
                                        ])
                                    ])

                                ]),
                            ])
                        ];
                    }),
                    m('.col-12.pt-3', [
                        m('.d-flex.flex-sm-row.flex-column.justify-content-md-between', [
                            m(Paging.Pagination, {
                                pageNo: UserSpellRecord.paging.pageNo,
                                pageCount: UserSpellRecord.paging.pageCount,
                                pageUrl: UserSpellRecord.getSpellRecord.bind(this)
                            }),
                        ])
                    ])
                ]),
            ])
        }
    }
}
import m from 'mithril'
import classNames from 'classnames';
import {
    User,
} from "app/models"


class HeaderComponet {
    constructor(vnode) {

    }
    view({ attrs }) {
        return m(".w-100.position-fixed", {
            style: {
                top: 0,
                backgroundColor: "#F9F9F9",
                boxShadow: "0 4px 6px -6px #222",
                zIndex: 1023,
            }
        }, [
            m(".d-flex.justify-content-between.align-items-center.py-2.w-100", {
                class: classNames({ 
                    container: !window.isMobile
                }),
                style: {
                    paddingLeft: (!window.isMobile) ? "10.2%" : "2px",
                    paddingRight: (!window.isMobile) ? "10.2%" : "2px",
                }
            }, [
                m("span.px-2", {
                    style: {
                        fontSize: "1rem",
                        color: "#000000",
                        borderRadius: "25px",
                        border: "2px solid #000000",
                        fontWeight: 700,
                        cursor: "pointer",
                    },
                    onclick: (e) => {
                        e.preventDefault()
                        attrs.open_tool_menu(!attrs.open_tool_menu())
                        m.redraw()
                    }
                }, "更多工具"),
                m(".position-relative",{
                    style:{
                        paddingRight:"10px"
                    }
                }, [
                    (User.Account.profile) ?
                        m("div.img-fluid.rounded-circle", {
                            style: {
                                height: "45px",
                                width: "45px",
                                maxHeight: "45px",
                                maxWidth: "45px",
                                backgroundImage: `url("${User.Account.profile}")`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                border: "1px solid #000000",
                                cursor: "pointer",
                            },
                            onclick: (e) => {
                                e.preventDefault()
                                attrs.open_user_menu(!attrs.open_user_menu())
                                m.redraw()
                            }
                        }) :
                        m("i.fa.fa-user", {
                            style: {
                                fontSize: "1.75rem"
                            },
                            onclick: (e) => {
                                e.preventDefault()
                                attrs.open_user_menu(!attrs.open_user_menu())
                                m.redraw()
                            }
                        }),

                    m(".position-absolute.rounded-circle.d-flex.align-items-center.justify-content-center", {
                        style: {
                            border:"2px solid #FFFFFF",
                            right: "16px",
                            bottom: "6px",
                            transform:"translate(50%, 50%)",
                            backgroundColor:"#FFFFFF",
                            height: "24px",
                            width: "24px",
                            color:"#000000",
                            zIndex:20
                        }
                    }, m("i.fa-solid.fa-circle-user",{
                        style:{
                            fontSize:"20px"
                        }
                    })),
                ])

            ]),
        ])
    }
}

export default HeaderComponet
import m from 'mithril'
import moment from 'moment'
import BaseTwoModel from './_baseTwo'

let _data = []

const constraints = {

}

class Article extends BaseTwoModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
    }

    static get data() {
        return _data;
    }

    static getArticleList(page) {
        Article.loading = true
        page = (page === undefined) ? Article.pageIndex : page
        Article.pageIndex = page
        let url = `${window.BASE_URL}/api/manager/member/aricleList`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
            data: {
                memberId: window.memberIdSelect,
                selectType: window.select_type,
                page: page
            }
        }).then((response) => {
            Article.paging = {
                pageCount: response.last_page,
                pageNo: response.current_page,
                pageSize: response.per_page,
                totalRecordCount: response.total
            }
            _data = response.data
            Article.loading = false
            return _data
        })
    }
}

export default Article

import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _data = []

const constraints = {

}

class Member extends BaseModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.profile = args.profile || null;
        this.level = args.level || 1;
        this.nickname = args.nickname || null;
        this.email = args.email || null;
        this.line_id = args.line_id || null;
        this.use_tokens = args.use_tokens || 0;
        this.remain_tokens = args.remain_tokens || 0;
        this.diedline = moment(args.diedline).format('Y-M-D') || moment().format('Y-M-D');

    }

    static get data() {
        return _data;
    }

    static getMemberList(page) {
        Member.loading = true
        page = (page === undefined) ? Member.pageIndex : page
        Member.pageIndex = page
        let url = `${window.BASE_URL}/api/manager/member/memberList`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
            data: {
                memberType: window.member_type,
                page: page
            }
        }).then((response) => {
            Member.paging = {
                pageCount: response.last_page,
                pageNo: response.current_page,
                pageSize: response.per_page,
                totalRecordCount: response.total
            }
            _data = response.data
            Member.loading = false
            return _data
        })
    }

    static addPoints(userId, points) {
        let url = `${window.BASE_URL}/api/manager/member/addPoints`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
            data: {
                userId: userId,
                points: points
            }
        }).then((response) => {
            return response
        })
    }



}

export default Member
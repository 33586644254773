import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea
} from 'components'
import * as Components from 'components'
import {
    Authority,
} from "app/models"
import moment from 'moment'


class ModelDialog {
    constructor(vnode) {
        this.questions = vnode.attrs.questions
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return m('.modal-dialog', [
            m('.modal-content', [
                m('.modal-header', [
                    m('h5.modal-title', [
                        m('span', "預覽表單")
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body", [
                    m('.container-fluid', [
                        m(".row.w-100.m-0", [
                            m(".col-12.text-center.py-2", [
                                m("span", {
                                    style: {
                                        fontSize: "1.2rem",
                                        fontWeight: 800
                                    }
                                }, "預覽")
                            ]),
                        ]),
                        (this.questions != null) ? [
                            this.questions.map((_question) => {
                                if (_question['type'] == "TextBox" || _question['type'] == "TextArea") {
                                    return m('.col-12.pb-2', [
                                        m(Components[_question['type']], {
                                            type: 'text',
                                            label: _question['question'],
                                            required: _question['required'],
                                            tips: (_question.hasOwnProperty('tips')) ? _question['tips'] : null
                                        })
                                    ])
                                }
                                if (_question['type'] == "Radio") {
                                    return m('.col-12.pb-2', [
                                        m(".form-group.m-0", [
                                            m("label.text-dark.mb-1.pl-2", _question['question']),
                                            _question.options.map((_options, index) => {
                                                return m(".col-12.py-2.px-0", [
                                                    m(".input-group", [
                                                        m(".input-group-text", [
                                                            m("input.mt-0", {
                                                                type: "radio",
                                                                value: _options.value,
                                                                style: {
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }
                                                            })
                                                        ]),
                                                        m("input.form-control", {
                                                            type: 'text',
                                                            value: _options.key,
                                                            disable: true,
                                                            readonly: true,
                                                            oninput: (e) => {
                                                                _options.key = e.target.value
                                                                _options.value = e.target.value
                                                            },
                                                        })

                                                    ]),
                                                ])
                                            })
                                        ])
                                    ])
                                }
                                if (_question['type'] == "CheckBox") {
                                    return m('.col-12.pb-2', [
                                        m(".form-group.m-0", [
                                            m("label.text-dark.mb-1.pl-2", _question['question']),
                                            _question.options.map((_options, index) => {
                                                return m(".col-12.py-2.px-0", [
                                                    m(".input-group", [
                                                        m(".input-group-text", [
                                                            m("input.mt-0", {
                                                                type: "checkbox",
                                                                value: _options.value
                                                            })
                                                        ]),
                                                        m("input.form-control", {
                                                            type: 'text',
                                                            value: _options.key,
                                                            disable: true,
                                                            readonly: true,
                                                            oninput: (e) => {
                                                                _options.key = e.target.value
                                                                _options.value = e.target.value
                                                            },
                                                        })

                                                    ]),
                                                ])
                                            })
                                        ])
                                    ])
                                }
                                if (_question['type'] == "Select") {
                                    return m('.col-12.pb-2', [
                                        m(".form-group.m-0", [
                                            m("label.text-dark.mb-1.pl-2", _question['question']),
                                            m("select.form-control", [
                                                m("option[disabled][selected]", "請選擇"),
                                                _question['options'].map((_item) => {
                                                    return [m(`optgroup`, {
                                                        label: _item.key
                                                    }),
                                                    _item.datas.map((_data) => {
                                                        return m("option", _data)
                                                    })

                                                    ]
                                                })
                                            ])
                                        ])
                                    ])
                                }
                                if (_question['type'] == "Photo") {
                                    return [
                                        (_question.hasOwnProperty(_question['variable'])) ?
                                            m(".col-12.p-2.text-center", [
                                                (_question[_question['variable']] != null) ?
                                                    m("img.img-fluid", {
                                                        src: _question[_question['variable']],
                                                        style: {
                                                            maxHeight: "240px"
                                                        }
                                                    }) : ""
                                            ]) : "",
                                        
                                        m(".col-12.py-2", [
                                            m(TextBox, {
                                                oninput: (e) => {
                                                    _question.options['title'] = e.target.value
                                                },
                                                value: _question.options['title'],
                                                type: 'text',
                                                label: "圖片想強調的主題",
                                                disable: true,
                                                readonly: true,
                                            })
                                        ]),
                                        m(".col-12.pt-2", [
                                            m("span", _question['question'])
                                        ]),
                                        m('.col-12.pb-4', [
                                            m(".btn.btn-outline-success.w-100", "上傳照片")
                                        ])]
                                }
                                if (_question['type'] == "PhotoSelect") {
                                    return m(".col-12.pb-1", [
                                        m(".w-100", {
                                            style: {
                                                "overflow-x": "auto",
                                                "white-space": "nowrap",
                                                "webkit-overflow-scrolling": "touch",
                                                "-ms-overflow-style": "none",
                                                "scrollbar-width": (!window.isMobile) ? "auto" : "none",
                                                "-ms-overflow-style": "-ms-autohiding-scrollbar",
                                            }
                                        }, [
                                            _question['options'].map((_option) => {
                                                return m(".pr-3.d-inline-block.pb-2", [
                                                    m(".justify-content-center.align-items-center", {
                                                        style: {
                                                            maxHeight: "210px",
                                                            minHeight: "210px",
                                                            minWidth: "240px",
                                                            minWidth: "240px",
                                                            backgroundImage: `url(${_option.url})`,
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundPosition: "center",
                                                            backgroundSize: "contain",
                                                            borderRadius: "5px"
                                                        },
                                                    }),
                                                    m(".w-100.d-flex.justify-content-center.align-itens-center.py-1", {
                                                        style: {
                                                            color: "#234B74",
                                                            backgroundColor: "#FFFFFF",
                                                            borderRadius: "25px",
                                                            border: "1px solid #234B74",
                                                            fontWeight: 600,
                                                            fontSize: "1.25rem",
                                                            cursor: "pointer",
                                                        }
                                                    }, [
                                                        m("span", _option.value),
                                                    ])
                                                ])
                                            })
                                        ])
                                    ])
                                }
                            })
                        ] : ""
                    ])
                ]),
                m('.modal-footer', [
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            fontWeight: 700,
                            fontSize: "1.25rem",
                            cursor: "pointer",
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            this.close()
                        }
                    }, '關閉')
                ])
            ])
        ])
    }
}

export default ModelDialog
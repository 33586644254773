import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _datas = []

const constraints = {
    name: {
        presence: {
            allowEmpty: false,
            message: "^請輸入種類名字"
        }
    },
}

class Category extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.name = args.name || '';
        this.sort = args.sort || 99;
    }

    static get datas() {
        return _datas;
    }

    static fetch() {
        Category.loading = true
        return m.request({
            method: 'POST',
            url: `/api/setting/category/fetch`,

        }).then((response) => {
            _datas = response
            Category.loading = false
            return response
        })
    }

    save() {
        Category.loading = true
        return m.request({
            method: 'POST',
            url: `/api/setting/category/save`,

            data: this
        }).then((response) => {
            Category.loading = false
            return response
        })
    }

    static remove (id){
        Category.loading = true
        return m.request({
            method: 'POST',
            url: `/api/setting/category/remove`,

            data: {
                id: id
            }
        }).then((response) => {
            Category.loading = false
        })
    }
    

}

export default Category
import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _datas = []

const constraints = {
    name: {
        presence: {
            allowEmpty: false,
            message: "^請輸入模板主題"
        }
    },
}

class Model extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.level = args.level || 1;
        this.category_id = args.category_id || null;
        this.is_view = args.is_view || false;
        this.is_gpt4 = args.is_gpt4 || false;
        this.is_free = args.is_free || false;
        this.name = args.name || '';
        this.subtitle = args.subtitle || '';
        this.role = args.role || '';
        this.rule = args.rule || '';
        this.sort = args.sort || 99;
        this.icon = args.icon || '';
        // this.spell = args.spell || [];
        this.steps = args.steps || [];
        this.used_times = args.used_times || 0;
        this.preview = args.preview || null;

        // this.questions = args.questions || []; 
        // this.system_spell = args.system_spell || "";
        
        this.updated_at = moment(args.updated_at).format('Y-M-D') || moment().format('Y-M-D');
        this.created_at = moment(args.created_at).format('Y-M-D') || moment().format('Y-M-D');
    }

    static get datas() {
        return _datas;
    }

    save() {
        Model.loading = true
        return m.request({
            method: 'POST',
            url: `/api/setting/model/save`,

            data: this
        }).then((response) => {
            Model.loading = false
            return response
        })
    }

    static load(id) {
        Model.loading = true
        return m.request({
            method: 'POST',
            url: `/api/setting/model/load`,

            data: {
                id: id
            }
        }).then((response) => {
            Model.loading = false
            return response
        })
    }

    static remove (id){
        Model.loading = true
        return m.request({
            method: 'POST',
            url: `/api/setting/model/remove`,

            data: {
                id: id
            }
        }).then((response) => {
            Model.loading = false
        })
    }
    

}

export default Model
import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea
} from 'components'
import {
    Setting,
} from "app/models"
import moment from 'moment'
import classNames from 'classnames'
import OptionComponent from './options'
import PhotoSelectComponent from './photo_select'
import Uploader from 'components/uploader/core'

class QuestionDialog {
    constructor(vnode) {
        this.model = vnode.attrs.model
        this.uploader = new Uploader()
        this.answer_type = [
            { name: "簡答題", key: "TextBox" },
            { name: "詳答題", key: "TextArea" },
            { name: "多選題", key: "CheckBox" },
            { name: "下拉式選單", key: "Select" },
            { name: "圖片選擇", key: "PhotoSelect" },
            { name: "選擇題", key: "Radio" },
            { name: "圖片", key: "Photo" },
            { name: "顏色選擇", key: "ColorSelect" },
            // { name: "Excel", key: "Excel" },
            // { name: "Word", key: "Word" },
            // { name: "PDF", key: "PDF" },
            { name: "文件上傳", key: "DATA" },
            { name: "Recording", key: "Recording" },
            { name: "Google圖轉文", key: "GoogleTransferIamge" },
            { name: "上傳影音", key: "MP4Upload" },
            // { name: "Dell-E2", key: "DallE2" },
            { name: "套版", key: "Template" },
        ]
        this.score_section_options = [
            { key: "分數", datas: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }
        ]
        this.style_section_options = [
            { key: "經濟、科學、商業風格", datas: ['商業寫作', '科普寫作', '經濟寫作', '農產品風格', '採訪風格',] },
            { key: "文學風格", datas: ['敘事寫作', '歷史故事風格', '個人隨筆', '日記體風格', '文青風格', '佛教風格', '基督教風格',] },
            { key: "旅遊相關", datas: ['旅行日記', '紀實攝影寫作', '旅遊寫作', '探險旅行', '旅遊指南'] },
            { key: "靈性與自然", datas: ['靈性寫作', '自然寫作'] },
            { key: "飲食風格", datas: ['食品和烹飪寫作', '美食寫作風格'] },
            { key: "運動", datas: ['運動寫作'] },
            { key: "教育與家庭關係文學", datas: ['親子寫作', '兒童文學', '教育寫作', '育兒寫作'] },
            { key: "輕鬆生活", datas: ['幽默', '時尚風格', '社交媒體風格', '寫作指南', '勵志寫作', '網紅風格', '愛美風格'] },
            { key: "健康", datas: ['健康寫作'] },
            { key: "其他", datas: ['指南風格', '日記風格', '敘事風格', '解惑寫作風格'] },
        ]

        if (this.model.variable == null) {
            this.model.variable = this.uuid()
        }

        this.options = stream(this.model.options)

    }

    uuid() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }

    close(status, value) {
        setTimeout(() => {
            if (status) {
                Dialog.close(value)
            } else {
                Dialog.close(false)
            }
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return m('.modal-dialog', [
            m('.modal-content', [
                m('.modal-header', [
                    m('h5.modal-title', [
                        m('span', this.model.id == 0 ? "新增問題" : "編輯問題")
                    ]),
                    m(".btn.close", {
                        onclick: (e) => {
                            e.preventDefault()
                            this.close(false, null)
                        }
                    }, m('span', m.trust('&times;'))
                    )
                ]),
                m(".modal-body", [
                    m('.container-fluid', [
                        m('.row', [
                            m('.col-12.pb-3',
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.question = e.target.value
                                    },
                                    value: this.model.question,
                                    type: 'text',
                                    label: "問題顯示",
                                    required: true,
                                    // validate: () => {
                                    //     this.model.valid('question')
                                    // },
                                    // error: this.model.error('question'),
                                })
                            ),
                            m('.col-12.pb-3',
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.title = e.target.value
                                    },
                                    value: this.model.title,
                                    type: 'text',
                                    label: "題目名稱(GPT閱讀用)",
                                    required: true,
                                    // validate: () => {
                                    //     this.model.valid('title')
                                    // },
                                    // error: this.model.error('title'),
                                })
                            ),
                            m('.col-12.pb-3',
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.tips = e.target.value
                                    },
                                    value: this.model.tips,
                                    type: 'text',
                                    label: "題目備註",
                                })
                            ),
                            (this.model.type == "Photo" || this.model.type == "GoogleTransferIamge") ? [
                            ] : [
                                m('.col-12.pb-3',
                                    m(TextBox, {
                                        oninput: (e) => {
                                            this.model.defaultAnswer = e.target.value
                                        },
                                        value: this.model.defaultAnswer,
                                        type: 'text',
                                        label: "預設答案",
                                    })
                                ),
                            ],
                            m('.col-12.pb-3',
                                m(".form-group.m-0", [
                                    m("label.text-dark.mb-1.pl-2", {
                                        style: {
                                            "font-weight": 700
                                        }
                                    }, "問題類別"),
                                    m("select.form-control", {
                                        onchange: (e) => {
                                            this.model.type = e.target.value
                                            this.model.options = []
                                            if (this.model.type == "Photo" || this.model.type == "DATA" || this.model.type == "Recording" || this.model.type == "GoogleTransferIamge" || this.model.type == "MP4Upload" || this.model.type == "DallE2" || this.model.type == "Template") {
                                                if (this.model.type == "Photo" || this.model.type == "GoogleTransferIamge" || this.model.type == "DallE2") {
                                                    this.model.options[0] = { "spell": "", "mask": "", "title": "" }
                                                    this.model.isPhoto = true
                                                } else if (this.model.type == "Recording" || this.model.type == "MP4Upload") {
                                                    this.model.options[0] = { "spell": "" }
                                                    this.model.isData = true
                                                } else if (this.model.type == "Template") {
                                                    this.model.options[0] = { "isDefault": false, "data": null }
                                                    this.model.isData = true
                                                } else {
                                                    this.model.isData = true
                                                }
                                            } else {
                                                this.model.isPhoto = false
                                                this.model.isData = false
                                            }
                                            if (this.model.type == "CheckBox" || this.model.type == "Radio") {
                                                this.model.options = []
                                            }
                                            if (this.model.type == "Select" || this.model.type == "PhotoSelect") {
                                                this.model.options = []
                                                this.options = stream(this.model.options)
                                            }
                                        }
                                    }, [
                                        m("option[disabled][selected]", {
                                            value: null,
                                        }, "請選擇"),
                                        this.answer_type.map((_type) => {
                                            return m("option", {
                                                value: _type.key,
                                                selected: this.model.type == _type.key
                                            }, _type.name)
                                        })
                                    ])
                                ])
                            ),

                            (this.model.type == "CheckBox") ? [
                                m('.col-12.pb-3',
                                    m(".row.w-100.m-0", [
                                        m(".col-12.py-2.px-0", [
                                            m("span", {
                                                style: {
                                                    fontSize: "1.2rem",
                                                    fontWeight: 700,
                                                }
                                            }, "選項設定"),
                                        ]),
                                        m("span.py-0.px-3", {
                                            style: {
                                                color: "#234B74",
                                                borderRadius: "25px",
                                                border: "1px solid #234B74",
                                                fontWeight: 500,
                                                fontSize: "1rem",
                                                cursor: "pointer",
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()
                                                this.model.options.push({
                                                    key: null,
                                                    value: null
                                                })
                                            }
                                        }, "新增選項"),

                                        this.model.options.map((_options, index) => {
                                            return [m(".col-11.py-2", [
                                                m(".input-group", [
                                                    m(".input-group-text", {
                                                        style: {
                                                            backgroundColor: "#FFFFFF"
                                                        }
                                                    }, [
                                                        m("input.mt-0", {
                                                            type: "checkbox",
                                                            value: _options.value,
                                                        })
                                                    ]),
                                                    m("input.form-control", {
                                                        type: 'text',
                                                        value: _options.key,
                                                        placeholder: "使用者看到選項",
                                                        oninput: (e) => {
                                                            _options.key = e.target.value
                                                            _options.value = e.target.value
                                                        },
                                                        backgroundColor: "#FFFFFF"
                                                    })

                                                ]),
                                            ]),
                                            m(".col-1.d-flex.align-items-center.justify-content-center", [
                                                m("i.fa.fa-trash-o.py-1.text-danger.px-2", {
                                                    style: {
                                                        fontSize: "1.2rem",
                                                        // color: "#A7A3E2"
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        Swal.fire({
                                                            title: "你確定要刪除此選項？",
                                                            // text: "注意：此流程刪除後，該流程所附屬咒語及問題皆會消失！",
                                                            showCancelButton: true,
                                                            confirmButtonText: "確認",
                                                            cancelButtonText: "取消",
                                                            icon: "warning"

                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                this.model.options.splice(index, 1)
                                                                m.redraw()
                                                            }
                                                        });
                                                    }
                                                }),
                                            ]),
                                            m(".col-12.d-flex.align-items-end.justify-content-center", [
                                                m("input.form-control", {
                                                    type: 'text',
                                                    value: _options.gpt,
                                                    placeholder: "GPT閱讀內容",
                                                    oninput: (e) => {
                                                        _options.gpt = e.target.value
                                                    },
                                                })
                                            ])


                                            ]
                                        })
                                    ])
                                ),
                            ] : "",

                            (this.model.type == "Radio") ? [
                                m('.col-12.pb-3',
                                    m(".row.w-100.m-0", [
                                        m(".col-12.py-2.px-0", [
                                            m("span", {
                                                style: {
                                                    fontSize: "1.2rem",
                                                    fontWeight: 700,
                                                }
                                            }, "選項設定"),
                                        ]),
                                        m("span.py-0.px-3", {
                                            style: {
                                                color: "#234B74",
                                                borderRadius: "25px",
                                                border: "1px solid #234B74",
                                                fontWeight: 500,
                                                fontSize: "1rem",
                                                cursor: "pointer",
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()
                                                this.model.options.push({
                                                    key: null,
                                                    value: null
                                                })
                                            }
                                        }, "新增選項"),

                                        this.model.options.map((_options, index) => {
                                            return [m(".col-11.py-2", [
                                                m(".input-group", [
                                                    m(".input-group-text", {
                                                        style: {
                                                            backgroundColor: "#FFFFFF"
                                                        }
                                                    }, [
                                                        m("input.mt-0", {
                                                            type: "radio",
                                                            value: _options.value
                                                        })
                                                    ]),
                                                    m("input.form-control", {
                                                        type: 'text',
                                                        value: _options.key,
                                                        placeholder: "使用者看到選項",
                                                        oninput: (e) => {
                                                            _options.key = e.target.value
                                                            _options.value = e.target.value

                                                        },
                                                    })

                                                ]),
                                            ]),
                                            m(".col-1.d-flex.align-items-center.justify-content-center", [
                                                m("i.fa.fa-trash-o.py-1.text-danger.px-2", {
                                                    style: {
                                                        fontSize: "1.2rem",
                                                        // color: "#A7A3E2"
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        Swal.fire({
                                                            title: "你確定要刪除此選項？",
                                                            // text: "注意：此流程刪除後，該流程所附屬咒語及問題皆會消失！",
                                                            showCancelButton: true,
                                                            confirmButtonText: "確認",
                                                            cancelButtonText: "取消",
                                                            icon: "warning"

                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                this.model.options.splice(index, 1)
                                                                m.redraw()
                                                            }
                                                        });
                                                    }
                                                }),
                                            ]),
                                            m(".col-12.d-flex.align-items-end.justify-content-center", [
                                                m("input.form-control", {
                                                    type: 'text',
                                                    value: _options.gpt,
                                                    placeholder: "GPT閱讀內容",
                                                    oninput: (e) => {
                                                        _options.gpt = e.target.value
                                                    },
                                                })
                                            ])
                                            ]
                                        })
                                    ])
                                ),
                            ] : "",

                            (this.model.type == "Select") ? [
                                m('.col-12.pb-3',
                                    m(".row.w-100.m-0.d-flex.justify-content-end", [
                                        m('.col-8.px-0',
                                            m(".form-group.m-0", [
                                                m("select.form-control", [
                                                    m("option[selected]", {
                                                        value: null,
                                                    }, "請選擇"),
                                                    this.model.options.map((_item) => {
                                                        return [m(`optgroup`, {
                                                            label: _item.key
                                                        }),
                                                        _item.datas.map((_data) => {
                                                            if (_data.hasOwnProperty('value')) {
                                                                return m("option", {
                                                                    value: _data.gpt,
                                                                }, _data.value)
                                                            } else {
                                                                return m("option", {
                                                                    value: _data,
                                                                }, _data)
                                                            }
                                                        })
                                                        ]
                                                    })
                                                ])
                                            ])
                                        ),
                                        m(".col-4.px-0.d-flex.justify-content-end.align-items-center", [
                                            m("span.px-2", {
                                                style: {
                                                    color: "#234B74",
                                                    borderRadius: "25px",
                                                    border: "1px solid #234B74",
                                                    fontWeight: 500,
                                                    fontSize: "1rem",
                                                    cursor: "pointer",
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    Dialog.show(OptionComponent, {
                                                        attrs: {
                                                            options: this.options
                                                        },
                                                        didHide: (returnValue) => {
                                                            this.model.options = this.options()
                                                        }
                                                    })
                                                }
                                            }, "選項設定"),
                                        ]),

                                    ])
                                ),

                            ] : "",

                            (this.model.type == "PhotoSelect") ? [
                                m('.col-12.pb-3',
                                    m(".row.w-100.m-0.d-flex.justify-content-end", [
                                        m(".col-8.px-0.d-flex.justify-content-start.align-items-center", [
                                            m("span.px-2", {
                                                style: {
                                                    color: "#234B74",
                                                    borderRadius: "25px",
                                                    // border: "1px solid #234B74",
                                                    fontWeight: 500,
                                                    fontSize: "1rem",
                                                    // cursor: "pointer",
                                                },
                                            }, "共 " + this.model.options.length + " 項"),
                                        ]),
                                        m(".col-4.px-0.d-flex.justify-content-end.align-items-center", [
                                            m("span.px-2", {
                                                style: {
                                                    color: "#234B74",
                                                    borderRadius: "25px",
                                                    border: "1px solid #234B74",
                                                    fontWeight: 500,
                                                    fontSize: "1rem",
                                                    cursor: "pointer",
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    Dialog.show(PhotoSelectComponent, {
                                                        attrs: {
                                                            options: this.options
                                                        },
                                                        didHide: (returnValue) => {
                                                            this.model.options = this.options()
                                                        }
                                                    })
                                                }
                                            }, "選項設定"),
                                        ]),

                                    ])
                                ),
                            ] : "",

                            (this.model.type == "Photo" || this.model.type == "DallE2") ? [
                                m('.col-12.pb-3',
                                    m(".row.w-100.m-0", [
                                        // m(".col-12.py-2.px-0", [
                                        //     m(TextBox, {
                                        //         oninput: (e) => {
                                        //             this.model.options[0].title = e.target.value
                                        //         },
                                        //         value: this.model.options[0].title,
                                        //         type: 'text',
                                        //         label: "圖片想強調的主題",
                                        //     })
                                        // ]),
                                        m(".col-12.py-2.px-0", [
                                            m(TextArea, {
                                                oninput: (e) => {
                                                    this.model.options[0].spell = e.target.value
                                                },
                                                value: this.model.options[0].spell,
                                                type: 'text',
                                                label: "圖片咒語",
                                            })
                                        ]),
                                    ])
                                ),
                            ] : "",

                            (this.model.type == "Excel" || this.model.type == "Word" || this.model.type == "DATA" || this.model.type == "Template") ? [
                                m('.col-12.pb-3',
                                    m(".row.w-100.m-0", [
                                        // m(".col-12.py-2.px-0", [
                                        //     m(TextBox, {
                                        //         oninput: (e) => {
                                        //             this.model.options[0].title = e.target.value
                                        //         },
                                        //         value: this.model.options[0].title,
                                        //         type: 'text',
                                        //         label: "圖片想強調的主題",
                                        //     })
                                        // ]),
                                        // m(".col-12.py-2.px-0", [
                                        //     m(TextArea, {
                                        //         oninput: (e) => {
                                        //             this.model.options[0].spell = e.target.value
                                        //         },
                                        //         value: this.model.options[0].spell,
                                        //         type: 'text',
                                        //         label: "咒語",
                                        //     })
                                        // ]),
                                    ])
                                ),
                            ] : "",

                            (this.model.type == "Template") ? [
                                // this.model.options[0] = { "isDefault": false, "data": null, "type": "word" }
                                m('.col-12.pb-3',
                                    m(".row.w-100.m-0", [
                                        m(".col-12.py-2.px-0", [
                                            m(".px-4", [
                                                m(".form-radio.form-radio-flat.my-2.d-flex.justify-content-center.align-items-center",
                                                    m("label.form-check-label.pl-4", {
                                                        style: {
                                                            fontWeight: 700,
                                                        }
                                                    }, [
                                                        m("input.form-check-input[type='radio']", {
                                                            checked: this.model.options[0].isDefault,
                                                            style: "color:#000",
                                                            onclick: () => {
                                                                this.model.options[0].isDefault = !this.model.options[0].isDefault
                                                            }
                                                        }),
                                                        "預設套版文件",
                                                        m("i.input-helper")
                                                    ]
                                                    )
                                                ),
                                            ]),
                                        ]),
                                        (this.model.options[0].isDefault) ? [

                                            m(`input.g-file_browser.file_default_data[type="file"][name="files[]"][accept=".docx,.xlsx"]`, {
                                                onchange: (e) => {
                                                    if (e.target.files.length === 0) {
                                                        return false
                                                    }
                                                    if (!this.uploader.filterImageExtensionByType(e.target.files, ['.docx', '.xlsx'])) {
                                                        alert('不支援上傳的檔案格式, 請選擇正確的文件檔!')
                                                        return false
                                                    }
                                                    this.uploader.uploadTemplateToServer(`${window.BASE_URL}/api/upload/template`, e.target.files[0]).then((response) => {
                                                        this.model.options[0].data = response
                                                        this.model[this.question['question']] = this.question['variable']
                                                    })
                                                },
                                            }),

                                            (this.model.options[0].data != null) ? [
                                                m(".col-8.py-2.text-center", [
                                                    m("span", {
                                                        style: {
                                                            fontWeight: 600,
                                                            fontSize: "1rem",
                                                            color: "#B6B6B6",
                                                        },
                                                    },
                                                        this.model.options[0].data.name
                                                    ),
                                                ]),
                                                m(".col-3.py-2.text-center", [
                                                    m("span", {
                                                        style: {
                                                            fontWeight: 600,
                                                            fontSize: "1rem",
                                                            color: "#B6B6B6",
                                                        },
                                                    },
                                                        this.model.options[0].data.type
                                                    ),
                                                ]),
                                                m(".col-1.py-2.text-center", [
                                                    m("i.fa.fa-eye", {
                                                        style: {
                                                            fontSize: "1.5rem",
                                                            color: "#A3E0E2"
                                                        },
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            Setting.getDocumentExample(this.model.options[0].data.path, this.model.options[0].data.type).then((_response) => {
                                                                console.log(_response)
                                                                Swal.fire({
                                                                    title: "預覽效果",
                                                                    html: "<div class='text-left'>" + _response.data.replace(/ /g, "&nbsp;").replace(/\n/g, "<br>").replace(/\t/g, "&nbsp;&nbsp;") + "</div>",
                                                                    confirmButtonText: "關閉"
                                                                });
                                                            })
                                                        }
                                                    })
                                                ]),
                                            ] : [],
                                            m('.col-12.pb-4', [
                                                m(".w-100.text-center.py-2", {
                                                    style: {
                                                        color: "#000000",
                                                        // backgroundColor: "#000000",
                                                        // borderRadius: "15px",
                                                        border: "1px solid #ced4da",
                                                        fontWeight: 700
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        $(`.file_default_data`).click()
                                                    }
                                                }, m("i.fa-solid.fa-cloud-arrow-up.pr-2"), "上傳文件")
                                            ]),

                                        ] : ""
                                    ])
                                ),
                            ] : "",

                            // (this.model.type == "Recording") ? [
                            //     m('.col-12.pb-3',
                            //         m(".row.w-100.m-0", [
                            //             // m(".col-12.py-2.px-0", [
                            //             //     m(TextBox, {
                            //             //         oninput: (e) => {
                            //             //             this.model.options[0].title = e.target.value
                            //             //         },
                            //             //         value: this.model.options[0].title,
                            //             //         type: 'text',
                            //             //         label: "圖片想強調的主題",
                            //             //     })
                            //             // ]),
                            //             m(".col-12.py-2.px-0", [
                            //                 m(TextArea, {
                            //                     oninput: (e) => {
                            //                         this.model.options[0].spell = e.target.value
                            //                     },
                            //                     value: this.model.options[0].spell,
                            //                     type: 'text',
                            //                     label: "咒語",
                            //                 })
                            //             ]),
                            //         ])
                            //     ),
                            // ] : "",




                            m('.col-6.pb-3',
                                m(".px-4", [
                                    m(".form-radio.form-radio-flat.my-2.d-flex.justify-content-center.align-items-center",
                                        m("label.form-check-label.pl-4", {
                                            style: {
                                                fontWeight: 700,
                                            }
                                        }, [
                                            m("input.form-check-input[type='radio']", {
                                                checked: this.model.required,
                                                style: "color:#000",
                                                onclick: () => {
                                                    this.model.required = !this.model.required
                                                }
                                            }),
                                            "必填",
                                            m("i.input-helper")
                                        ]
                                        )
                                    ),
                                ]),
                            ),
                            m('.col-6.pb-3',
                                m(".px-4", [
                                    m(".form-radio.form-radio-flat.my-2.d-flex.justify-content-center.align-items-center",
                                        m("label.form-check-label.pl-4", {
                                            style: {
                                                fontWeight: 700,
                                            }
                                        }, [
                                            m("input.form-check-input[type='radio']", {
                                                checked: this.model.isAdvice,
                                                style: "color:#000",
                                                onclick: () => {
                                                    this.model.isAdvice = !this.model.isAdvice
                                                }
                                            }),
                                            "需要GPT建議",
                                            m("i.input-helper")
                                        ]
                                        )
                                    ),
                                ]),
                            ),

                            (this.model.isAdvice) ? [
                                m('.col-12.pb-3',
                                    m(TextBox, {
                                        oninput: (e) => {
                                            this.model.adviceComment = e.target.value
                                        },
                                        value: this.model.adviceComment,
                                        type: 'text',
                                        label: "建議內容",
                                        tips: "給出四個建議，約50字"
                                    })
                                ),
                            ] : "",

                            m('.col-12.pb-3',
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.sort = e.target.value
                                    },
                                    value: this.model.sort,
                                    type: 'number',
                                    label: "排序",
                                    tips: "數值越小排序越高"
                                })
                            ),
                            m('.w-100'),
                        ])
                    ])
                ]),
                m('.modal-footer', [
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#41BCC3",
                            borderRadius: "25px",
                            border: "2px solid #41BCC3",
                            fontWeight: 700,
                            fontSize: "1.25rem",
                            cursor: "pointer",
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            this.close(true, this.model)
                        }
                    }, '確定'),
                    m(".p-1"),
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            fontWeight: 700,
                            fontSize: "1.25rem",
                            cursor: "pointer",
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            this.close(false, null)
                        }
                    }, '關閉')
                ])
            ])
        ])
    }
}

export default QuestionDialog
import m from 'mithril'
// import moment from 'moment'
// import BaseModel from './_base'

// let _datas = []

// const constraints = {
//     question: {
//         presence: {
//             allowEmpty: false,
//             message: "^問題名稱"
//         }
//     },
//     type: {
//         presence: {
//             allowEmpty: false,
//             message: "^請選擇回答類別"
//         }
//     },
//     title: {
//         presence: {
//             allowEmpty: false,
//             message: "^問題名稱"
//         }
//     },

    
// }

export default class Question {
    constructor(args) {
        // super(constraints)
        // args = (args) ? args : {};
        this.id = args.id || 0;
        this.question = args.question || null;
        this.title = args.title || null;
        this.tips = args.tips || null;
        this.required = args.required || false;
        this.defaultAnswer = args.defaultAnswer || null;
        this.options = args.options || [];
        this.type = args.type || "TextBox";
        this.isPhoto = args.isPhoto || false;
        this.isAdvice = args.isAdvice || false;
        this.adviceComment = args.adviceComment || null;
        this.sort = args.sort || 99;
        this.model_id = args.model_id || null;
        this.variable = args.variable || null;

    }

    // static get datas() {
    //     return _datas;
    // }

    // save() {
    //     Question.loading = true
    //     return m.request({
    //         method: 'POST',
    //         url: `/api/setting/question/save`,

    //         data: this
    //     }).then((response) => {
    //         Question.loading = false
    //         return response
    //     })
    // }

    // static remove(id) {
    //     Question.loading = true
    //     return m.request({
    //         method: 'POST',
    //         url: `/api/setting/question/remove`,

    //         data: {
    //             id: id
    //         }
    //     }).then((response) => {
    //         Question.loading = false
    //         return response
    //     })
    // }

}
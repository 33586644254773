import classNames from 'classnames';
import m from 'mithril';
import { User, UserBox, UserOption } from 'app/models';
import {
    Dialog, TextBox, GoHome, ResultBox, QuestionBox
} from 'components';
import BoxEditComponent from './dialogs/boxEdit';
import SearchBox from './dialogs/searchBox';
import * as Components from 'components'


export default class BoxSettingPage {
    constructor() {
        this.reSortTree()
        this.selectedItem = null
        this.change_article = false
        this.select_article = 0
        this.article_model = null
        this.questions = null
        this.model = null
        this.articles = null
        window.search_keyword = null
    }
    reSortTree() {
        this.items = [];
        this.items_failed = [];
        UserBox.getBox().then((_response) => {
            _response.forEach((_item) => {
                if (_item.is_root) {
                    this.items.push({
                        id: _item.id,
                        type: 'folder',
                        name: _item.title,
                        subtitle: _item.subtitle,
                        items: _item.articles,
                        is_open: false,
                        children: [],
                    });
                } else {
                    let parent = this.findItemById(this.items, _item.parent_id);
                    if (parent) {
                        parent.children.push({
                            id: _item.id,
                            type: 'folder',
                            name: _item.title,
                            subtitle: _item.subtitle,
                            items: _item.articles,
                            is_open: false,
                            children: [],
                        });
                    } else {
                        this.items_failed.push(_item);
                    }
                }
            });
            this.processFailedItems();
        });
    }
    processFailedItems() {
        let retry = [];

        this.items_failed.forEach((_item) => {
            let parent = this.findItemById(this.items, _item.parent_id);
            if (parent) {
                parent.children.push({
                    id: _item.id,
                    type: 'folder',
                    name: _item.title,
                    subtitle: _item.subtitle,
                    items: _item.articles,
                    is_open: false,
                    children: [],
                });
            } else {
                retry.push(_item);
            }
        });

        if (retry.length !== this.items_failed.length) {
            this.items_failed = retry;
            if (this.items_failed.length > 0) {
                this.processFailedItems();
            }
        }
    }
    findItemById(items, id) {
        for (let item of items) {
            if (item.id === id) {
                return item;
            } else if (item.children.length > 0) {
                let found = this.findItemById(item.children, id);
                if (found) return found;
            }
        }
        return null;
    }
    addFolder() {
        Dialog.show(BoxEditComponent, {
            attrs: {
                model: new UserBox(),
            },
            didHide: (returnValue) => {
                if (returnValue) {
                    this.reSortTree();
                }
            }
        });
    }
    addFolderToFolder(model) {
        Dialog.show(BoxEditComponent, {
            attrs: {
                model: new UserBox(),
                parent_model: new UserBox(model)
            },
            didHide: (returnValue) => {
                if (returnValue) {
                    this.reSortTree();
                }
            }
        });
    }
    deleteItem(index, parentIndex = null) {
        if (parentIndex !== null) {
            this.items[parentIndex].items.splice(index, 1);
        } else {
            this.items.splice(index, 1);
        }
        m.redraw();
    }
    selectItem(item) {
        this.change_article = true
        m.redraw()
        this.selectedItem = item;
        if (item.type != "folder") {
            this.article_model = item.detail
            this.questions = item.detail.question
            this.model = item.detail.model
        }
        this.change_article = false
        m.redraw()
    }
    renderItems(item, level) {
        return m(".row.w-100.m-0.pt-2", [
            m(".col-12.px-0", [
                m("div.select_hover", {
                    style: {
                        paddingLeft: (level * 15) + "px"
                    },
                    onclick: () => this.selectItem(item)
                }, [
                    m(".d-flex.justify-content-between", [
                        m(".", {

                        }, [
                            (item.type == "folder") ?
                                m("i.fa-solid.pr-3", {
                                    style: {
                                        fontSize: "1.25rem"
                                    },
                                    class: classNames({
                                        "fa-chevron-right": !item.is_open,
                                        "fa-angle-down": item.is_open,
                                    }),
                                    onclick: (e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        item.is_open = !item.is_open
                                    }
                                }) : "",
                            m("i.fa.pr-2", {
                                style: {
                                    fontSize: "1.25rem"
                                },
                                class: classNames({
                                    "fa-box": (item.type == "folder"),
                                    "fa-file": (item.type != "folder"),
                                    "text-dark": (item.type != "folder"),
                                    "text-warning": (item.type == "folder"),
                                }),
                            }),
                            m("span", {
                                style: {
                                    fontSize: "1.25rem"
                                },
                            }, (item.type == "folder") ? item.name : item.model_name),

                        ]),
                        (item.type == "folder") ?
                            m("button.delete-button", {
                                onclick: (e) => {
                                    if (item.type == "folder") {
                                        Dialog.show(BoxEditComponent, {
                                            attrs: {
                                                model: new UserBox(item),
                                            },
                                            didHide: (returnValue) => {
                                                if (returnValue) {
                                                    this.reSortTree();
                                                }
                                            }
                                        });
                                    } else {
                                        console.log(item)
                                    }
                                    // this.deleteItem(index, level > 0 ? level - 1 : null)
                                }

                            }, "編輯") : ""
                    ])
                ]),
                (item.type === 'folder' && item.is_open) ? item.items.map(subItem => this.renderItems(subItem, level + 1)) : null,
                (item.type === 'folder' && item.children.length > 0 && item.is_open) ? item.children.map(child => this.renderItems(child, level + 1)) : null,
            ])
        ]);
    }
    renderArticles(items) {
        return items.map((_item) => {
            return m(".select_hover", {
                onclick: () => this.selectItem(_item)
            }, [
                m(".d-flex.justify-content-between", [
                    m(".", [
                        m("i.fa.fa-file.text-dark.pr-2", {
                            style: {
                                fontSize: "1.25rem"
                            },
                        }),
                        m("span", {
                            style: {
                                fontSize: "1.25rem"
                            },

                        }, _item.model_name),
                    ]),
                    m("span.text-danger", {
                        onclick: ((e) => {
                            e.preventDefault();
                            this.deleteItem(index, level > 0 ? level - 1 : null)
                        })
                    }, "刪除")
                ])
            ]);
        })
    }

    view() {
        return [
            m(".row.m-0.w-100.position-relative", {
                style: {
                    minHeight: "100vh"
                }
            }, [
                m(GoHome),
                //搜尋引擎
                m(".row.position-fixed", {
                    style: {
                        bottom: "20px",
                        right: "20px",
                        zIndex: 2048
                    }
                }, [
                    m(".col-12.text-center.px-4.py-2", {
                        style: {
                            border: "1px solid #EFEFEF",
                            borderRadius: "20px",
                            // cursor: "pointer"
                        }
                    }, [
                        m(TextBox, {
                            oninput: (e) => {
                                window.search_keyword = e.target.value
                            },
                            onkeydown: (e) => {
                                if (e.keyCode == 13) {
                                    Dialog.show(SearchBox, {
                                        attrs: {
                                            search_keyword: window.search_keyword,
                                        },
                                        didHide: (returnValue) => {
                                            if (returnValue) {
                                                console.log(returnValue)
                                                this.selectItem(returnValue);
                                            }
                                        }
                                    });
                                }
                            },
                            tips: "關鍵字搜尋",
                            value: window.search_keyword,
                            type: 'text',
                        })
                    ])
                ]),
                m(".col-md-2.col-12.px-0", [
                    m("div.file-explorer.h-100", [
                        m("span.font-weight-bold", {
                            style: {
                                fontSize: "1.75rem"
                            }
                        }, "我的盒子"),

                        m(".row.w-100.m-0.pb-1.pt-3", [
                            m(".col-12.text-center.px-0.py-2", {
                                style: {
                                    border: "2px dashed #000000",
                                    borderRadius: "20px",
                                    cursor: "pointer"
                                }
                            }, [
                                m("span", {
                                    style: {
                                        fontSize: "1.25rem"
                                    },
                                    onclick: ((e) => {
                                        e.preventDefault();
                                        this.addFolder();
                                    })
                                }, "新增盒子"),
                            ])
                        ]),
                        this.items.map(item => this.renderItems(item, 0)),


                    ])
                ]),
                m(".col-md-10.col-12.px-0", [
                    m("div.right-pane", [
                        this.selectedItem ? [
                            m(".py-2", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    if (this.selectedItem.type == "folder") {
                                        Dialog.show(BoxEditComponent, {
                                            attrs: {
                                                model: new UserBox(this.selectedItem),
                                            },
                                            didHide: (returnValue) => {
                                                if (returnValue) {
                                                    this.reSortTree();
                                                }
                                            }
                                        });
                                    } else {
                                        Swal.fire({
                                            title: "請輸入想修改的名字",
                                            input: "text",
                                            inputValue: this.selectedItem.model_name,
                                            showCancelButton: true,
                                        }).then((response) => {
                                            if (response.isConfirmed) {
                                                UserBox.modifyContentTitle(this.selectedItem.id, response.value).then((_response) => {
                                                    this.selectedItem.model_name = _response.model_name
                                                })
                                            }
                                        });
                                    }
                                }
                            }, [
                                m(".i.fa.pr-2", {
                                    style: {
                                        fontSize: "2rem"
                                    },
                                    class: classNames({
                                        "fa-box": (this.selectedItem.type == "folder"),
                                        "fa-file": (this.selectedItem.type != "folder"),
                                        "text-dark": (this.selectedItem.type != "folder"),
                                        "text-warning": (this.selectedItem.type == "folder"),
                                    }),
                                }),
                                m("span.font-weight-bold.pl-3", {
                                    style: {
                                        fontSize: "2rem"
                                    }
                                }, (this.selectedItem.type == "folder") ? this.selectedItem.name : this.selectedItem.model_name),
                                (this.selectedItem.type == "folder") ? [
                                    m("span.d-block", {
                                        style: {
                                            fontSize: "1rem"
                                        },
                                    }, this.selectedItem.subtitle),
                                ] : ""
                            ]),

                            m(".px-2", {
                                style: {
                                    minHeight: "2px",
                                    backgroundColor: "#EAEAEA"
                                }
                            }),
                            m(".row.w-100.m-0.pt-3", [
                                this.selectedItem.type === 'folder' ? [
                                    m(".col-md-2.col-12", [
                                        m(".a_black_border.px-3", {
                                            onclick: (e) => {

                                            }
                                        }, [
                                            m("span.a_black_button", { onclick: () => this.addFolderToFolder(this.selectedItem) }, "新增盒子"),
                                        ]),
                                    ]),
                                    m(".col-12.pt-3", [
                                        m(".row.m-0.w-100", [
                                            m(".col-md-2.col-12", [
                                                this.renderArticles(this.selectedItem.items)
                                            ])
                                        ])

                                    ])
                                ] : [
                                    (!this.change_article) ? [
                                        m(".row.w-100.m-0.py-1", [

                                            m(".col-md-4.col-12.p-3.order-1.order-md-0", {
                                                style: {
                                                    backgroundColor: "#FAFAFA",
                                                    borderRadius: "5px"
                                                }
                                            }, [
                                                m("span.d-block.text-center.py-2", {
                                                    style: {
                                                        fontWeight: 800,
                                                        fontSize: "1.25rem"
                                                    }
                                                }, this.article_model.model_name),
                                                this.questions.map((_question) => {
                                                    return m(QuestionBox, {
                                                        question: _question,
                                                        model: this.model,
                                                        question_type: "box",
                                                    })
                                                }),
                                            ]),
                                            m(".col-md-8.col-12.order-md-1.order-0.pt-3.pt-md-0", [
                                                m(ResultBox, {
                                                    articles: this.article_model,
                                                    step_type: (this.article_model.articles[this.select_article].hasOwnProperty('message')) ? "view_article" : "view_photo",
                                                    result_type: "box",
                                                }),
                                            ]),
                                        ]),
                                    ] : ""
                                ]
                            ]),
                        ] : m("h3", "請選擇一個文件或盒子")
                    ])
                ])
            ]),
            m(Dialog)
        ];
    }
}

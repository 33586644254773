import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _datas = []

const constraints = {

}

class UserRenew extends BaseModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};

    }

    static get datas() {
        return _datas;
    }

    // static create() {
    //     UserRenew.loading = true
    //     return m.request({
    //         method: 'POST',
    //         url: `/api/userRenew/create`,

    //     }).then((response) => {
    //         _datas = response
    //         UserRenew.loading = false
    //         return response
    //     })
    // }

    static getLastRecord() {
        UserRenew.loading = true
        return m.request({
            method: 'POST',
            url: `/api/manager/userRenew/getLastRecord`,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
        }).then((response) => {
            _datas = response
            UserRenew.loading = false
            return response
        })
    }

    

    

}

export default UserRenew
import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea,
    Paging
} from 'components'
import {
    Member,
    Article
} from 'app/models'
import moment from 'moment'
import classNames from 'classnames'
import ArticleDetailComponent from './articleDetail'


class ContentComponent {
    constructor(vnode) {
        this.member = vnode.attrs.member
        window.memberIdSelect = this.member.id
        this.loading = true
        window.select_type = "文章記錄"
        this.types = ["文章記錄", "圖片紀錄", "Token使用紀錄", "續約紀錄"]

        Article.getArticleList(Article.pageIndex)

    }
    change_level(level) {
        switch (level) {
            case 2:
                return m("span", { style: { color: "#000000" } }, "入門使用");
            case 3:
                return m("span", { style: { color: "#000000" } }, "進階使用");
            case 4:
                return m("span", { style: { color: "#000000" } }, "專業使用");
        }
    }

    change_status(status) {
        switch (status) {
            case 1:
                return "前往付款";
            case 2:
                return "已完成";
            case 3:
                return "已取消";
            case 4:
                return "申訴中";
            case 5:
                return "已退款";
            case 99:
                return m("span", { style: { color: "#FF0000" } }, "付款失敗");
        }
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return m('.modal-dialog.modal-lg', [
            m('.modal-content', [
                m('.modal-header', [
                    m('h5.modal-title', [
                        m('span', "客戶資訊")
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body", [
                    //個人資訊
                    m(".row.m-0.w-100.py-2", [
                        m(".col-1.d-flex.justify-content-center.align-items-center", [
                            m("div.img-fluid.rounded-circle", {
                                style: {
                                    height: "80px",
                                    width: "80px",
                                    maxHeight: "80px",
                                    maxWidth: "80px",
                                    backgroundImage: `url("${this.member.profile}")`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    border: "1px solid #000000",
                                    cursor: "pointer",
                                },
                            })
                        ]),
                        m(".col-11", [
                            m(".row.m-0.w-100.pt-2", [
                                m('.col-md-3.px-1',
                                    m(".form-group", [
                                        m("label.text-dark.mb-1", "顧客名稱"),
                                        [
                                            m(TextBox, {
                                                class: 'mt-0 form-control',
                                                value: this.member.nickname,
                                                type: 'text',
                                                readonly: true
                                            })
                                        ]
                                    ]),
                                ),
                                m('.col-md-3.px-1',
                                    m(".form-group", [
                                        m("label.text-dark.mb-1", "使用Token數"),
                                        [
                                            m(TextBox, {
                                                class: 'mt-0 form-control',
                                                value: this.member.use_tokens.toLocaleString(),
                                                type: 'text',
                                                readonly: true
                                            })
                                        ]
                                    ]),
                                ),
                                m('.col-md-3.px-1',
                                    m(".form-group", [
                                        m("label.text-dark.mb-1", "剩餘Token數"),
                                        [
                                            m(TextBox, {
                                                class: 'mt-0 form-control',
                                                value: this.member.remain_tokens.toLocaleString(),
                                                type: 'text',
                                                readonly: true
                                            })
                                        ]
                                    ]),
                                ),
                                m('.col-md-3.px-1',
                                    m(".form-group", [
                                        m("label.text-dark.mb-1", "產生文章數"),
                                        [
                                            m(TextBox, {
                                                class: 'mt-0 form-control',
                                                value: this.member.articles_count.toLocaleString(),
                                                type: 'text',
                                                readonly: true
                                            })
                                        ]
                                    ]),
                                ),
                                m('.col-md-3.px-1',
                                    m(".form-group", [
                                        m("label.text-dark.mb-1", "產生圖片數"),
                                        [
                                            m(TextBox, {
                                                class: 'mt-0 form-control',
                                                value: this.member.photos_count.toLocaleString(),
                                                type: 'text',
                                                readonly: true
                                            })
                                        ]
                                    ]),
                                ),
                                m('.col-md-3.px-1',
                                    m(".form-group", [
                                        m("label.text-dark.mb-1", "最後使用時間"),
                                        [
                                            m(TextBox, {
                                                class: 'mt-0 form-control',
                                                value: moment(this.member.updated_at).format("YYYY/MM/DD hh:mm"),
                                                type: 'text',
                                                readonly: true
                                            })
                                        ]
                                    ]),
                                ),
                                m('.col-md-3.px-1',
                                    m(".form-group", [
                                        m("label.text-dark.mb-1", "加入時間"),
                                        [
                                            m(TextBox, {
                                                class: 'mt-0 form-control',
                                                value: moment(this.member.created_at).format("YYYY/MM/DD hh:mm"),
                                                type: 'text',
                                                readonly: true
                                            })
                                        ]
                                    ]),
                                ),




                            ]),
                        ]),
                    ]),
                    //Type選擇
                    m(".row.m-0.w-100", {
                        style: {
                            backgroundColor: "#EAEAEA"
                        }
                    }, [
                        this.types.map((_type) => {
                            return m(".col.d-flex.justify-content-center.align-items-center.py-2", {
                                style: {
                                    backgroundColor: (window.select_type == _type) ? "#252525" : "#EAEAEA",
                                    color: (window.select_type == _type) ? "#FFFFFF" : "#252525",
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    window.select_type = _type
                                    Article.pageIndex = 1
                                    Article.getArticleList(Article.pageIndex)
                                }
                            }, [
                                m("span", {
                                    style: {
                                        fontSize: "1.2rem"
                                    }
                                }, _type)
                            ])
                        })
                    ]),
                    m(".row.m-0.w-100.py-2", [
                        Article.data.map((_record, _index) => {
                            if (window.select_type == "文章記錄" || window.select_type == "圖片紀錄") {
                                return [
                                    m(".col-12.pb-1.px-0", [
                                        m(".row.m-0.w-100.border.py-1", {
                                            onclick: (e) => {
                                                e.preventDefault()
                                                Dialog.show(ArticleDetailComponent, {
                                                    attrs: {
                                                        model: _record,
                                                    },
                                                })
                                            }
                                        }, [
                                            m(".col-1.d-flex.justify-content-start.align-items-center", [
                                                m("span.font-weight-bold", { style: { fontSize: "1rem" } }, _index + 1)]),
                                            m(".col-6.d-flex.justify-content-start.align-items-center", [
                                                m("span.font-weight-bold", { style: { fontSize: "1rem" } }, _record.model_name)]),
                                            m(".col-5.d-flex.justify-content-end.align-items-center", [
                                                m("span", { style: { color: "grey", fontSize: "0.8rem" } }, moment(_record.created_at).format("Y-MM-DD hh:mm"))
                                            ]),

                                        ]),
                                    ])
                                ];
                            } else if (window.select_type == "Token使用紀錄") {
                                return [
                                    m(".col-12.pb-1", [
                                        m(".row.m-0.w-100.border.py-1", [
                                            m(".col-1.d-flex.justify-content-start.align-items-center", [
                                                m("span.font-weight-bold", { style: { fontSize: "1rem" } }, _index + 1)]),
                                            m(".col-4.d-flex.justify-content-start.align-items-center", [
                                                m("span.font-weight-bold", { style: { fontSize: "1rem" } }, _record.model_name)]),
                                            m(".col-5.d-flex.justify-content-start.align-items-center", [
                                                m("span.font-weight-bold", { style: { color: "grey", fontSize: "1rem" } }, _record.step_name)
                                            ]),
                                            m(".col-2.d-flex.justify-content-start.align-items-center", [
                                                m("span.font-weight-bold", { style: { color: "grey", fontSize: "1rem" } }, _record.use_tokens.toLocaleString())
                                            ]),
                                            m(".col-12.d-flex.justify-content-end.align-items-center", [
                                                m("span", { style: { color: "grey", fontSize: "0.8rem" } }, moment(_record.created_at).format("Y-MM-DD hh:mm"))
                                            ]),

                                        ]),
                                    ])
                                ];
                            }
                            else if (window.select_type == "續約紀錄") {
                                return [
                                    m(".col-12.pb-1", [
                                        m(".row.m-0.w-100.border.py-1", [
                                            m(".col-1.d-flex.justify-content-start.align-items-center", [
                                                m("span.font-weight-bold", { style: { fontSize: "1rem" } }, (_index + 1) + ".")]),
                                            m(".col-2.d-flex.justify-content-start.align-items-center", [
                                                m("span.font-weight-bold", { style: { fontSize: "1rem" } }, _record.serial)]),

                                            m(".col-1.d-flex.justify-content-center.align-items-center", [
                                                m("span.font-weight-bold", { style: { fontSize: "1rem" } }, "$ " + _record.cost)]),
                                            m(".col-2.d-flex.justify-content-center.align-items-center.py-1.px-0", [
                                                m("span.font-weight-bold.py-0", {
                                                    class: classNames({
                                                        'btn': _record.status == 1,
                                                        'btn-outline-danger': _record.status == 1
                                                    }),
                                                    style: {
                                                        fontSize: ".9rem"
                                                    },
                                                }, this.change_status(_record.status))]),


                                            m(".col-2.d-flex.justify-content-center.align-items-center", [
                                                m("span.font-weight-bold", { style: { fontSize: "1rem" } }, this.change_level(_record.level))]),
                                            m(".col-2.d-flex.justify-content-end.align-items-center", [
                                                m("span", { style: { color: "grey", fontSize: "0.8rem" } }, moment(_record.created_at).format("Y-MM-DD hh:mm"))
                                            ]),
                                        ]),
                                    ])
                                ];
                            }

                        }),
                        m('.col-12.pt-3.px-0', [
                            m('.d-flex.flex-sm-row.flex-column.justify-content-md-between', [
                                m(Paging.Pagination, {
                                    pageNo: Article.paging.pageNo,
                                    pageCount: Article.paging.pageCount,
                                    pageUrl: Article.getArticleList.bind(this)
                                }),
                            ])
                        ])
                    ]),
                ]),
            ])
        ])
    }
}

export default ContentComponent
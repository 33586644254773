import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _data = []
let _use_record = []
let _article_record = []
let _renew_record = []


const constraints = {
    name: {
        presence: {
            allowEmpty: false,
            message: "^請輸入名字"
        }
    },

    password: function (value, attributes, attributeName, options, constraints) {
        if (attributes.changePassword) {
            return {
                presence: {
                    allowEmpty: false,
                    message: "^請輸入新密碼"
                },
                format: {
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
                    message: "^請輸入至少6個字元，需包含英文大小寫及數字"
                }
            }
        }

        return false;
    },
    confirmPassword: function (value, attributes, attributeName, options, constraints) {
        if (attributes.changePassword) {
            return {
                presence: {
                    allowEmpty: false,
                    message: "^請輸入確認密碼"
                },
                equality: {
                    attribute: "password",
                    message: "^密碼不相符",
                    comparator: (v1, v2) => {
                        return JSON.stringify(v1) === JSON.stringify(v2);
                    }
                }
            }
        }
        return false;
    }
}


let _user = {}

class User extends BaseModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.profile = args.profile || null;
        this.level = args.level || 1;
        this.nickname = args.nickname || null;
        this.email = args.email || null;
        this.line_id = args.line_id || null;
        this.use_tokens = args.use_tokens || 0;
        this.remain_tokens = args.remain_tokens || 0;
        this.diedline = moment(args.diedline).format('Y-M-D') || moment().format('Y-M-D');

    }

    static get data() {
        return _data;
    }

    static get Account() {
        return _user
    }
    static get useRecord() {
        return _use_record
    }
    static get articleRecord() {
        return _article_record
    }
    static get renewRecord() {
        return _renew_record
    }

    static load(id) {
        User.loading = true
        if (!id) {
            _user = new User()
            return null
        }
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/manager/user/${id}`,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
            data: [id]
        }).then((response) => {
            _user = response
            User.loading = false
            return response
        })
    }

    static getUseRecord(page) {
        User.loading = true
        page = (page === undefined) ? User.pageIndex : page
        User.pageIndex = page
        let url = `${window.BASE_URL}/api/manager/user/useRecord?page=${page}`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
        }).then((response) => {
            User.paging = {
                pageCount: response.last_page,
                pageNo: response.current_page,
                pageSize: response.per_page,
                totalRecordCount: response.total
            }
            _use_record = response.data
            User.loading = false
            return _use_record
        })
    }

    static getLastRecord(model_id) {
        User.loading = true
        let url = `${window.BASE_URL}/api/manager/user/articleRecord/last`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
            data: {
                model_id: model_id
            }
        }).then((response) => {
            User.loading = false
            return response
        })
    }

    static articleNewSave(articles) {
        User.loading = true
        let url = `${window.BASE_URL}/api/manager/user/articleNewSave`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
            data: {
                articles: articles
            }
        }).then((response) => {
            User.loading = false
            return _article_record
        })
    }


    static articleRecordSave(id, articles) {
        User.loading = true
        let url = `${window.BASE_URL}/api/manager/user/articleRecordSave`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
            data: {
                id: id,
                articles: articles
            }
        }).then((response) => {
            User.loading = false
            return _article_record
        })
    }



    static getArticleRecord(page) {
        User.loading = true
        page = (page === undefined) ? User.pageIndex : page
        User.pageIndex = page
        let url = `${window.BASE_URL}/api/manager/user/articleRecord?page=${page}`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
        }).then((response) => {
            User.paging = {
                pageCount: response.last_page,
                pageNo: response.current_page,
                pageSize: response.per_page,
                totalRecordCount: response.total
            }
            _article_record = response.data
            User.loading = false
            return _article_record
        })
    }

    static getRenewRecord(page) {
        User.loading = true
        page = (page === undefined) ? User.pageIndex : page
        User.pageIndex = page
        let url = `${window.BASE_URL}/api/manager/user/renewRecord?page=${page}`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
        }).then((response) => {
            User.paging = {
                pageCount: response.last_page,
                pageNo: response.current_page,
                pageSize: response.per_page,
                totalRecordCount: response.total
            }
            _renew_record = response.data
            User.loading = false
            return _renew_record
        })
    }



    static createRenew(level, invoice) {
        User.loading = true
        let url = `${window.BASE_URL}/api/manager/userRenew/create`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
            data: {
                level: level,
                invoice: invoice
            }
        }).then((response) => {
            User.loading = false
            return response
        })
    }

}

export default User
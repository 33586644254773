import m from 'mithril'
import stream from 'mithril/stream'
import uuid from 'uuid'

let defaultOptions = {
    preview: false
}

class Uploader {
    constructor(args) {
        this.uploading = false
        this.progress = stream()
        this.imgSrc = stream()
        this.filename = uuid()
        this.user = ""
    }
    filterImageExtension(files) {
        for (let i = 0; i < files.length; i++) {
            if (/^image/.test(files[i].type)) {
                return true;
            }
        }
        return false;
    }
    filterImageExtensionByType(files, validExts) {
        let _check = true
        for (let i = 0; i < files.length; i++) {
            var fileExt = files[i].name;
            fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.indexOf(fileExt) < 0) {
                _check = false
            }
        }
        return _check;
    }
    filterDataExtension(files) {
        for (let i = 0; i < files.length; i++) {
            if (/^image/.test(files[i].type)) {
                return true;
            }
        }
        return false;
    }
    filterSize(files, size) {
        for (let i = 0; i < files.length; i++) {
            if (size > files[i].size) {
                return true;
            }
        }
        return false;
    }
    uploadToServer(file, option) {
        let formData = new FormData;
        formData.append('file', file)
        formData.append('type', option.type)
        formData.append('width', option.width)
        formData.append('height', option.height)
        formData.append('folder', option.folder)
        formData.append('user', option.user)
        this.progress = option.progresss

        // console.log("uploadImage", file, option)

        // formData.append('categoryId', categoryId)
        return m.request({
            method: 'POST',
            url: option.url,
            data: formData,
            config: (xhr) => {
                xhr.upload.addEventListener("progress", (e) => {
                    // this.progress(Math.round(e.loaded / e.total * 100))
                    // if (this.progress() == 100) {
                    //     setTimeout(() => {
                    //         this.progress(null)
                    //         m.redraw()
                    //     }, 50);
                    // } else {
                    //     m.redraw()
                    // }
                }, true)
            }
        })
    }
    uploadTemplateToServer(url, file) {
        let formData = new FormData;
        formData.append('file', file)
        formData.append("userId", window.USER_ID)

        return m.request({
            method: 'POST',
            url: url,
            data: formData,
            config: (xhr) => {
                xhr.upload.addEventListener("progress", (e) => {
                    this.progress(Math.round(e.loaded / e.total * 100))
                    if (this.progress() == 100) {
                        setTimeout(() => {
                            this.progress(null)
                            m.redraw()
                        }, 50);
                    } else {
                        m.redraw()
                    }
                }, true)
            }
        })
    }
    uploadDataToServer(url, file, checkTrans) {
        let formData = new FormData;
        // formData.append("upload", file)
        formData.append('file', file)
        // formData.append('folder', option.folder)

        if (checkTrans) {
            formData.append("checkTrans", checkTrans)
        }
        formData.append("userId", window.USER_ID)


        return m.request({
            method: 'POST',
            url: url,
            data: formData,
            config: (xhr) => {
                xhr.upload.addEventListener("progress", (e) => {
                    this.progress(Math.round(e.loaded / e.total * 100))
                    if (this.progress() == 100) {
                        setTimeout(() => {
                            this.progress(null)
                            m.redraw()
                        }, 50);
                    } else {
                        m.redraw()
                    }
                }, true)
            }
        })
    }
    uploadImage(file, options) {
        const _options = Object.assign({}, defaultOptions, options)
        let _preivew = null
        if (_options.preview) {
            _preivew = this.preview(file, options)
        }
        const upload = this.uploadToServer(file, options)
        return Promise.all([_preivew, upload]).then((values) => {
            this.imgSrc(window.BASE_URL + values[1].url)
            return values[1]
        })
    }
    preview(file, options) {
        let self = this
        const reader = new FileReader()
        reader.readAsDataURL(file)
        return new Promise((resolve, reject) => {
            reader.onload = function (e) {
                let img = new Image()

                img.src = e.target.result
                img.onload = () => {
                    let canvas = self.resizeCrop(img, options.width, options.height).toDataURL('image/jpg', 90);
                    self.imgSrc(canvas)
                    resolve(canvas)
                    m.redraw()
                }
            }

            reader.onloadend = (e) => {

            }
        })
    }
    resizeCrop(src, width, height) {
        let crop = true

        if (width === undefined) {
            width = src.width
        }
        if (height === undefined) {
            height = src.height
        }
        // not resize
        if (width > src.width || height > src.height) {
            width = src.width;
            height = src.height;
            // crop = false
        }

        // check scale
        var xscale = width / src.width;
        var yscale = height / src.height;
        var scale = crop ? Math.max(xscale, yscale) : Math.min(xscale, yscale);
        // create empty canvas
        var canvas = document.createElement("canvas");
        canvas.width = width ? width : Math.round(src.width * scale);
        canvas.height = height ? height : Math.round(src.height * scale);
        canvas.getContext("2d").scale(scale, scale);
        // crop it top center
        canvas.getContext("2d").drawImage(src, ((src.width * scale) - canvas.width) * -.5, ((src.height * scale) - canvas.height) * -.5);
        return canvas;
    }
}

export default Uploader
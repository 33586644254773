import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea
} from 'components'
import * as Components from 'components'
import {
    User,
} from "app/models"
import moment from 'moment'
import classNames from 'classnames'
import RenewTicketComponent from './renewTicket'


class ModelDialog {
    constructor(vnode) {
        // this.levels = [{ id: 2, name: "一般會員", icon: "fa-seedling" }, { id: 3, name: "進階會員", icon: "fa-user-doctor" }, { id: 4, name: "高級會員", icon: "fa-user-astronaut" }];
        this.levels = [
            { id: 2, name: "入門使用", icon: "fa-seedling", price: 99, tokens: 200000, canBuy: true },
            { id: 3, name: "進階使用", icon: "fa-brands fa-pagelines", price: 299, tokens: 2000000, canBuy: false },
            { id: 4, name: "專業使用", icon: "fa-solid fa-tree", price: 399, tokens: 2000000, canBuy: false }
        ];
        this.select = null
        this.invoice_information = {
            type: null,
            company_name: "",
            eInvoice: "",
            isPaper: false,
            mail: "",
        }
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return m('.modal-dialog.modal-dialog-scrollable.modal-xl.modal-dialog-centered', [
            m('.modal-content', [
                m('.modal-header', {
                    style: {
                        backgroundColor: "#B6B6B6"
                    }
                }, [
                    m('h5.modal-title', [
                        m('span', "升級會員")
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body.p-2", [
                    m(".row.m-0.w-100.pt-2", [
                        this.levels.map((_level, _index) => {
                            if (_level.canBuy) {

                                return [
                                    m(".col-12.p-1.col-md-4", [
                                        m(".row.m-0.w-100.py-1", {
                                            style: {
                                                borderRadius: "20px",
                                                boxShadow: "5px 4px 3px #B0B0B2",
                                                border: (this.select != _index) ? "2px solid #9B9B9B" : "2px solid #4E7421"
                                            },
                                            // class: classNames({
                                            //     "border-primary": this.select == _index,
                                            // }),
                                            // onclick: (e) => {
                                            //     e.preventDefault()
                                            //     if (_index == 0) {
                                            //         this.select = _index
                                            //     }
                                            // }
                                        }, [
                                            m(".col-12.text-center", [
                                                m("span.font-weight-bold", { style: { fontSize: "1.25rem" } }, "【" + _level.name + "】"),
                                            ]),
                                            m(".col-12.pt-4.pb-2.text-center", [
                                                m(`i.fa-solid.${_level.icon}`, { style: { fontSize: "3.25rem", color: "green" } })
                                            ]),

                                            m(".col-12.text-center", [
                                                m("span.font-weight-bold", { style: { fontSize: "1.25rem" } }, (_level.canBuy) ? ("NTD: " + _level.price + " 元") : "Comming Soon"),
                                            ]),
                                            m(".col-12.py-2.text-center", [
                                                m("span.font-weight-bold", { style: { fontSize: "1rem" } }, (_level.canBuy) ? ("/ " + _level.tokens.toLocaleString() + " tokens") : "Comming Soon"),
                                            ]),
                                            (_level.canBuy) ? [
                                                m(".col-12.pb-2.pt-4", [
                                                    m("span", { style: { fontSize: "1rem" } }, "-儲存20萬 token(約10萬字)"),
                                                ]),
                                                m(".col-12.py-2", [
                                                    m("span", { style: { fontSize: "1rem" } }, "-可自由使用超過20個模板"),
                                                ]),
                                                m(".col-12.py-2", [
                                                    m("span", { style: { fontSize: "1rem" } }, "-容納1位使用者"),
                                                ]),
                                                m(".col-12.py-3.text-center", [
                                                    m("span.px-4", {
                                                        style: {
                                                            borderRadius: "25px",
                                                            border: "2px solid #B6B6B6",
                                                            fontWeight: 700,
                                                            fontSize: "1.25rem"
                                                        },
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            Dialog.show(RenewTicketComponent, {
                                                                attrs: {
                                                                    model: this.invoice_information,
                                                                    level: _level
                                                                },
                                                            })
                                                            
                                                        }

                                                    }, "購買")
                                                ])
                                            ] : ""
                                        ])
                                    ])
                                ];
                            }

                        }),
                    ]),

                    
                ]),
                // m('.modal-footer', [
                //     m(".text-center.py-1.px-3", {
                //         style: {
                //             color: "#B6B6B6",
                //             borderRadius: "25px",
                //             border: "2px solid #B6B6B6",
                //             fontWeight: 700,
                //             fontSize: "1.25rem"
                //         },
                //         onclick: this.close.bind(this)
                //     }, '關閉')
                // ])
            ])
        ])
    }
}

export default ModelDialog
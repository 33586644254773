import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea
} from 'components'
import Uploader from 'components/uploader/core'
import moment from 'moment'
import classNames from 'classnames'


class PhotoSelectDialog {
    constructor(vnode) {
        this.options = vnode.attrs.options
        this.uploader = new Uploader()
        this.progress = stream()
    }

    save() {
        let _count = 0
        this.options().map((_option) => {
            if (_option.data != null) {
                _count++
            }
        })

        this.options().map((_option, index) => {
            if (_option.data != null) {
                this.uploader.uploadImage(_option.data, {
                    url: `${window.BASE_URL}/api/upload/photo`,
                    width: 480,
                    height: 480,
                    folder: "asda",
                    type: "photo",
                    preview: "sdf",
                    progress: this.progress
                }).then((response) => {
                    this.options()[index].data = null
                    this.options()[index].url = response.path
                    if (_count - 1 == index) {
                        this.close()
                    }
                })
            }
        })
        if (_count == 0) {
            this.close()
        }
    }

    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        console.log(this.progress())
        return m('.modal-dialog.modal-lg', [
            m('.modal-content', [
                m('.modal-header', [
                    m('h5.modal-title', [
                        m('span', "選項設定")
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body", [
                    m('.row.w-100.m-0', [
                        m(".col-12.pb-2.px-0", [
                            m("span.py-0.px-3", {
                                style: {
                                    color: "#234B74",
                                    borderRadius: "25px",
                                    border: "1px solid #234B74",
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.options().push({
                                        key: null,
                                        value: null,
                                        gpt: null,
                                        data: null
                                    })
                                }
                            }, "新增選項"),
                            m(".px-2.d-inline"),
                            m("span.py-0.px-3", {
                                style: {
                                    color: "#234B74",
                                    borderRadius: "25px",
                                    border: "1px solid #234B74",
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    $(`.mutil_photo_upload`).click()
                                }
                            }, "選擇多個照片"),

                            m(`input.g-file_browser.mutil_photo_upload[type="file"][name="files[]"][accept="image/*"][multiple]`, {
                                onchange: (e) => {
                                    if (e.target.files.length === 0) {
                                        return false
                                    }
                                    Array.from(e.target.files).forEach(_data => {
                                        this.options().push({
                                            key: null,
                                            value: null,
                                            gpt: null,
                                            data: _data,
                                            url: null
                                        })
                                    });
                                }
                            }),
                        ]),

                        this.options().map((_options, index) => {
                            return m(".col-3.px-0.py-2 ", {
                                class: classNames({
                                    // "col": this.options().length > 4,
                                    // "col-3": this.options().length <= 4,
                                })
                            }, [
                                m(".row.w-100.m-0", [
                                    m(".col-12.position-relative", [
                                        ((_options.data == null || (Array.isArray(_options.data) && _options.data.length == 0)) && _options.url == null) ?
                                            m(".border.d-flex.justify-content-center.align-items-center", {
                                                style: {
                                                    minHeight: "120px",

                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    $(`.file_${index}`).click()

                                                }
                                            }, [
                                                m("i.fa.fa-cloud-upload.py-1", {
                                                    style: {
                                                        fontSize: "3rem",
                                                        color: "#234B74"
                                                    },
                                                }),
                                            ]) : [m(".border.d-flex.justify-content-center.align-items-center", {
                                                style: {
                                                    maxHeight: "120px",
                                                    minHeight: "120px",
                                                    backgroundImage: (_options.url == null) ? `url(${URL.createObjectURL(_options.data)})` : `url(${_options.url})`,
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "center",
                                                    backgroundSize: "contain"
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    $(`.file_${index}`).click()

                                                }
                                            }), m("span")],
                                        m('label.mb-1.pl-1.d-flex.justify-content-between', {
                                            style: {
                                                "font-weight": 700
                                            }
                                        },
                                            m("span", (index + 1) + ".選項"),
                                            m("i.fa.fa-trash-o.py-1", {
                                                style: {
                                                    fontSize: "1.2rem",
                                                    color: "#C63333"
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    Swal.fire({
                                                        title: "你確定要刪除此選項？",
                                                        // text: "注意：此流程刪除後，該流程所附屬咒語及問題皆會消失！",
                                                        showCancelButton: true,
                                                        confirmButtonText: "確認",
                                                        cancelButtonText: "取消",
                                                        icon: "warning"

                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            this.options().splice(index, 1)
                                                            m.redraw()
                                                        }
                                                    });
                                                }
                                            }),
                                        ),
                                        m(".col-12.d-flex.align-items-end.justify-content-center.px-0", [
                                            m("input.form-control", {
                                                type: 'text',
                                                value: this.options()[index].key,
                                                placeholder: "使用者看到選項",
                                                oninput: (e) => {
                                                    this.options()[index].key = e.target.value
                                                    this.options()[index].value = e.target.value
                                                    e.redraw = false

                                                },
                                            }),
                                        ]),
                                        m(".col-12.d-flex.align-items-end.justify-content-center.px-0.py-1", [
                                            m('textarea.form-control.w-100', {
                                                placeholder: "GPT閱讀內容",
                                                style: {
                                                    minHeight: '10rem'
                                                },
                                                oninput: (e) => {
                                                    this.options()[index].gpt = e.target.value
                                                    e.redraw = false
                                                },
                                            }, this.options()[index].gpt),
                                        ]),

                                        m(`input.g-file_browser.file_${index}[type="file"][name="files[]"][accept="image/*"][multiple]`, {
                                            onchange: (e) => {
                                                if (e.target.files.length === 0) {
                                                    return false
                                                }
                                                this.options()[index].data = e.target.files[0]
                                            }
                                        }),
                                    ]),
                                ])
                            ])
                        }),
                    ])
                ]),

                m('.modal-footer', [
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#41BCC3",
                            borderRadius: "25px",
                            border: "2px solid #41BCC3",
                            fontWeight: 700,
                            fontSize: "1.25rem",
                            cursor: "pointer",
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            this.save()
                        }
                    }, '確定'), m("span", this.progress()),
                ])
            ])
        ])
    }
}

export default PhotoSelectDialog
import m from 'mithril';
export default class GoHeader {
    view({
        attrs
    }) {
        return m(".position-fixed.d-inline-block", {
            style: {
                bottom: "15px",
                right: "15px",
                zIndex: 2025,
            }
        }, [
            m(".d-inline-block.pr-2", [
                m(".p-2.d-flex.justify-content-center.align-items-center", {
                    style: {
                        zIndex: 2025,
                        minWidth: "50px",
                        minHeight: "50px",
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                        borderRadius: "25px",
                        cursor: "pointer",
                    },
                    onclick: (e) => {
                        e.preventDefault()
                        $('html,body').animate({ scrollTop: 0 }, 333);
                    }
                }, [
                    m("i.fa.fa-arrow-up", {
                        style: {
                            fontSize: "2.2rem",
                            color: "#FFFFFF"
                        }
                    })
                ])
            ]),
        ])
    }
}
import m from 'mithril';
import stream from 'mithril/stream';
import BoxComponent from './dialogs/box';
import {
    FBPost,
    User,
    UserSpellRecord,
} from 'app/models';
import {
    Dialog,
    Editor
} from 'components';

//Googlt GTM
export default class ResultBox {
    constructor(vnode) {
        this.articles = vnode.attrs.articles;
        this.step_type = vnode.attrs.step_type;
        this.select_type = vnode.attrs.select_type;
        this.result_type = vnode.attrs.result_type;
        this.select_article = 0;
        this.send_dataLayout = false;

        this.download_link = null;

        if (this.step_type == "view_photo") {
            this.download_link = {
                url: this.articles['articles'][this.select_article].url,
                blobUrl: null,
                loadImage: () => {
                    fetch(this.download_link.url)
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.blob();
                        })
                        .then(blob => {
                            this.download_link.blobUrl = URL.createObjectURL(blob);
                            this.download_link.file = new File([blob], "shared_image.png", { type: blob.type });

                            m.redraw();
                        })
                        .catch(error => {
                            // console.error('There has been a problem with your fetch operation:', error);
                        });
                }
            };
            // 加載圖片
            this.download_link.loadImage();
        }

        if (this.step_type == "view_data") {
            // this.download_link = {
            //     url: this.articles['articles'][this.select_article].url,
            //     blobUrl: null,
            //     loadImage: () => {
            //         fetch(this.download_link.url)
            //             .then(response => {
            //                 if (!response.ok) {
            //                     throw new Error('Network response was not ok');
            //                 }
            //                 return response.blob();
            //             })
            //             .then(blob => {
            //                 this.download_link.blobUrl = URL.createObjectURL(blob);
            //                 this.download_link.file = new File([blob], "shared_image.png", { type: blob.type });

            //                 m.redraw();
            //             })
            //             .catch(error => {
            //                 // console.error('There has been a problem with your fetch operation:', error);
            //             });
            //     }
            // };
            // this.download_link.loadImage();
        }

        //暫時用不到
        this.change_article = false;
    }

    view(vnode) {
        return [
            (this.articles != null) ? [
                m(".row.w-100.m-0[id='focus']", [
                    m(".col-12.pt-3.d-flex.justify-content-between.align-items-center", [
                        (this.result_type == "production") ? [
                            m(".d-inline-block.px-3.py-1", {
                                style: {
                                    color: "#3b5998",
                                    fontSize: "1rem",
                                    borderRadius: "25px",
                                    border: "2px solid #3b5998",
                                    fontWeight: 700,
                                    cursor: "pointer",
                                },
                                onclick: vnode.attrs.goBack
                            }, [
                                m("i.fa-solid.fa-angle-left"),
                                m("span.pl-2", "返回"),
                            ]),
                        ] : "",
                        (this.result_type != "godmode") ?
                            m(".d-inline-block.px-3.py-1.d-md-none", {
                                style: {
                                    color: "#3b5998",
                                    fontSize: "1rem",
                                    borderRadius: "25px",
                                    border: "2px solid #3b5998",
                                    fontWeight: 700,
                                    cursor: "pointer",
                                },
                                onclick: (e) => {
                                    e.preventDefault();
                                    if (this.step_type == "view_photo") {
                                        let shareData = {
                                            // title: "APE AI",
                                            files: [this.download_link.file],
                                        };
                                        navigator.share(shareData);

                                    } else {
                                        let shareData = {
                                            title: "APE AI",
                                            text: this.articles['articles'][this.select_article].message.content,
                                        };
                                        navigator.share(shareData);
                                    }
                                }
                            }, [
                                m("i.fa-solid.fa-arrow-up-from-bracket"),
                                m("span.pl-2", "分享"),
                            ]) : "",
                    ]),
                    m(".w-100.px-2.pt-4", {
                        style: {
                            maxWidth: "8px"
                        }
                    }, [
                        m(".w-100.px-3", {
                            style: {
                                backgroundColor: "#000000",
                                minHeight: "3px"
                            }
                        })
                    ]),
                    (!this.change_article) ? [
                        (this.step_type == "view_article") ?
                            [
                                m(".col-12.pt-2", [

                                    m(".row.w-100.m-0.pt-3.pb-1", [
                                        this.articles['articles'].map((_article, index) => {
                                            return [
                                                m(".col-4.pl-0.pr-2", [
                                                    m(".w-100.px-1.d-flex.justify-content-center.py-1", {
                                                        // class: classNames({
                                                        //     "active": this.select_article == index
                                                        // }),
                                                        style: {
                                                            color: (this.select_article == index) ? "#000000" : "#7C7C7C",
                                                            // borderRadius: "25px",
                                                            border: (this.select_article == index) ? "2px solid #000000" : "2px solid #7C7C7C",
                                                            fontWeight: 700,

                                                        },
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            this.change_article = true
                                                            m.redraw()
                                                            this.select_article = index
                                                            m.redraw()
                                                            this.change_article = false
                                                            m.redraw()

                                                            // const $scroll = $('#focus');

                                                            // if ($scroll.length) {
                                                            //     $('html,body').animate({
                                                            //         scrollTop: $scroll.offset().top
                                                            //     }, 'fast');
                                                            // }
                                                        }
                                                    }, "結果 " + (index + 1))
                                                ]),
                                            ];
                                        }),
                                    ]),
                                ]),
                                m(".col-12.pt-2", [
                                    m("textarea.form-control.w-100", {
                                        style: {
                                            fontSize: "1rem",
                                            fontWeight: 400,
                                            lineHeight: "1.8rem",
                                            minHeight: '30rem'
                                        },
                                        oninput: (e) => {
                                            this.articles['articles'][this.select_article].message.content = e.target.value;
                                            if (!this.send_dataLayout) {
                                                this.send_dataLayout = true;
                                                dataLayer.push({
                                                    'event': 'edit',
                                                    'user_id': User.Account.id,
                                                    'temp_id': this.articles['model']['id'],
                                                    'temp_name': this.articles['model']['name'],
                                                    'gen_free': this.articles['model']['is_free'],
                                                });
                                            }
                                        },
                                    }, m.trust(this.articles['articles'][this.select_article].message.content))
                                ])
                            ] : "",
                        (this.step_type == "view_data") ? [
                            m(".col-12.pt-2.text-center", [

                                m("a.d-inline-block.px-3.py-1", {
                                    href: this.articles.dataInfo,
                                    style: {
                                        color: "#3b5998",
                                        fontSize: "1rem",
                                        borderRadius: "25px",
                                        border: "2px solid #3b5998",
                                        fontWeight: 700,
                                        cursor: "pointer",
                                    },
                                }, [
                                    m("i.fa-solid.fa-clipboard"),
                                    m("span.pl-2", "下載檔案"),
                                ]),
                                m(".d-inline.px-2"),
                            ])

                        ] : "",
                        (this.step_type == "view_photo") ?
                            m(".col-12.pt-2.text-center", [
                                m('img.img-fluid', {
                                    src: this.articles['articles'][this.select_article].url,
                                    style: {
                                        maxHeight: "480px",
                                        // width: "480px",
                                        // height: "480px",
                                    },
                                }),
                            ]) : ""
                    ] : "",
                    (this.result_type != "godmode") ?
                        m(".col-12.pt-3.d-flex.justify-content-md-end.justify-content-center.align-items-center", [
                            (this.step_type == "view_photo") ? [
                                m("a.d-inline-block.px-3.py-1", {
                                    href: this.download_link.url,
                                    download: "image.png",
                                    style: {
                                        color: "#3b5998",
                                        fontSize: "1rem",
                                        borderRadius: "25px",
                                        border: "2px solid #3b5998",
                                        fontWeight: 700,
                                        cursor: "pointer",
                                    },
                                }, [
                                    m("i.fa-solid.fa-clipboard"),
                                    m("span.pl-2", "下載圖片"),
                                ]),
                                m(".d-inline.px-2"),
                            ] : "",

                            (this.result_type != "demo") ?
                                m(".d-inline-block.px-3.py-1", {
                                    style: {
                                        color: "#3b5998",
                                        fontSize: "1rem",
                                        borderRadius: "25px",
                                        border: "2px solid #3b5998",
                                        fontWeight: 700,
                                        cursor: "pointer",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault();
                                        Dialog.show(BoxComponent, {
                                            attrs: {
                                                type: "selectBox",
                                                index: this.select_article,
                                                articleId: this.articles.id
                                            },
                                        });
                                    }
                                }, [
                                    m("i.fa.fa-box"),
                                    m("span.pl-2", "存入盒子"),
                                ]) : "",
                            (this.step_type == "view_photo" || this.result_type == "demo" || this.step_type == "view_data") ? "" : [
                                m(".d-inline.px-2"),
                                m(".d-inline-block.px-3.py-1", {
                                    style: {
                                        color: "#3b5998",
                                        fontSize: "1rem",
                                        borderRadius: "25px",
                                        border: "2px solid #3b5998",
                                        fontWeight: 700,
                                        cursor: "pointer",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault();
                                        User.articleRecordSave(this.articles.id, this.articles['articles']).then((_response) => {
                                            swal.fire({
                                                title: "已儲存",
                                                icon: "success",
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                timer: 400
                                            });
                                        });
                                    }
                                }, [
                                    m("i.fa.fa-hdd-o"),
                                    m("span.pl-2", "覆蓋結果"),
                                ])
                            ],
                            (this.step_type == "view_photo" || this.step_type == "view_data") ? "" : [
                                m(".d-inline.px-2"),
                                m(".d-inline-block.px-3.py-1", {
                                    style: {
                                        color: "#3b5998",
                                        fontSize: "1rem",
                                        borderRadius: "25px",
                                        border: "2px solid #3b5998",
                                        fontWeight: 700,
                                        cursor: "pointer",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault();
                                        navigator.clipboard.writeText(this.articles['articles'][this.select_article].message.content);
                                        swal.fire({
                                            title: "已複製",
                                            icon: "success",
                                            showCancelButton: false,
                                            showConfirmButton: false,
                                            timer: 500
                                        });
                                    }
                                }, [
                                    m("i.fa-solid.fa-clipboard"),
                                    m("span.pl-2", "複製"),
                                ]),
                            ]
                        ]) : "",
                ]),
                (this.result_type == "production") ? [
                    m(".row.w-100.m-0.pt-3.pb-1", [
                        m(".w-100.py-2.d-flex.align-items-center.justify-content-center", {
                            style: {
                                color: "#000000",
                                borderRadius: "25px",
                                border: "2px solid #000000",
                                fontWeight: 700,
                                cursor: "pointer",
                            },
                            onclick: vnode.attrs.regenerate
                        }, [
                            m("i.fa.fa-refresh", {
                                style: {
                                    fontSize: "1.5rem"
                                },
                            }),
                            m("span.pl-3", "重新產生結果")
                        ]),
                    ])
                ] : "",
            ] : "",
        ];
    }
}

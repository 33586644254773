import m from 'mithril'
import classNames from 'classnames';
import {
    User,
    UserFavorite
} from "app/models"


class MenuComponet {
    constructor(vnode) {
        this.data = vnode.attrs.data
        this.select_type = vnode.attrs.select_type
        this.index = vnode.attrs.index
    }
    view({ attrs }) {
        return m(".col-12.col-md-4", {
            onclick: (e) => {
                e.preventDefault()
                e.stopPropagation()
                attrs.onclick()
            },
            
        }, [
            m(".card.mb-3",{
                style: {
                    cursor: "pointer",
                }
            }, [
                m(".card-header.d-flex.align-items-center.justify-content-between", [
                    m("", [
                        m(`i${(this.data.icon == null) ? ".fa.fa-user" : "." + this.data.icon}.py-1`, {
                            style: {
                                fontSize: "1.5rem",

                                color: (this.select_type == this.index) ? "#000000" : "#000000",
                            },

                        }),
                        m("span.pl-4.font-weight-bold", {
                            class: classNames({
                            }),
                            style: {
                                fontSize: (this.data.name.length >= 10) ? "0.8rem" : "1rem",
                                color: (this.select_type == this.index) ? "#000000" : "#000000",
                                fontWeight: (this.select_type == this.index) ? 700 : 400,
                            },

                        }, (this.select_type == this.index) ? "【" + this.data.name + "】" : this.data.name),
                    ]),
                    (User.Account.id != 0) ? [
                        m("d-flex.align-items-center.px-2", [
                            (UserFavorite.datas.includes(this.data.id)) ?
                                m(`i.fa.fa-heart-circle-minus.py-1`, {
                                    style: {
                                        fontSize: "1.5rem",
                                        color: (this.select_type == this.index) ? "#000000" : "#7C7C7C",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        Swal.fire({
                                            title: "是否移除我的最愛？",
                                            showCancelButton: true,
                                            confirmButtonText: "確認",
                                            cancelButtonText: "取消",
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                UserFavorite.remove(this.data.id).then((_res) => {
                                                    UserFavorite.fetch().then(() => {
                                                        m.redraw()
                                                    })
                                                })
                                            }
                                        });
                                    }
                                }) :
                                m(`i.fa.fa-heart-circle-plus.py-1`, {
                                    style: {
                                        fontSize: "1.5rem",
                                        color: (this.select_type == this.index) ? "#000000" : "#7C7C7C",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        Swal.fire({
                                            title: "是否加入我的最愛？",
                                            showCancelButton: true,
                                            confirmButtonText: "確認",
                                            cancelButtonText: "取消",
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                UserFavorite.add(this.data.id).then((_res) => {
                                                    UserFavorite.fetch().then(() => {
                                                        m.redraw()
                                                    })
                                                })
                                            }
                                        });

                                    }
                                })
                        ]),
                    ] : "",
                ]),
                m(".card-body.px-2.py-2", [
                    m("p.card-text.px-2", {
                        class: classNames({
                        }),
                        style: {
                            fontSize: "1rem",
                            color: (this.select_type == this.index) ? "#000000" : "#7C7C7C",
                            fontWeight: (this.select_type == this.index) ? 700 : 400,
                        },

                    }, this.data.subtitle),
                    m(".py-2")
                ]),
                (this.data.is_free) ?
                    m(".position-absolute", {
                        style: {
                            width: "80px",
                            textAlign: "center",
                            color: "#FFFFFF",
                            bottom: "0px",
                            left: "0px",
                            fontSize: "1rem",
                            background: "rgb(38,181,63)",
                            borderRadius: "2px"
                        }
                    }, "Free") : "",
                (this.data.preview) ?
                    m(".position-absolute.px-2", {
                        style: {
                            bottom: "4px",
                            right: "4px",
                            fontWeight: 600,
                            fontSize: "0.9rem",
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            Swal.fire({
                                title: "預覽效果",
                                html: "<div class='text-left border p-3'>" + this.data.preview.replace(/ /g, "&nbsp;").replace(/\n/g, "<br>").replace(/\t/g, "&nbsp;&nbsp;") + "</div>",
                                confirmButtonText: "關閉"
                            });
                        }
                    }, "預覽範例") : "",

            ]),
        ])
    }
}

export default MenuComponet
import m from 'mithril'
import moment from 'moment'
import {
    Dialog,
    TextBox
} from 'components'
import * as Components from 'components'
import Uploader from 'components/uploader/core'
import {
    Member
} from 'app/models'
import classNames from 'classnames';
import {
    Paging,
} from 'components'
import ContentComponent from './dialogs/contentDialog'




export default class MemberPage {
    constructor() {
        window.search_keyword = ""
        Member.pageIndex = 1
        window.member_type = "一般排序"
        Member.getMemberList()
        this.types = ["一般排序", "最新會員", "付費會員", "特約會員", "使用最多", "圖片數最多", "文章數最多"]
    }
    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder", [
                        m("h3.main-title.float-left", `客戶列表`),
                        m("ol.breadcrumb.float-right",
                            [
                                m("li.breadcrumb-item.active", `客戶列表/首頁`)
                            ]
                        ), m(".clearfix")
                    ])
                )
            ),
            m(".row.d-flex.justify-content-center",
                m(".col-md-11",
                    m(".panel-content",
                        m(".card.mb-3",
                            [
                                m(".card-header",
                                    m(".row.d-flex.justify-content-between.align-items-center", [
                                        m(".col-2",
                                            m("h3", [m("i.fa.fa-table.mr-2"), "客戶列表"]),
                                        ),
                                        m(".col-7", [
                                            m(".row.d-flex.justify-content-end.align-items-center", [

                                                this.types.map((_type) => {
                                                    return m(".col.d-flex.justify-content-end.align-items-center.p-1", {
                                                        style: {

                                                        },
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            window.member_type = _type
                                                            Member.pageIndex = 1
                                                            Member.getMemberList()
                                                        }
                                                    }, [
                                                        m(".btn.w-100", {
                                                            style: {
                                                                backgroundColor: (window.member_type == _type) ? "#252525" : "#EAEAEA",
                                                                color: (window.member_type == _type) ? "#FFFFFF" : "#252525",
                                                                borderRadius: "15px",
                                                                fontSize: "1rem"
                                                            }
                                                        }, _type)
                                                    ])
                                                })
                                            ])
                                        ])

                                    ])
                                ),
                                m(".card-header", [
                                    m(".row.w-100.text-light.border-bottom.pb-1", [
                                        m(".col-1",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "ID")
                                        ),
                                        m(".col-1"),
                                        m(".col-2",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "暱稱")
                                        ),
                                        m(".col-1",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "使用點數")
                                        ),
                                        m(".col-1",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "剩餘點數")
                                        ),
                                        m(".col-1",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "文章數")
                                        ),
                                        m(".col-1",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "圖片數")
                                        ),
                                        m(".col-1",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "加入時間")
                                        ),
                                        m(".col-3",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "功能")
                                        ),


                                    ]),

                                    (Member.loading) ?
                                        [
                                            m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                style: {
                                                    fontSize: "85%",
                                                    fontWeight: 700,
                                                    lineHeight: 1,
                                                }
                                            }, [
                                                m('.col-12.text-center.py-2', [
                                                    m('span', {
                                                        style: {
                                                            fontSize: "1.5rem"
                                                        }
                                                    }, "資料讀取中...")
                                                ])
                                            ])

                                        ] : [
                                            (Member.data.length == 0) ?
                                                [
                                                    m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                        style: {
                                                            fontSize: "85%",
                                                            fontWeight: 700,
                                                            lineHeight: 1,
                                                        }
                                                    }, [
                                                        m('.col-12.text-center.py-2', [
                                                            m('span', {
                                                                style: {
                                                                    fontSize: "1.5rem"
                                                                }
                                                            }, "無資料")
                                                        ])
                                                    ])

                                                ] : [

                                                    Member.data.map((_data, index) => {
                                                        return m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                            style: {
                                                                // fontSize: "85%",
                                                                fontWeight: 700,
                                                                lineHeight: 1,
                                                                backgroundColor: (index % 2 == 0) ? "#f2f2f2" : "#FFF"
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                Dialog.show(ContentComponent, {
                                                                    attrs: {
                                                                        member: _data,
                                                                    },
                                                                })
                                                            }
                                                        }, [
                                                            m(".col-1",
                                                                m("span", _data.id)
                                                            ),
                                                            m(".col-1",
                                                                m("div.img-fluid.rounded-circle", {
                                                                    style: {
                                                                        height: "60px",
                                                                        width: "60px",
                                                                        maxHeight: "60px",
                                                                        maxWidth: "60px",
                                                                        backgroundImage: `url("${_data.profile}")`,
                                                                        backgroundRepeat: "no-repeat",
                                                                        backgroundPosition: "center",
                                                                        backgroundSize: "cover",
                                                                        border: "1px solid #000000",
                                                                        cursor: "pointer",
                                                                    },
                                                                })
                                                            ),
                                                            m(".col-2",
                                                                m("span", _data.nickname)
                                                            ),
                                                            m(".col-1",
                                                                m("span", _data.use_tokens.toLocaleString())
                                                            ),
                                                            m(".col-1",
                                                                m("span", _data.remain_tokens.toLocaleString())
                                                            ),
                                                            m(".col-1",
                                                                m("span", _data.articles_count.toLocaleString())
                                                            ),
                                                            m(".col-1",
                                                                m("span", _data.photos_count.toLocaleString())
                                                            ),
                                                            m(".col-1",
                                                                m("span", moment(_data.created_at).format("YYYYMMDD"))
                                                            ),
                                                            m(".col-3",
                                                                m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                    style: {
                                                                        backgroundColor: "#e2f0d9"
                                                                    },
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        e.stopPropagation()
                                                                        Dialog.show(ContentComponent, {
                                                                            attrs: {
                                                                                member: _data,
                                                                            },
                                                                        })
                                                                    }
                                                                },
                                                                    [
                                                                        m("i.fa.fa-eye"),
                                                                        m("span.pl-2", "詳細")
                                                                    ]
                                                                ),

                                                                m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                    style: {
                                                                        backgroundColor: "#FCFCCB"
                                                                    },
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        Swal.fire({
                                                                            title: '請輸入欲增加的點數',
                                                                            input: 'text',
                                                                            inputAttributes: {
                                                                                autocapitalize: 'off'
                                                                            },
                                                                            showCancelButton: true,
                                                                            confirmButtonText: '確認',
                                                                            showLoaderOnConfirm: true,
                                                                            preConfirm: (points) => {
                                                                                Member.addPoints(_data.id, points).then((_response) => {
                                                                                    if (_response.status) {
                                                                                        swal.fire({
                                                                                            title: '已更新',
                                                                                            icon: "success",
                                                                                            buttons: {
                                                                                                cancel: true,
                                                                                                confirm: "確認",
                                                                                            },
                                                                                        }).then((response) => {
                                                                                            _data.remain_tokens += parseInt(points)
                                                                                        })
                                                                                    } else {
                                                                                        swal.fire({
                                                                                            title: _response.message,
                                                                                            icon: "error",
                                                                                            buttons: {
                                                                                                cancel: true,
                                                                                                confirm: "確認",
                                                                                            },
                                                                                        })
                                                                                    }
                                                                                })
                                                                            },
                                                                            allowOutsideClick: () => !Swal.isLoading()
                                                                        })
                                                                    }
                                                                },
                                                                    [
                                                                        m("i.fa-regular.fa-plus"),
                                                                        m("span.pl-2", "加點")
                                                                    ]
                                                                ),

                                                                // m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                //     style: {
                                                                //         backgroundColor: (!_data.is_night_mode) ? "#FCFCCB" : "#C7F7FF",
                                                                //         // color: (!_data.is_night_mode) ? "#FFFFFF" : "#000000",
                                                                //     },
                                                                //     onclick: (e) => {
                                                                //         e.preventDefault()
                                                                //         swal.fire({
                                                                //             title: (_data.is_night_mode) ? "取消精選" : '設為夜盲?',
                                                                //             text: "若設為夜盲此師傅將收不到/看不到媒合單!",
                                                                //             icon: 'warning',
                                                                //             showCancelButton: true,
                                                                //             confirmButtonText: 'Yes!',
                                                                //             cancelButtonText: 'No!',
                                                                //             reverseButtons: true
                                                                //         }).then((result) => {
                                                                //             if (result.isConfirmed) {
                                                                //                 _data.is_night_mode = !_data.is_night_mode
                                                                //                 Member.nightMode(_data.id)

                                                                //             }
                                                                //         })
                                                                //     }
                                                                // },
                                                                //     [
                                                                //         (_data.is_night_mode) ? m("i.fa-regular.fa-eye-slash") : m("i.fa-regular.fa-eye"),
                                                                //         m("span.pl-2", (_data.is_night_mode) ? "取消" : "夜盲")
                                                                //     ]
                                                                // ),

                                                                // m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                //     style: {
                                                                //         backgroundColor: (!_data.not_search) ? "#FCFCCB" : "#C7F7FF",
                                                                //         // color: (!_data.not_search) ? "#FFFFFF" : "#000000",
                                                                //     },
                                                                //     onclick: (e) => {
                                                                //         e.preventDefault()
                                                                //         swal.fire({
                                                                //             title: (_data.not_search) ? "取消搜尋" : '可搜尋?',
                                                                //             text: "若設為可搜尋，將在關鍵字、進階搜尋被搜尋到!",
                                                                //             icon: 'warning',
                                                                //             showCancelButton: true,
                                                                //             confirmButtonText: 'Yes!',
                                                                //             cancelButtonText: 'No!',
                                                                //             reverseButtons: true
                                                                //         }).then((result) => {
                                                                //             if (result.isConfirmed) {
                                                                //                 _data.not_search = !_data.not_search
                                                                //                 Member.searchMode(_data.id)

                                                                //             }
                                                                //         })
                                                                //     }
                                                                // },
                                                                //     [
                                                                //         (_data.not_search) ? m("i.fa-regular.fa-eye-slash") : m("i.fa-regular.fa-eye"),
                                                                //         m("span.pl-2", (_data.not_search) ? "下架" : "上架")
                                                                //     ]
                                                                // ),
                                                            ),

                                                        ])

                                                    }),
                                                ]
                                        ],
                                    m('.row.no-gutters.px-5', [
                                        m('.col-12.pt-3', [
                                            m('.d-flex.flex-sm-row.flex-column.justify-content-md-between', [
                                                m(Paging.Pagination, {
                                                    pageNo: Member.paging.pageNo,
                                                    pageCount: Member.paging.pageCount,
                                                    pageUrl: Member.getMemberList.bind(this)
                                                }),
                                                m(Paging.PageItemCount, {
                                                    pageNo: Member.paging.pageNo,
                                                    pageSize: Member.paging.pageSize,
                                                    total: Member.paging.totalRecordCount
                                                })
                                            ])
                                        ])
                                    ]),
                                ]),
                            ]
                        )
                    )
                )
            ),
        ]
    }
}
import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea
} from 'components'
import * as Components from 'components'
import {
    UserBox,
} from "app/models"
import moment from 'moment'
import classNames from 'classnames';

class SearchBox {
    constructor(vnode) {
        this.loading = true
        this.search_keyword = vnode.attrs.search_keyword
        this.selectItem = vnode.attrs.selectItem
        window.category = "box"
        this.types = [{ name: "我的盒子", key: "box" }, { name: "文章內容", key: "article" },]
        this.results = []
        UserBox.searchBox().then((_response) => {
            this.results = _response
        })
    }
    close(event) {
        setTimeout(() => {
            if (event) {
                if (window.category == "article") {
                    Dialog.close(event)
                } else {
                    Dialog.close({
                        id: event.id,
                        type: 'folder',
                        name: event.title,
                        subtitle: event.subtitle,
                        items: event.articles,
                        is_open: false,
                        children: event.articles
                    })
                }
            } else {
                Dialog.close(false)
            }
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return m('.modal-dialog.modal-dialog-scrollable.modal-lg', [
            m('.modal-content', [
                m('.modal-header', {
                    style: {
                        backgroundColor: "#B6B6B6"
                    }
                }, [
                    m('h5.modal-title', [
                        m('span', this.search_keyword)
                    ]),
                    m('button[type="button"].close', {
                        onclick:(e)=>{
                            Dialog.close(false)
                        } 
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body.px-0", [
                    m(".row.w-100.m-0", [
                        this.types.map((_type) => {
                            return m(".col-2.px-2", [
                                m(".btn.btn-outline-info.p-2.w-100", {
                                    class: classNames({
                                        'active': window.category == _type.key
                                    }),
                                    onclick: (e) => {
                                        this.results = []
                                        window.category = _type.key
                                        UserBox.pageIndex = 1
                                        UserBox.searchBox().then((_response) => {
                                            this.results = _response
                                        })
                                    }
                                }, _type.name)
                            ])
                        }),
                    ]),
                    m(".row.w-100.m-0.pt-2", [
                        this.results.map((_box, index) => {
                            return m(".col-12.py-2.select_hover", {
                                style: {
                                    fontWeight: 700,
                                    lineHeight: 1.5,
                                    backgroundColor: (index % 2 == 0) ? "#f2f2f2" : "#FFF"
                                }
                            }, [
                                (window.category != "box") ? [
                                    m(".", {
                                        onclick: () => {
                                            this.close(_box)
                                        }
                                    }, [
                                        m(".", [
                                            m("i.fa.fa-file.text-dark.pr-2", {
                                                style: {
                                                    fontSize: "1.5rem"
                                                },
                                            }),
                                            m("span", {
                                                style: {
                                                    fontSize: "1.5rem"
                                                },

                                            }, _box.model_name),
                                        ]),
                                        m(".", [
                                            m("p.pb-0", {
                                                style: {
                                                    fontSize: "1rem",
                                                    color: "#7B7B7B",
                                                    fontSize: "0.9rem",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    "-webkit-line-clamp": 2,

                                                },

                                            }, _box.content),
                                        ]),
                                    ])
                                ] : [
                                    m(".select_hover", {
                                        onclick: () => {
                                            this.close(_box)
                                        }
                                    }, [
                                        m(".d-flex.justify-content-between", [
                                            m(".", [
                                                m("i.fa.fa-box.text-warning.pr-2", {
                                                    style: {
                                                        fontSize: "1.25rem"
                                                    },
                                                }),
                                                m("span", {
                                                    style: {
                                                        fontSize: "1.25rem"
                                                    },
                                                }, _box.title),

                                            ]),
                                        ])
                                    ]),
                                ]
                            ])
                        }),
                    ]),
                ]),
                m('.modal-footer', [
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            fontWeight: 700,
                            fontSize: "1.25rem"
                        },
                        onclick: (e) => {
                            Dialog.close(false)
                        } 
                    }, '關閉')
                ]),
            ])
        ])
    }
}

export default SearchBox
import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _datas = []

const constraints = {

}

class Setting extends BaseModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};

    }

    static get datas() {
        return _datas;
    }

    static fetchModels() {
        Setting.loading = true
        return m.request({
            method: 'POST',
            url: `/api/setting/model/fetch`,

        }).then((response) => {
            _datas = response
            Setting.loading = false
            return response
        })
    }

    static getDemoModel() {
        Setting.loading = true
        return m.request({
            method: 'POST',
            url: `/api/setting/model/getDemoModel`,
            data: {
                type: window.demoType
            }

        }).then((response) => {
            _datas = response
            Setting.loading = false
            return response
        })
    }

    static getDocumentExample(url, type) {
        Setting.loading = true
        return m.request({
            method: 'POST',
            url: `/api/setting/model/getDocumentExample`,
            data: {
                url: url,
                type: type
            }

        }).then((response) => {
            _datas = response
            Setting.loading = false
            return response
        })
    }







}

export default Setting
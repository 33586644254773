import m from 'mithril'
import moment from 'moment'
import stream from 'mithril/stream'
import classNames from 'classnames';

import {
    Dialog,
    Paging,
    TextArea,
    Editor,
    GoogleGTM,
    AdminControl,
    GoHeader,
    GoBox,
    ResultBox,
    QuestionBox
} from 'components'
import * as Components from 'components'
import {
    FBPost,
    Setting,
    User,
    Category,
    UserOption,
    UserFavorite,
    UserSpellRecord,
    UserBox
} from 'app/models'

import ArticleRecordComponent from './dialogs/article'
import TokenRecordComponent from './dialogs/record'
import RenewComponent from './dialogs/renew'
import RenewRecordComponent from './dialogs/renewRecord'

import OptionDialog from './dialogs/options'

import MenuComponet from './components/menu'
import HeaderComponet from './components/header'






export default class DashboardPage {
    constructor() {
        //cookie item
        this.cookie_auto_open_menu = localStorage.getItem("menu_auto_open");
        this.cookie_admin_open_menu = localStorage.getItem("admin_menu_open");
        this.cookie_model_select_index = localStorage.getItem("last_model_index");

        this.open_admin_control = stream((this.cookie_admin_open_menu != null && this.cookie_admin_open_menu == "open") ? true : false)
        this.open_tool_menu = stream((this.cookie_auto_open_menu != null && this.cookie_auto_open_menu == "open") ? true : false)
        this.open_user_menu = stream(false)

        this.route_model_id = localStorage.getItem("route_model_id");

        if (this.route_model_id == "") {
            this.route_model_id = null
        }

        this.model = new FBPost()

        this.models = []
        this.else_models = []
        this.types = []

        this.gpt_response = []
        this.article = stream("")


        //整理
        this.step = stream(0)
        this.articles = null


        this.select_type = null
        // this.step = 0
        this._user_article_end = null

        this.temp_function = null

        this.loading = true

        this.questions = []
        this.expend = true
        this.select_question = 0

        this.view_favorite = true
        this.view_else_model = false

        this.select_user_options = []

        this.regen_start = false
        this.record_status = stream(null)


        Category.fetch().then(() => {
            Setting.fetchModels().then((_response) => {
                UserFavorite.fetch().then((_res) => {
                    this.types = _response
                    this.loading = false
                    this.SortByCategory()
                    if (this.route_model_id != null) {
                        let _find_index = _response.map(e => e.id).indexOf(parseInt(this.route_model_id));
                        this.select_type = _find_index
                    } else {
                        if (this.cookie_model_select_index == null) {
                            _res.map((_temp) => {
                                if (this.select_type == null) {
                                    let _find_index = _response.map(e => e.id).indexOf(_temp);
                                    if (_find_index > -1 && _response[_find_index].is_view) {
                                        this.select_type = _find_index
                                    }
                                }
                            })
                            if (this.select_type == null) {
                                _response.map((_temp, _index) => {
                                    if (this.select_type == null && _temp.is_view) {
                                        this.select_type = _index
                                    }
                                })
                            }
                        } else {
                            this.select_type = this.cookie_model_select_index
                        }
                    }
                })

            })
        })
        UserOption.fetchOptions()
        UserBox.getBox()

        this.click = 0
    }
    SortByCategory() {
        this.models = []
        this.else_models = []
        Category.datas.map((_category) => {
            this.models.push({
                categoryId: _category.id,
                categoryName: _category.name,
                view: false,
                datas: []
            })
        })
        // let _temp = {
        //     "功能名字": [],
        //     "功能描述": [],
        //     "連結": [],
        // }
        Setting.datas.map((_model) => {
            let _find_index = this.models.map(e => e.categoryId).indexOf(_model.category_id);
            if (_model.is_view || User.Account.level == 99) {
                if (_find_index > -1) {
                    this.models[_find_index].datas.push(_model)
                } else {
                    this.else_models.push(_model)
                }
            }
            // if (_model['is_view']) {
            //     _temp['功能名字'].push(_model['name'])
            //     _temp['功能描述'].push(_model['subtitle'])
            //     _temp['連結'].push("https://liff.line.me/2004293808-R16vpjpV?id=" + _model['id'])
            // }
        })
        // console.log(_temp)
        m.redraw()
    }

    change_level(level) {
        switch (level) {
            case 1:
                return m(".title-ch", { style: { color: "#000000" } }, "試用會員");
            case 2:
                return m(".title-ch", { style: { color: "#1D91BC" } }, "入門使用");
            case 3:
                return m(".title-ch", { style: { color: "#1D91BC" } }, "進階使用");
            case 4:
                return m(".title-ch", { style: { color: "#FFFB00" } }, "特約會員");
            case 99:
                return m(".title-ch", { style: { color: "#0A1254" } }, "管理員");
        }
    }
    view() {
        return [
            (User.Account.level == 99 && !window.isMobile) ? [
                m(".position-fixed.d-inline-block", {
                    style: {
                        top: "0px",
                        right: "0px",
                        zIndex: 2025,
                    }
                }, [
                    m(".d-inline-block.pr-2", [
                        m(".p-2.d-flex.justify-content-center.align-items-center", {
                            style: {
                                zIndex: 2025,
                                minWidth: "50px",
                                minHeight: "50px",
                            },
                            onclick: (e) => {
                                e.preventDefault()
                                this.click++
                                if (this.click == 5) {
                                    m.route.set('/rebalanceSetting')
                                }
                            }
                        }, [

                        ])
                    ]),
                ])
            ] : [],
            //Googlt GTM
            m(GoogleGTM),
            //Admin Control
            (User.Account.level == 99) ? m(AdminControl, { open_admin_control: this.open_admin_control }) : [],
            //更多工具 & User Setting
            m(HeaderComponet, { open_tool_menu: this.open_tool_menu, open_user_menu: this.open_user_menu }),
            //滑至頁面最上方
            m(GoHeader),
            //滑至頁面最上方
            m(GoBox),

            //Content
            m(".postition-relative", {
                class: classNames({
                    container: !window.isMobile
                }),
                style: {
                    paddingLeft: (!window.isMobile) ? "10%" : "2px",
                    paddingRight: (!window.isMobile) ? "10%" : "2px",
                }
            }, [
                //左上角更多工具
                (this.open_tool_menu()) ? [
                    m(".w-100.m-0.position-relative", [
                        m(".border.position-absolute", {
                            style: {
                                minHeight: "98vh",
                                backgroundColor: "#FAFAFA",
                                zIndex: 2024
                            }
                        }, [
                            m(".col-12.px-0", [
                                m(".row.w-100.m-0", [
                                    //自動開啟
                                    m(".col-md-12.col-12.d-flex.justify-content-end.align-items-center.pt-2.pb-3", [
                                        m(".form-check", [
                                            m("input.form-check-input", {
                                                id: "flexCheckChecked",
                                                type: "checkbox",
                                                checked: this.cookie_auto_open_menu != null && this.cookie_auto_open_menu == "open",
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    if (this.cookie_auto_open_menu != null && this.cookie_auto_open_menu == "open") {
                                                        this.cookie_auto_open_menu = "close"
                                                        localStorage.setItem("menu_auto_open", "close");
                                                    } else {
                                                        this.cookie_auto_open_menu = "open"
                                                        localStorage.setItem("menu_auto_open", "open");
                                                    }
                                                    m.redraw()
                                                }
                                            }),
                                            m("lable.form-check-label[for='flexCheckChecked']", {
                                            }, "自動開啟")
                                        ])
                                    ]),
                                    //常用工具 BAR
                                    m(".col-md-12.col-12.pt-2.pb-0.pl-1", {
                                        onclick: (e) => {
                                            e.preventDefault()
                                            this.view_favorite = !this.view_favorite
                                        }
                                    }, [
                                        m(".btn.py-3.w-100.d-flex.justify-content-start.align-items-center.font-weight-bold", {
                                            style: {
                                                fontSize: "1.25rem",
                                                fontWeight: 500,
                                                borderRadius: "5px",
                                                border: "1px solid #2b3d51",
                                                boxShadow: "4px 3px 2px #B0B0B2",
                                                backgroundColor: (this.view_favorite) ? "#C9C9CF" : "#FFFFFF",
                                                cursor: "pointer",
                                            },
                                        }, m(`i.fa.fa-toolbox.pr-1`), m("span.pl-1.pr-2", "｜"), "常用工具"),
                                    ]),
                                    //常用工具
                                    Setting.datas.map((_data) => {
                                        if (UserFavorite.datas.includes(_data.id) && (User.Account.level == 99 || _data.is_view) && this.view_favorite) {
                                            let index = this.types.map(e => e.id).indexOf(_data.id);
                                            return m(MenuComponet, {
                                                data: _data,
                                                select_type: this.select_type,
                                                index: index,
                                                onclick: () => {
                                                    this.loading = true
                                                    this.model = new FBPost()
                                                    m.redraw()
                                                    this.questions = []
                                                    localStorage.setItem("last_model_index", index);
                                                    this.select_type = index
                                                    this.step(0)
                                                    this.gpt_response = []
                                                    this._user_article_end = null
                                                    this.loading = false
                                                    this.open_tool_menu(false)
                                                    dataLayer.push({
                                                        'event': 'switch_temp',
                                                        'user_id': User.Account.id,
                                                        'temp_id': this.types[this.select_type].id,
                                                        'temp_name': this.types[this.select_type].name,
                                                        'gen_free': this.types[this.select_type].is_free,
                                                    });
                                                }
                                            })
                                        }
                                    }),
                                    //所有工具
                                    this.models.map((_type) => {
                                        return [
                                            m(".col-md-12.col-12.pt-2.pb-0.pl-1", {
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    _type.view = !_type.view
                                                }
                                            }, [
                                                m(".btn.w-100.d-flex.justify-content-start.align-items-center.font-weight-bold.py-3", {
                                                    style: {
                                                        fontSize: "1.25rem",
                                                        fontWeight: 500,
                                                        borderRadius: "5px",
                                                        border: "1px solid #2b3d51",
                                                        boxShadow: "4px 3px 2px #B0B0B2",
                                                        backgroundColor: (_type.view) ? "#C9C9CF" : "#FFFFFF"
                                                    },
                                                }, m(`i${(_type.datas.length <= 0 || _type.datas[0].icon == null) ? ".fa.fa-user" : "." + _type.datas[0].icon}.pr-1`), m("span.pl-1.pr-2", "｜"), _type.categoryName),
                                            ]),
                                            _type.datas.map((_data) => {
                                                let index = this.types.map(e => e.id).indexOf(_data.id);
                                                if (_type.view) {
                                                    return m(MenuComponet, {
                                                        data: _data,
                                                        select_type: this.select_type,
                                                        index: index,
                                                        onclick: () => {
                                                            this.loading = true
                                                            this.model = new FBPost()
                                                            m.redraw()
                                                            this.questions = []
                                                            localStorage.setItem("last_model_index", index);
                                                            this.select_type = index
                                                            this.step(0)
                                                            this.gpt_response = []
                                                            this._user_article_end = null
                                                            this.loading = false
                                                            this.open_tool_menu(false)
                                                            dataLayer.push({
                                                                'event': 'switch_temp',
                                                                'user_id': User.Account.id,
                                                                'temp_id': this.types[this.select_type].id,
                                                                'temp_name': this.types[this.select_type].name,
                                                                'gen_free': this.types[this.select_type].is_free,
                                                            });
                                                        }
                                                    })
                                                }
                                            }),
                                        ]
                                    }),
                                    //其他工具 BAR
                                    m(".col-md-12.col-12.pt-2.pb-0.pl-1", {
                                        onclick: (e) => {
                                            e.preventDefault()
                                            this.view_else_model = !this.view_else_model
                                        }
                                    }, [
                                        m(".btn.py-3.w-100.d-flex.justify-content-start.align-items-center.font-weight-bold", {
                                            style: {
                                                fontSize: "1.25rem",
                                                fontWeight: 500,
                                                borderRadius: "5px",
                                                border: "1px solid #2b3d51",
                                                boxShadow: "4px 3px 2px #B0B0B2",
                                                backgroundColor: (this.view_favorite) ? "#C9C9CF" : "#FFFFFF"
                                            },
                                        }, m(`i.fa.fa-box-open.pr-1`), m("span.pl-1.pr-2", "｜"), "其他"),
                                    ]),
                                    //其他工具
                                    this.else_models.map((_data) => {
                                        let index = this.types.map(e => e.id).indexOf(_data.id);
                                        if (this.view_else_model) {
                                            return m(MenuComponet, {
                                                data: _data,
                                                select_type: this.select_type,
                                                index: index,
                                                onclick: () => {
                                                    this.loading = true
                                                    this.model = new FBPost()
                                                    m.redraw()
                                                    this.questions = []
                                                    localStorage.setItem("last_model_index", index);
                                                    this.select_type = index
                                                    this.step(0)
                                                    this.gpt_response = []
                                                    this._user_article_end = null
                                                    this.loading = false
                                                    this.open_tool_menu(false)
                                                    dataLayer.push({
                                                        'event': 'switch_temp',
                                                        'user_id': User.Account.id,
                                                        'temp_id': this.types[this.select_type].id,
                                                        'temp_name': this.types[this.select_type].name,
                                                        'gen_free': this.types[this.select_type].is_free,
                                                    });
                                                }
                                            })
                                        }
                                    }),
                                    //空白
                                    m(".col-12.py-2", {
                                        style: {
                                            minHeight: "100px"
                                        }
                                    }),
                                ])
                            ]),
                            //關閉按鈕
                            m(".position-fixed", {
                                style: {
                                    top: "15px",
                                    zIndex: 2025,
                                    cursor: "pointer",
                                }
                            }, [
                                m(".d-inline-block.pl-3", [
                                    m(".d-flex.justify-content-center.align-items-center", {
                                        style: {
                                            top: "15px",
                                            left: (!window.isMobile) ? "10.2%" : "2px",
                                            zIndex: 2025,
                                            minWidth: "50px",
                                            minHeight: "50px",
                                            "background-color": "rgba(0, 0, 0, 0.4)",
                                            "border-radius": "25px"
                                        },
                                        onclick: (e) => {
                                            e.preventDefault()
                                            this.open_tool_menu(!this.open_tool_menu())
                                        }
                                    }, [
                                        m("i.fa.fa-times", {
                                            style: {
                                                fontSize: "2rem",
                                                color: "#FFFFFF"
                                            }
                                        })
                                    ]),
                                ])
                            ]),
                        ]),
                    ])
                ] : "",
                //右上角使用者內容
                (this.open_user_menu()) ? [
                    m(".w-100.m-0.position-relative", [
                        m(".border.position-absolute", {
                            style: {
                                height: "98vh",
                                backgroundColor: "#FAFAFA",
                                zIndex: 1024,
                            }
                        }, [
                            m(".col-12.px-0", [
                                m(".row.w-100.m-0", [
                                    //開關
                                    m(".col-12.d-flex.justify-content-end.align-items-center.pt-2.pb-3", [
                                        m("i.fa.fa-close", {
                                            style: {
                                                fontSize: "1.75rem"
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()
                                                this.open_user_menu(!this.open_user_menu())
                                                m.redraw()
                                            }
                                        }),
                                    ]),
                                    //選單
                                    m(".row.w-100.m-0.position-relative", [
                                        //等級
                                        m(".position-absolute.ribbon-ch", {
                                            style: {
                                                top: "-40px",
                                                left: "0px"
                                            }
                                        }, [
                                            this.change_level(User.Account.level),
                                        ]),
                                        //頭像
                                        m(".col-12.py-3", [
                                            m(".d-flex.justify-content-center.flex-column.align-items-center", [
                                                (User.Account.profile) ? [
                                                    m("div.img-fluid.rounded-circle", {
                                                        style: {
                                                            height: "90px",
                                                            width: "90px",
                                                            maxHeight: "90px",
                                                            maxWidth: "90px",
                                                            "background-image": `url("${User.Account.profile}")`,
                                                            "background-repeat": "no-repeat",
                                                            "background-position": "center",
                                                            "background-size": "cover"
                                                        },
                                                    }),
                                                ] : [
                                                    m("i.fa.fa-user", {
                                                        style: {
                                                            fontSize: "5rem"
                                                        },

                                                    })
                                                ],
                                                m("span.d-flex.justify-content-center.pt-2", { style: { fontSize: "1.2rem", color: "#000000", fontWeight: 600 } }, User.Account.nickname)
                                            ]),
                                        ]),
                                        //Token
                                        m(".col-12.py-2.d-flex.justify-content-center.align-items-center", [
                                            m("span", { style: { fontSize: "1.4rem", color: "dark", fontWeight: 600 } }, "剩餘Token數：" + User.Account.remain_tokens.toLocaleString()),
                                        ]),
                                        //選項
                                        m(".col-12.py-3", [
                                            m(".row.w-100.m-0.py-2", [
                                                m(".col-12", { style: { minHeight: "1px", backgroundColor: "#B6B6B6" } }),
                                                m(".col-12.py-2.d-flex.justify-content-between.align-items-center", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        this.open_user_menu(!this.open_user_menu())
                                                        Dialog.show(OptionDialog, {
                                                            didHide: (returnValue) => {
                                                                if (returnValue) {
                                                                    UserOption.fetchOptions()
                                                                    m.redraw()
                                                                }
                                                            }
                                                        })
                                                    }
                                                }, [
                                                    m("span", { style: { fontSize: "1.1rem", color: "dark", fontWeight: 600 } }, "預設選項"),
                                                    m("span", { style: { fontSize: "1.1rem", color: "grey", fontWeight: 600 } }, m("i.fa-solid.fa-chevron-right")),
                                                ]),
                                                m(".col-12", { style: { minHeight: "1px", backgroundColor: "#B6B6B6" } }),
                                                m(".col-12.py-2.d-flex.justify-content-between.align-items-center", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        this.open_user_menu(!this.open_user_menu())
                                                        Dialog.show(ArticleRecordComponent)
                                                    }
                                                }, [
                                                    m("span", { style: { fontSize: "1.1rem", color: "dark", fontWeight: 600 } }, "歷史紀錄"),
                                                    m("span", { style: { fontSize: "1.1rem", color: "grey", fontWeight: 600 } }, m("i.fa-solid.fa-chevron-right")),
                                                ]),
                                                m(".col-12", { style: { minHeight: "1px", backgroundColor: "#B6B6B6" } }),

                                                m(".col-12.py-2.d-flex.justify-content-between.align-items-center", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        m.route.set('/boxSystem')
                                                    }
                                                }, [
                                                    m("span", { style: { fontSize: "1.1rem", color: "dark", fontWeight: 600 } }, m("i.fa.fa-archive.pr-2"), "我的盒子"),
                                                    m("span", { style: { fontSize: "1.1rem", color: "grey", fontWeight: 600 } }, m("i.fa-solid.fa-chevron-right")),
                                                ]),
                                                m(".col-12", { style: { minHeight: "1px", backgroundColor: "#B6B6B6" } }),
                                                m(".col-12.py-2.d-flex.justify-content-between.align-items-center.pt-5", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        this.open_user_menu(!this.open_user_menu())
                                                        Dialog.show(RenewComponent)
                                                    }
                                                }, [
                                                    m("span", { style: { fontSize: "1.1rem", color: "dark", fontWeight: 600 } }, m("i.fa-solid.fa-cart-shopping.pr-2"), "購買Token"),
                                                    m("span", { style: { fontSize: "1.1rem", color: "grey", fontWeight: 600 } }, m("i.fa-solid.fa-chevron-right")),
                                                ]),
                                                m(".col-12", { style: { minHeight: "1px", backgroundColor: "#B6B6B6" } }),

                                                m(".col-12.py-2.d-flex.justify-content-between.align-items-center", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        this.open_user_menu(!this.open_user_menu())
                                                        Dialog.show(RenewRecordComponent)
                                                    }
                                                }, [
                                                    m("span", { style: { fontSize: "1.1rem", color: "dark", fontWeight: 600 } }, m("i.fa-solid.fa-gear.pr-2"), "消費紀錄"),
                                                    m("span", { style: { fontSize: "1.1rem", color: "grey", fontWeight: 600 } }, m("i.fa-solid.fa-chevron-right")),
                                                ]),
                                                m(".col-12", { style: { minHeight: "1px", backgroundColor: "#B6B6B6" } }),

                                                m(".col-12.py-2.d-flex.justify-content-between.align-items-center", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        this.open_user_menu(!this.open_user_menu())
                                                        Dialog.show(TokenRecordComponent)
                                                    }
                                                }, [
                                                    m("span", { style: { fontSize: "1.1rem", color: "dark", fontWeight: 600 } }, m("i.fa-solid.fa-gear.pr-2"), "Token使用紀錄"),
                                                    m("span", { style: { fontSize: "1.1rem", color: "grey", fontWeight: 600 } }, m("i.fa-solid.fa-chevron-right")),
                                                ]),

                                                m(".col-12", { style: { minHeight: "1px", backgroundColor: "#B6B6B6" } }),
                                                m(".col-12.py-2.d-flex.justify-content-between.align-items-center.pt-5", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        window.location.href = `https://forms.gle/JnC8pwNT5PnynJSM7`
                                                    }
                                                }, [
                                                    m("span", { style: { fontSize: "1.1rem", color: "dark", fontWeight: 600 } }, m("i.fa-solid.fa-star.pr-2"), "許願池"),
                                                    m("span", { style: { fontSize: "1.1rem", color: "grey", fontWeight: 600 } }, m("i.fa-solid.fa-chevron-right")),
                                                ]),
                                                m(".col-12", { style: { minHeight: "1px", backgroundColor: "#B6B6B6" } }),
                                                (User.Account.level == 99) ? [
                                                    m(".col-12.pt-5.d-flex.justify-content-between.align-items-center", {
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            this.open_user_menu(false)
                                                            this.open_admin_control(true)
                                                            localStorage.setItem("admin_menu_open", "open");
                                                        }
                                                    }, [
                                                        m("span", { style: { fontSize: "1.1rem", color: "dark", fontWeight: 600 } }, m("i.fa.fa-cog.pr-2"), "管理員選單"),
                                                        m("span", { style: { fontSize: "1.1rem", color: "grey", fontWeight: 600 } }, m("i.fa-solid.fa-chevron-right")),
                                                    ]),
                                                    m(".col-12", { style: { minHeight: "1px", backgroundColor: "#B6B6B6" } })]
                                                    : "",
                                                m(".col-12.py-2.d-flex.justify-content-between.align-items-center", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        liff.logout()
                                                        liff.closeWindow()
                                                        window.location.href = window.ROUTE_URL
                                                    }
                                                }, [
                                                    m("span", { style: { fontSize: "1.1rem", color: "dark", fontWeight: 600 } }, m("i.fa-solid.fa-star.pr-2"), "登出"),
                                                    m("span", { style: { fontSize: "1.1rem", color: "grey", fontWeight: 600 } }, m("i.fa-solid.fa-chevron-right")),
                                                ]),
                                                m(".col-12", { style: { minHeight: "1px", backgroundColor: "#B6B6B6" } }),
                                            ])
                                        ]),
                                    ]),
                                ])
                            ]),
                        ])
                    ])
                ] : "",
                //變數內容
                (this.open_tool_menu() == false && this.open_user_menu() == false) ?
                    m(".row.w-100.m-0", [
                        m(".col-12.py-4"),
                        //MENU 我的最愛
                        m(".col-12.pb-1.pt-4", [
                            m(".w-100", {
                                style: {
                                    "overflow-x": "auto",
                                    "white-space": "nowrap",
                                    "webkit-overflow-scrolling": "touch",
                                    "-ms-overflow-style": "none",
                                    "scrollbar-width": (!window.isMobile) ? "auto" : "none",
                                    "-ms-overflow-style": "-ms-autohiding-scrollbar",
                                }
                            }, [
                                (UserFavorite.datas.length > 0) ? [
                                    Setting.datas.map((_data) => {
                                        if (UserFavorite.datas.includes(_data.id) && (User.Account.level == 99 || _data.is_view)) {
                                            let index = this.types.map(e => e.id).indexOf(_data.id);
                                            return m(".d-inline-block.px-2", {
                                                style:{
                                                    cursor: "pointer",
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    this.loading = true
                                                    this.model = new FBPost()
                                                    m.redraw()
                                                    this.questions = []
                                                    this.select_type = index
                                                    localStorage.setItem("last_model_index", index);
                                                    this.step(0)
                                                    this.gpt_response = []
                                                    this._user_article_end = null
                                                    this.loading = false
                                                    dataLayer.push({
                                                        'event': 'switch_temp',
                                                        'user_id': User.Account.id,
                                                        'temp_id': this.types[this.select_type].id,
                                                        'temp_name': this.types[this.select_type].name,
                                                        'gen_free': this.types[this.select_type].is_free,
                                                    });
                                                    m.redraw()
                                                },

                                            }, [
                                                m(`i${(_data.icon == null) ? ".fa.fa-user" : "." + _data.icon}.text-center.w-100.py-1`, {
                                                    style: {
                                                        fontSize: "2rem",
                                                        color: (this.select_type == index) ? "#000000" : "#7C7C7C",
                                                    },
                                                }),
                                                m(".pb-1.text-center", {
                                                    style: {
                                                        fontSize: "1rem",
                                                        minWidth: "80px",
                                                        color: (this.select_type == index) ? "#000000" : "#7C7C7C",
                                                        fontWeight: (this.select_type == index) ? 700 : 400,
                                                    },

                                                }, (this.select_type == index) ? "【" + _data.name + "】" : _data.name),
                                                (this.select_type == index) ?
                                                    m(".w-100.px-3", [
                                                        m(".w-100.px-1", {
                                                            style: {
                                                                backgroundColor: "#000000",
                                                                minHeight: "2px"
                                                            }
                                                        },)
                                                    ]) : ""
                                            ])
                                        }
                                    }),
                                ] : [],
                            ])
                        ]),
                        //Content
                        (this.loading == false && this.select_type != null) ? [
                            //標題
                            m(".col-12.pb-0.pt-4.position-relative", [
                                m("h4.text-center", {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.loading = true
                                        m.redraw()
                                        this.questions = []
                                        this.gpt_response = []
                                        this.step(0)
                                        this._user_article_end = null
                                        this.loading = false
                                        m.redraw()
                                    }
                                },
                                    this.types[this.select_type].name
                                ),
                                (this.types[this.select_type].subtitle != "" && this.types[this.select_type].subtitle != null) ? [
                                    m("h5.text-center.py-3", {
                                        style: {
                                            fontSize: "0.9rem",
                                            color: "#7B7B7B"
                                        }
                                    },
                                        m.trust(this.types[this.select_type].subtitle.replace(/ /g, "&nbsp;").replace(/\n/g, "<br>").replace(/\t/g, "&nbsp;&nbsp;"))
                                    )] : "",
                                m("span.px-2.position-absolute", {
                                    style: {
                                        top: "10px",
                                        left: "10px",
                                        fontWeight: 600,
                                        fontSize: "0.9rem",
                                        color: "#B6B6B6",
                                        borderRadius: "25px",
                                        border: "2px solid #B6B6B6",
                                    },
                                },
                                    "熱門度：" + this.types[this.select_type].used_times.toLocaleString()
                                ),
                                m("span.px-2.position-absolute", {
                                    style: {
                                        top: "10px",
                                        right: "70px",
                                        fontWeight: 600,
                                        fontSize: "0.9rem",
                                        color: "#B6B6B6",
                                        borderRadius: "25px",
                                        border: "2px solid #B6B6B6",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        let shareData = {
                                            title: "APE GPT" + this.types[this.select_type].name,
                                            text: "快和朋友一起來玩玩看吧！",
                                            url: "https://liff.line.me/2004293808-R16vpjpV?id=" + this.types[this.select_type].id,
                                        }
                                        navigator.share(shareData)
                                    }
                                }, m("i.fa-solid.fa-arrow-up-from-bracket.py-1", {
                                    style: {
                                        fontSize: "1.5rem",
                                        color: "#000000",
                                    },
                                })),
                                m("span.px-2.position-absolute", {
                                    style: {
                                        top: "10px",
                                        right: "10px",
                                        fontWeight: 600,
                                        fontSize: "0.9rem",
                                        color: "#B6B6B6",
                                        borderRadius: "25px",
                                        border: "2px solid #B6B6B6",
                                    },
                                },
                                    (UserFavorite.datas.includes(this.types[this.select_type].id)) ?
                                        m(`i.fa.fa-heart-circle-minus.py-1`, {
                                            style: {
                                                fontSize: "1.5rem",
                                                color: "#000000",
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                Swal.fire({
                                                    title: "是否移除我的最愛？",
                                                    showCancelButton: true,
                                                    confirmButtonText: "確認",
                                                    cancelButtonText: "取消",
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        UserFavorite.remove(this.types[this.select_type].id).then((_res) => {
                                                            UserFavorite.fetch().then(() => {
                                                                m.redraw()
                                                            })
                                                        })
                                                    }
                                                });
                                            }
                                        }) :
                                        m(`i.fa.fa-heart-circle-plus.py-1`, {
                                            style: {
                                                fontSize: "1.5rem",
                                                color: "#7C7C7C",
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                Swal.fire({
                                                    title: "是否加入我的最愛？",
                                                    showCancelButton: true,
                                                    confirmButtonText: "確認",
                                                    cancelButtonText: "取消",
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        UserFavorite.add(this.types[this.select_type].id).then((_res) => {
                                                            UserFavorite.fetch().then(() => {
                                                                m.redraw()
                                                            })
                                                        })
                                                    }
                                                });

                                            }
                                        })
                                )
                            ]),
                            //查看效果
                            m(".col-6.pb-0.pt-1.pb-3.text-left", [
                                m("span.px-2", {
                                    style: {
                                        fontWeight: 600,
                                        fontSize: "0.9rem",
                                        color: "#B6B6B6",
                                        borderRadius: "25px",
                                        border: "2px solid #B6B6B6",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        Swal.fire({
                                            title: "預覽效果",
                                            html: "<div class='text-left'>" + ((this.types[this.select_type].preview != null) ? this.types[this.select_type].preview.replace(/ /g, "&nbsp;").replace(/\n/g, "<br>").replace(/\t/g, "&nbsp;&nbsp;") : "") + "</div>",
                                            confirmButtonText: "關閉"
                                        });
                                    }
                                },
                                    "範例效果"
                                )
                            ]),
                            //步驟開始
                            (this.step() == 0) ? [
                                //載入紀錄
                                m(".col-6.pb-0.pt-1.pb-3.text-right", [
                                    m("span.px-2", {
                                        style: {
                                            fontWeight: 600,
                                            fontSize: "0.9rem",
                                            color: "#B6B6B6",
                                            borderRadius: "25px",
                                            border: "2px solid #B6B6B6",
                                        },
                                        onclick: (e) => {
                                            e.preventDefault()
                                            User.getLastRecord(this.types[this.select_type].id).then((_res) => {
                                                dataLayer.push({
                                                    'event': 'input_history',
                                                    'user_id': User.Account.id,
                                                    'temp_id': this.types[this.select_type].id,
                                                    'temp_name': this.types[this.select_type].name,
                                                    'gen_free': this.types[this.select_type].is_free,
                                                    'has_history': (_res) ? true : false
                                                })
                                                if (_res) {
                                                    this.types[this.select_type].steps[this.step()].s.map((_temp) => {
                                                        if (_res.model.hasOwnProperty(_temp['variable'])) {
                                                            this.model[_temp['variable']] = _res.model[_temp['variable']]
                                                            this.model[_temp['question']] = _res.model[_temp['question']]
                                                        }
                                                    })
                                                } else {
                                                    swal.fire({
                                                        title: "查無紀錄",
                                                        icon: "error",
                                                        showCancelButton: false,
                                                        showConfirmButton: false,
                                                        timer: 800
                                                    });
                                                }
                                            })
                                        }
                                    },
                                        "載入紀錄"
                                    )
                                ])
                            ] : "",
                            //使用者選單
                            (this.types[this.select_type].steps[this.step()].x == "view") ? [
                                this.types[this.select_type].steps[this.step()].s.map((_question) => {
                                    return m(QuestionBox, {
                                        question: _question,
                                        model: this.model,
                                        question_type: "production",
                                        record_status: this.record_status,
                                    });
                                }),
                                //圖片產出比例
                                this.types[this.select_type].steps.map((_step) => {
                                    if (_step.x == "BlackForestLab") {
                                        return [
                                            m(".col-12.px-0.pt-2", [
                                                m("label.text-dark.mb-1.pl-2", {
                                                    style: {
                                                        "font-weight": 700
                                                    }
                                                }, "圖片產出比例"),
                                            ]),
                                            m('.col-12.pb-3', [
                                                m(".form-group.m-0", [
                                                    m("select.form-control", {
                                                        onchange: (e) => {
                                                            this.model['BlackForestLab_Aspect_ratios'] = e.target.value;
                                                        },
                                                        oncreate: (e) => {
                                                            this.model['BlackForestLab_Aspect_ratios'] = _step.o.aspect_ratio;
                                                            m.redraw();
                                                        }
                                                    }, [
                                                        m("option[disabled]", {
                                                            value: null,
                                                        }, "請選擇圖片比例"),
                                                        ['1:1', '16:9', '2:3', '3:2', '4:5', '5:4', '9:16'].map((_item) => {
                                                            return m("option", {
                                                                value: _item,
                                                                selected: this.model['BlackForestLab_Aspect_ratios'] == _item
                                                            }, _item);
                                                        })
                                                    ])
                                                ])
                                            ])
                                        ]
                                    }
                                }),
                                //個人預設訊息
                                (UserOption.datas.length > 0) ? [
                                    m(".row.w-100.m-0.pt-3", [
                                        m(".col-12", [
                                            m("label.text-dark.mb-1", {
                                                style: {
                                                    "font-weight": 700
                                                }
                                            }, "帶入個人資訊："),
                                        ]),
                                        UserOption.datas.map((_options, index) => {
                                            return m(".col-12.py-2", [
                                                m(".input-group", [
                                                    m(".input-group-text", {
                                                        style: {
                                                            backgroundColor: "#FFFFFF"
                                                        }
                                                    }, [
                                                        m("input.mt-0", {
                                                            type: "checkbox",
                                                            value: _options.id,
                                                            checked: (this.select_user_options.includes(_options.id.toString())) ? true : false,
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                if (this.select_user_options.includes(_options.id.toString())) {
                                                                    this.select_user_options.splice(this.select_user_options.indexOf(_options.id.toString()), 1)
                                                                } else {
                                                                    this.select_user_options.push(e.target.value)
                                                                }
                                                                m.redraw()
                                                            }
                                                        })
                                                    ]),
                                                    m("input.form-control", {
                                                        type: 'text',
                                                        value: _options.title,
                                                        disable: true,
                                                        readonly: true,
                                                        style: {
                                                            backgroundColor: "#FFFFFF"
                                                        }

                                                    })
                                                ]),
                                            ])
                                        })
                                    ])
                                ] : "",
                            ] : "",
                            //GPT 選單
                            (this.types[this.select_type].steps[this.step()].x == "view_suggest" || this.step() >= 1) ? [
                                (this.gpt_response && this.gpt_response.length > 0) ?
                                    m(".row.w-100.m-0[id='focus_suggest']", [
                                        this.gpt_response.map((_question, index) => {
                                            return m(".col-12", [
                                                m(".row.w-100.m-0.pb-3", [
                                                    m(".col-12.pb-1.d-flex.justify-content-between",
                                                        m("span", {
                                                            style: {
                                                                fontWeight: 600,
                                                                fontSize: "1.2rem"
                                                            }
                                                        }, index + 1 + ". " + _question.key),
                                                        //Regenerate
                                                        m("i.fa.fa-refresh", {
                                                            style: {
                                                                fontSize: "1.75rem"
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                this.select_question = index
                                                                FBPost.sendToGPTForVariable(this.model, this.questions, this.types[this.select_type].id, _question.step, this.gpt_response, this.select_user_options).then((_response) => {
                                                                    if (_response.hasOwnProperty('status') && !_response.status) {
                                                                        swal.fire({
                                                                            title: _response.message,
                                                                            // text: "請確認產品或服務名字!",
                                                                            icon: "error",
                                                                            showCancelButton: true,
                                                                            showConfirmButton: false,
                                                                            timer: 5000
                                                                        });
                                                                        return
                                                                    }
                                                                    JSON.parse(_response[0].choices[0].message.content).options.map((_question, index) => {
                                                                        Object.keys(_question).map((_anser) => {
                                                                            let _find_index = this.gpt_response.map(e => e.key).indexOf(_anser);
                                                                            if (_find_index > -1 && this.select_question == _find_index) {
                                                                                this.gpt_response[_find_index].options = _question[_anser]
                                                                                this.gpt_response[_find_index].answer = []
                                                                            }
                                                                        })
                                                                    })
                                                                    if (User.Account.level == 99) {
                                                                        UserSpellRecord.getSpellRecord()
                                                                    }
                                                                    m.redraw()
                                                                })
                                                            }
                                                        }),
                                                    ),
                                                    //建議選項
                                                    _question.options.map((_option) => {
                                                        return m(".col-12.p-1",
                                                            m(".w-100.text-center.py-2.px-2", {
                                                                style: {
                                                                    color: (_question.answer.includes(_option)) ? "#000000" : "rgb(109 109 109)",
                                                                    "white-space": "normal",
                                                                    border: "1px solid #ced4da",
                                                                    fontWeight: (_question.answer.includes(_option)) ? 700 : 400,
                                                                    backgroundColor: (_question.answer.includes(_option)) ? "#ced4da" : "#FFFFFF",
                                                                    borderRadius: "5px"
                                                                },
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    if (_question.answer.includes(_option)) {
                                                                        _question.answer.splice(_question.answer.indexOf(_option), 1)
                                                                    } else {
                                                                        _question.answer.push(_option)
                                                                    }
                                                                    m.redraw()
                                                                }
                                                            }, m.trust(_option.replace(/ /g, "&nbsp;").replace(/\n/g, "<br>").replace(/\t/g, "&nbsp;&nbsp;")))
                                                        )
                                                    }),
                                                    //原本寫的答案
                                                    (this.model.hasOwnProperty(_question.key)) ? [
                                                        m(".col-12.p-1",
                                                            m(".w-100.text-center.py-2.px-2", {
                                                                style: {
                                                                    "white-space": "normal",
                                                                    color: (_question.answer.includes(this.model[this.model[_question.key]])) ? "#000000" : "rgb(109 109 109)",
                                                                    "white-space": "normal",
                                                                    border: "1px solid #ced4da",
                                                                    fontWeight: (_question.answer.includes(this.model[this.model[_question.key]])) ? 700 : 400,
                                                                    backgroundColor: (_question.answer.includes(this.model[this.model[_question.key]])) ? "#ced4da" : "#FFFFFF",
                                                                    borderRadius: "5px"
                                                                },
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    if (_question.answer.includes(this.model[this.model[_question.key]])) {
                                                                        _question.answer.splice(_question.answer.indexOf(this.model[this.model[_question.key]]), 1)
                                                                    } else {
                                                                        _question.answer.push(this.model[this.model[_question.key]])
                                                                    }
                                                                }
                                                            }, this.model[this.model[_question.key]]
                                                            )
                                                        )
                                                    ] : ""
                                                ]),
                                            ])
                                        })
                                    ]) : "",
                            ] : "",
                            //結果查看
                            ((this.types[this.select_type].steps[this.step()].x == "view_article" || this.types[this.select_type].steps[this.step()].x == "view_data" ||this.types[this.select_type].steps[this.step()].x == "view_photo") && this.regen_start == false) ? [
                                m(ResultBox, {
                                    articles: this.articles,
                                    step_type: this.types[this.select_type].steps[this.step()].x,

                                    result_type: "production",
                                    goBack: (e) => {
                                        e.preventDefault()
                                        this.articles = null
                                        this.questions = []
                                        this.step(0)
                                        m.redraw()
                                    },
                                    regenerate: (e) => {
                                        e.preventDefault()
                                        this.regen_start = true
                                        let _temp_step = 2
                                        if (this.types[this.select_type].steps[this.step() - 2]['x'] == 'dgpt') {
                                            _temp_step = 3
                                        }
                                        FBPost.sendToGPTForVariable(this.model, this.questions, this.types[this.select_type].id, this.step() - _temp_step, this.gpt_response, this.select_user_options).then((_response) => {
                                            if (this.step_type == "view_article" || this.step_type == "view_photo") {
                                                dataLayer.push({
                                                    'event': 'regen',
                                                    'user_id': User.Account.id,
                                                    'temp_id': this.types[this.select_type].id,
                                                    'temp_name': this.types[this.select_type].name,
                                                    'gen_free': this.types[this.select_type].is_free,
                                                    'output_format': (this.step_type == "view_article") ? "text" : "pic",
                                                    'custom_setting': (this.select_user_options.length > 0) ? true : false,
                                                    'token': (_response.hasOwnProperty('status') && !_response.status) ? 0 : _response[5],
                                                    'status': (_response.hasOwnProperty('status') && !_response.status) ? false : true,
                                                    'error_message': (_response.hasOwnProperty('status') && !_response.status) ? _response.message : null,
                                                });
                                            }

                                            if (_response.hasOwnProperty('status') && !_response.status) {
                                                swal.fire({
                                                    title: _response.message,
                                                    // text: "請確認產品或服務名字!",
                                                    icon: "error",
                                                    showCancelButton: false,
                                                    showConfirmButton: false,
                                                    timer: 5800
                                                });
                                                return
                                            }
                                            if (this.types[this.select_type].steps[this.step()].x == "view_suggest") {
                                                JSON.parse(_response[0].choices[0].message.content).options.map((_question, index) => {
                                                    Object.keys(_question).map((_anser) => {
                                                        this.gpt_response.push({
                                                            key: _anser,
                                                            options: _question[_anser],
                                                            answer: [],
                                                            step: this.step() - 2
                                                        })
                                                    })
                                                })
                                                m.redraw()
                                                const $scroll = $('#focus_suggest');

                                                if ($scroll.length) {
                                                    $('html,body').animate({
                                                        scrollTop: $scroll.offset().top - 150
                                                    }, 'fast');
                                                }
                                            } else if (this.types[this.select_type].steps[this.step()].x == "view_article" || this.types[this.select_type].steps[this.step()].x == "view_photo") {
                                                this.articles = null
                                                m.redraw()
                                                this.articles = _response[3]
                                                m.redraw()
                                                const $scroll = $('#focus');

                                                if ($scroll.length) {
                                                    $('html,body').animate({
                                                        scrollTop: $scroll.offset().top - 100
                                                    }, 'fast');
                                                }
                                            }
                                            this.regen_start = false

                                            if (User.Account.level == 99) {
                                                UserSpellRecord.getSpellRecord()
                                            }
                                            m.redraw()
                                        })
                                    }
                                }),

                            ] : "",
                            //下一步
                            (!(this.types[this.select_type].steps[this.step()].x == "view_article" || this.types[this.select_type].steps[this.step()].x == "view_photo" || this.types[this.select_type].steps[this.step()].x == "view_data")) ? [
                                m('.col-12.px-2.pt-3',
                                    m(".w-100.text-center.py-2", {
                                        style: {
                                            color: "#000000",
                                            borderRadius: "25px",
                                            border: "2px solid #000000",
                                            fontWeight: 700,
                                            cursor: "pointer",
                                        },
                                        onclick: (e) => {
                                            e.preventDefault()
                                            let _check = false
                                            let _message = "請填寫"
                                            if (this.types[this.select_type].steps[this.step()].x == "view") {
                                                this.types[this.select_type].steps[this.step()].s.map((_question) => {
                                                    if (_question['required'] && !this.model.hasOwnProperty(_question['variable'])) {
                                                        _check = true
                                                        _message += _question['question'] + "、"
                                                    }
                                                })
                                            } else if (this.types[this.select_type].steps[this.step()].x == "suggest") {
                                                this.gpt_response.map((_question, index) => {
                                                    if (_question.answer == null) {
                                                        _check = true
                                                    }
                                                })
                                            }
                                            this.temp_function = false
                                            if (this.record_status() == "recording") {
                                                this.temp_function = true
                                                var event = new CustomEvent("customer_recording");
                                                document.dispatchEvent(event);
                                                document.addEventListener("customer_recording_done", (e) => {
                                                    this.temp_function = false
                                                })
                                            }
                                            let checkwait = () => {
                                                if (!this.temp_function) {
                                                    if (_check) {
                                                        swal.fire({
                                                            title: _message,
                                                            icon: "error",
                                                            showCancelButton: false,
                                                            showConfirmButton: false,
                                                            timer: 1800
                                                        });
                                                    }
                                                    else {
                                                        if (this.types[this.select_type].steps[this.step()].x == "view") {
                                                            this.types[this.select_type].steps[this.step()].s.map((_temp) => {
                                                                this.questions.push(_temp)
                                                            })
                                                        }
                                                        if (this.types[this.select_type].steps[this.step() + 1].g) {
                                                            FBPost.sendToGPTForVariable(this.model, this.questions, this.types[this.select_type].id, this.step(), this.gpt_response, this.select_user_options).then((_response) => {
                                                                while (this.types[this.select_type].steps[this.step() + 1]['x'] == 'dgpt') {
                                                                    this.step(this.step() + 1)
                                                                }
                                                                this.step(this.step() + 2)

                                                                if (this.types[this.select_type].steps[this.step()].x == "view_article" || this.types[this.select_type].steps[this.step()].x == "view_photo" || this.types[this.select_type].steps[this.step()].x == "view_data") {
                                                                    dataLayer.push({
                                                                        'event': 'gen',
                                                                        'user_id': User.Account.id,
                                                                        'temp_id': this.types[this.select_type].id,
                                                                        'temp_name': this.types[this.select_type].name,
                                                                        'gen_free': this.types[this.select_type].is_free,
                                                                        'output_format': (this.types[this.select_type].steps[this.step()].x == "view_article") ? "text" : "pic",
                                                                        'custom_setting': (this.select_user_options.length > 0) ? true : false,
                                                                        'token': (_response.hasOwnProperty('status') && !_response.status) ? 0 : _response[5],
                                                                        'status': (_response.hasOwnProperty('status') && !_response.status) ? false : true,
                                                                        'error_message': (_response.hasOwnProperty('status') && !_response.status) ? _response.message : null,
                                                                    });
                                                                }

                                                                if (_response.hasOwnProperty('status') && !_response.status) {
                                                                    swal.fire({
                                                                        title: _response.message,
                                                                        // text: "請確認產品或服務名字!",
                                                                        icon: "error",
                                                                        showCancelButton: false,
                                                                        showConfirmButton: false,
                                                                        timer: 5800
                                                                    });
                                                                    return
                                                                }
                                                                if (this.types[this.select_type].steps[this.step()].x == "view_suggest") {
                                                                    JSON.parse(_response[0].choices[0].message.content).options.map((_question, index) => {
                                                                        Object.keys(_question).map((_anser) => {
                                                                            this.gpt_response.push({
                                                                                key: _anser,
                                                                                options: _question[_anser],
                                                                                answer: [],
                                                                                step: this.step() - 2
                                                                            })
                                                                        })
                                                                    })
                                                                    m.redraw()
                                                                    const $scroll = $('#focus_suggest');

                                                                    if ($scroll.length) {
                                                                        $('html,body').animate({
                                                                            scrollTop: $scroll.offset().top - 150
                                                                        }, 'fast');
                                                                    }
                                                                } else if (this.types[this.select_type].steps[this.step()].x == "view_article" || this.types[this.select_type].steps[this.step()].x == "view_photo" || this.types[this.select_type].steps[this.step()].x == "view_data") {
                                                                    this.articles = _response[3]
                                                                    this._user_article_end = (_response[0].hasOwnProperty('choices')) ? _response[0].choices : _response[0].data
                                                                    // if (_response[0].hasOwnProperty('choices')) {
                                                                    //     this.article(marked(this._user_article_end[this.select_article].message.content))
                                                                    // }

                                                                    this.expend = false
                                                                    m.redraw()

                                                                    const $scroll = $('#focus');

                                                                    if ($scroll.length) {
                                                                        $('html,body').animate({
                                                                            scrollTop: $scroll.offset().top - 100
                                                                        }, 'fast');
                                                                    }
                                                                }

                                                                if (User.Account.level == 99) {
                                                                    UserSpellRecord.getSpellRecord()
                                                                }
                                                            })
                                                        } else {
                                                            this.step(this.step() + 1)
                                                        }
                                                    }
                                                }
                                                else {
                                                    setTimeout(checkwait, 1000); // check again in a second
                                                }
                                            }
                                            checkwait()
                                        }
                                    },
                                        [
                                            m("span", "下一步")
                                        ])
                                ),
                            ] : "",
                            //Loading
                            (this.regen_start == true) ? [
                                m(".col-12.d-flex.justify-content-center.align-items-center", {
                                    style: {
                                        minHeight: "50vh",
                                        // paddingTop:"7rem"
                                    }
                                }, [
                                    m(".h-100.w-100.d-flex.justify-content-center.align-items-center.position-fixed", [
                                        m("span.loader")
                                    ])
                                ]),
                            ] : "",
                        ] : ""
                    ]) : "",
            ]),
            m(".py-5"),
            m(Dialog),

        ]
    }
}
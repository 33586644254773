import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _datas = []
let _search_boxes = []

const constraints = {
    title: {
        presence: {
            allowEmpty: false,
            message: "^請輸入主題"
        }
    },
}

class UserBox extends BaseModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.user_id = args.user_id || null;
        this.is_root = (args.is_root != null) ? args.is_root : true;
        this.parent_id = args.parent_id || null;
        this.title = (args.title || args.name) || "";
        this.subtitle = args.subtitle || "";
        this.articles = args.articles || [];
        this.updated_at = moment(args.updated_at).format('Y-M-D') || moment().format('Y-M-D');
        this.created_at = moment(args.created_at).format('Y-M-D') || moment().format('Y-M-D');
    }

    static get datas() {
        return _datas;
    }

    static get boxes() {
        return _search_boxes;
    }

    

    static getBox(page) {
        UserBox.loading = true
        page = (page === undefined) ? UserBox.pageIndex : page
        UserBox.pageIndex = page
        let url = `${window.BASE_URL}/api/manager/user/box/record?page=${page}`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
        }).then((response) => {
            UserBox.paging = {
                pageCount: response.last_page,
                pageNo: response.current_page,
                pageSize: response.per_page,
                totalRecordCount: response.total
            }
            _datas = response
            UserBox.loading = false
            return _datas
        })
    }

    static searchBox(page) {
        UserBox.loading = true
        page = (page === undefined) ? UserBox.pageIndex : page
        UserBox.pageIndex = page
        let url = `${window.BASE_URL}/api/manager/user/box/searchBox?page=${page}&keyword=${window.search_keyword}&category=${window.category}`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
        }).then((response) => {
            UserBox.paging = {
                pageCount: response.last_page,
                pageNo: response.current_page,
                pageSize: response.per_page,
                totalRecordCount: response.total
            }
            _search_boxes = response
            UserBox.loading = false
            return response.data
        })
    }
    save() {
        UserBox.loading = true
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/manager/user/box/save`,
            data: this,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
        }).then((response) => {
            UserBox.loading = false
            return response
        })
    }
    static remove(id) {
        UserBox.loading = true
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/manager/user/box/remove`,
            data: {
                id: id
            }

        }).then((response) => {
            UserBox.loading = false
            return response
        })
    }

    static inserArticle(article_id, index, user_box_id) {
        UserBox.loading = true
        let url = `${window.BASE_URL}/api/manager/user/box/inserArticle`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
            data: {
                index: index,
                article_id: article_id,
                user_box_id: user_box_id
            }
        }).then((response) => {
            UserBox.loading = false
            return _datas
        })
    }
    static removeRecord(record_id) {
        UserBox.loading = true
        let url = `${window.BASE_URL}/api/manager/user/box/removeRecord`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
            data: {
                record_id: record_id,
            }
        }).then((response) => {
            UserBox.loading = false
            return _datas
        })
    }

    static modifyContentTitle(id,title) {
        UserBox.loading = true
        let url = `${window.BASE_URL}/api/manager/user/box/modifyContentTitle`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
            data: {
                id: id,
                title: title,
            }
        }).then((response) => {
            UserBox.loading = false
            return response
        })
    }

    







}

export default UserBox
import m from 'mithril'

class Footer {
    view({
        attrs
    }) {
        return [
            m("footer.footer.py-0",{
                style:{
                    backgroundColor:"rgba(0,0,0,0.5)"
                }
            },
                [
                    m("span.float-right.text-light",
                        [
                            "Copyright ",
                            m.trust("&copy;"),
                            " 2022 Rebalance 立貝斯科技. All rights reserved."
                        ]
                    )
                ]
            )
        ]
    }
}

export default Footer
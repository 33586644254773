import m from 'mithril'

class ErrorPage {
    constructor(vnode) {

    }
    view(vnode) {
        return [
        m(".h-100.w-100.d-flex.justify-content-center.align-items-center.position-fixed.bg-theme",
           m("",
                m(".row",
                    m(".d-flex.align-items-center.text-center.error-page",
                        m(".col-12.mx-auto.text-white",
                            [
                                m(".row.align-items-center.d-flex.flex-row",
                                    [
                                        m(".col-lg-6.text-lg-right.pr-lg-4",
                                            m("h1.display-1.mb-0","404")
                                        ),
                                        m(".col-lg-6.error-page-ider.text-lg-left.pl-lg-4",
                                            [
                                                m("h2","SORRY!"),
                                                m("h5.mt-2","找不到此頁面")
                                            ]
                                        )
                                    ]
                                ),
                                m(".row.mt-5",
                                    m(".col-12.text-center.mt-xl-2",
                                        m("a.btn.btn-outline-light.font-weight-medium[href='#!/']","回首頁")
                                    )
                                ),
                                m(".row.mt-5",
                                    m(".col-12.mt-xl-2",
                                        m("p.text-white.font-weight-medium.text-center",
                                            [
                                                m.trust("2024&copy;"),
                                                " Copyright All Rights Reserved",
                                                " APEAI 版權所有仿冒必究"
                                            ]
                                        )
                                    )
                                )
                            ]
                        )
                    )
                )
            )
        )
        ]
    }
}

export default ErrorPage
import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _data = []

const constraints = {
    
}

class UserSpellRecord extends BaseModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;

    }

    static get datas() {
        return _data;
    }

    static getSpellRecord(page) {
        UserSpellRecord.loading = true
        page = (page === undefined) ? UserSpellRecord.pageIndex : page
        UserSpellRecord.pageIndex = page
        let url = `${window.BASE_URL}/api/manager/user/spellRecord?page=${page}`
        return m.request({
            method: 'POST',
            url: url,
            headers: {
                "Authorization": "Bearer " + window.JWTAuth,
            },
        }).then((response) => {
            UserSpellRecord.paging = {
                pageCount: response.last_page,
                pageNo: response.current_page,
                pageSize: response.per_page,
                totalRecordCount: response.total
            }
            _data = response.data
            UserSpellRecord.loading = false
            return _data
        })
    }
}

export default UserSpellRecord
import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea,
    Paging
} from 'components'
import * as Components from 'components'
import {
    User,
    UserRenew
} from "app/models"
import moment from 'moment'
import ArticleDetailComponent from './articleDetail'
import classNames from 'classnames'




class RenewTicketDialog {
    constructor(vnode) {
        this.invoice_information = vnode.attrs.model
        this.level = vnode.attrs.level
        UserRenew.getLastRecord().then((_response) => {
            if (_response) {
                this.invoice_information.type = _response.type
                this.invoice_information.eInvoice = _response.e_invoice
                this.invoice_information.company_name = _response.company_name
                this.invoice_information.isPaper = _response.is_paper
                this.invoice_information.mail = _response.mail
            }
        })
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return m('.modal-dialog.modal-xl.modal-dialog-centered.modal-dialog-scrollable', [
            m('.modal-content', [
                m('.modal-header', {
                    style: {
                        backgroundColor: "#B6B6B6"
                    }
                }, [
                    m('h5.modal-title', [
                        m('span', "續約紀錄")
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body.p-0", [
                    m(".row.m-0.w-100.pt-4.px-2", [
                        m(".col-12", [
                            m("span.font-weight-bold", { style: { fontSize: "1.25rem" } }, "【發票資訊】"),
                        ]),
                        m(".col-12.py-2.px-4", [
                            m("span.font-weight-bold", { style: { fontSize: "1.25rem" } }, "*注意⚠️：根據 綠界科技 的規定，超商條碼收款成功的時間可能會有2-5天的作業時間，付款方式建議使用信用卡會比較方便"),
                        ]),
                        m(".col-12", [
                            m("label.text-dark.mb-1.pl-2", {
                                style: {
                                    "font-weight": 700
                                }
                            }, "發票類型"),
                            m(".input-group", [
                                m(".input-group-text", {
                                    style: {
                                        backgroundColor: "#FFFFFF"
                                    }
                                }, [
                                    m("input.mt-0", {
                                        type: "radio",
                                        value: "user",
                                        checked: this.invoice_information.type == "user",
                                        onclick: (e) => {
                                            e.preventDefault()
                                            this.invoice_information.type = "user",
                                                m.redraw()
                                        },
                                        style: {
                                            width: "20px",
                                            height: "20px",
                                        }
                                    })
                                ]),
                                m("input.form-control", {
                                    type: 'text',
                                    value: "二聯式: 全面採用電子發票",
                                    disable: true,
                                    readonly: true,
                                    style: {
                                        backgroundColor: "#FFFFFF"
                                    }
                                })
                            ]),
                            m(".input-group", [
                                m(".input-group-text", {
                                    style: {
                                        backgroundColor: "#FFFFFF"
                                    }
                                }, [
                                    m("input.mt-0", {
                                        type: "radio",
                                        value: "company",
                                        checked: this.invoice_information.type == "company",
                                        onclick: (e) => {
                                            e.preventDefault()
                                            this.invoice_information.type = "company"
                                            m.redraw()
                                        },
                                        style: {
                                            width: "20px",
                                            height: "20px",
                                        }
                                    })
                                ]),
                                m("input.form-control", {
                                    type: 'text',
                                    value: "三聯式: 開立電子發票",
                                    disable: true,
                                    readonly: true,
                                    style: {
                                        backgroundColor: "#FFFFFF"
                                    }
                                })
                            ]),
                            (this.invoice_information.type == "user") ? [
                                m(".col-12.py-2.px-0", [
                                    m(TextBox, {
                                        oninput: (e) => {
                                            this.invoice_information.eInvoice = e.target.value
                                        },
                                        value: this.invoice_information.eInvoice,
                                        type: 'text',
                                        label: "手機條碼載具",
                                        required: false,
                                        tip: "手機條碼載具，若無則不需填寫"
                                    }),
                                ]),
                            ] : "",
                            (this.invoice_information.type == "company") ? [
                                m(".col-12.py-2.px-0", [
                                    m(TextBox, {
                                        oninput: (e) => {
                                            this.invoice_information.company_name = e.target.value
                                        },
                                        value: this.invoice_information.company_name,
                                        type: 'text',
                                        label: "公司抬頭",
                                        required: true,
                                    }),
                                ]),
                                m(".col-12.py-2.px-0", [
                                    m(TextBox, {
                                        oninput: (e) => {
                                            this.invoice_information.eInvoice = e.target.value
                                        },
                                        value: this.invoice_information.eInvoice,
                                        type: 'text',
                                        label: "公司統編",
                                        required: true,
                                    }),
                                ]),

                            ] : [],
                            m(".col-12.py-2.px-0", [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.invoice_information.mail = e.target.value
                                    },
                                    value: this.invoice_information.mail,
                                    type: 'text',
                                    label: "電子信箱",
                                    required: true,
                                }),
                            ]),

                        ]),


                    ]),
                ]),
                m('.modal-footer', [
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#000000",
                            borderRadius: "25px",
                            border: "2px solid #000000",
                            fontWeight: 700,
                            fontSize: "1.25rem"
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            if (this.invoice_information.type == null) {
                                Swal.fire({
                                    title: "請選擇發票類型",
                                })
                                return
                            }
                            if (this.invoice_information.type == "company" && (this.invoice_information.company_name == "" || this.invoice_information.eInvoice == "")) {
                                Swal.fire({
                                    title: "請填寫公司資訊",
                                })
                                return
                            }
                            if (this.invoice_information.mail == "") {
                                Swal.fire({
                                    title: "請填寫發票寄送地址",
                                })
                                return
                            }

                            User.createRenew(this.level.id, this.invoice_information).then((_response) => {
                                window.location.href = `${window.BASE_URL}/goEcpay?serial=${_response}`
                            })
                        }
                    }, '確認'),
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            fontWeight: 700,
                            fontSize: "1.25rem"
                        },
                        onclick: this.close.bind(this)
                    }, '關閉')
                ])
            ])
        ])
    }
}

export default RenewTicketDialog
import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea
} from 'components'
import {
    Authority,
} from "app/models"
import moment from 'moment'


class OptionDetailDialog {
    constructor(vnode) {
        this.model = vnode.attrs.model
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    save() {
        this.model.save().then((response) => {
            Dialog.close(true)
        })
    }

    view({
        attrs
    }) {
        return m('.modal-dialog.modal-dialog-centered.modal-dialog-scrollable', [
            m('.modal-content', [
                m('.modal-header', {
                    style: {
                        backgroundColor: "#B6B6B6"
                    }
                },[
                    m('h5.modal-title', [
                        m('span', this.model.id == 0 ? "新增資訊" : "編輯資訊")
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body", [
                    m('.container-fluid', [
                        m('.row', [
                            m('.col-12.pb-3',
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.title = e.target.value
                                    },
                                    value: this.model.title,
                                    type: 'text',
                                    label: "資訊主題",
                                    required: true,
                                    tips:  "背景資訊",
                                    validate: () => {
                                        this.model.valid('title')
                                    },
                                    error: this.model.error('title'),
                                })
                            ),
                            m('.col-12.pb-3',
                                m(TextArea, {
                                    oninput: (e) => {
                                        this.model.content = e.target.value
                                    },
                                    value: this.model.content,
                                    type: 'text',
                                    label: "資訊內容",
                                    required: true,
                                    tips:"你是Ape AI小編，Ape AI是一間協助用AI幫人撰寫文案的公司。你自稱小小編，稱呼讀者為小小粉",
                                    validate: () => {
                                        this.model.valid('content')
                                    },
                                    error: this.model.error('content'),
                                })
                            ),
                            m('.w-100'),
                        ])
                    ])
                ]),
                m('.modal-footer', [
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#000000",
                            borderRadius: "25px",
                            border: "2px solid #000000",
                            fontWeight: 700,
                            fontSize: "1.25rem"
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            if (this.model.validate()) {
                                return false
                            }
                            this.save()
                        }
                    }, '儲存'),
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            fontWeight: 700,
                            fontSize: "1.25rem"
                        },
                        onclick: this.close.bind(this)
                    }, '關閉')
                ])
            ])
        ])
    }
}

export default OptionDetailDialog
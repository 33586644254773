import m from 'mithril'
import stream from 'mithril/stream'
import classNames from 'classnames'
import * as Menu from 'app/models/menu'
import {
    User
} from 'app/models'

// const clickEvent = (dom, toggle, e) => {
//     if (!dom.contains(e.target)) {
//         toggle(null)
//         m.redraw()
//     }
// }


class MenuComponent {
    constructor(vnode) {
        this.menuToggle = vnode.attrs.navbarToggle
        this.roles = [
            'dashboard',
            '聊天',
            '媒合紀錄',
            '任務列表',
            '會員相關',
            '申請成為師傅',
            '師傅列表'
        ]
        // User.load(window.USER_ID).then((response) => {
        //     // this.roles = JSON.parse(response.permission)
        // })
        // this.auth = vnode.attrs.auth
        this.toggle = stream()
    }
    // oncreate(vnode) {
    //     document.addEventListener('click', clickEvent.bind(null, vnode.dom, this.toggle), false)
    // }
    view({
        attrs
    }) {
        return [
            m("div#sidebar-menu",
                [
                    m("ul",
                        [
                            Menu.ManagementItems.map((item, index) => {
                                if (window.USER_ID == 2) {
                                    if (this.roles.includes(item.auth)) {
                                        return m("li.submenu", {
                                            class: classNames({
                                                'dropdown': (item.children && item.children.length > 0)
                                            }),
                                            // onmouseover: (e) => {
                                            //     e.preventDefault()
                                            //     if (item.children && item.children.length > 0) {
                                            //         this.toggle(index)
                                            //     }

                                            // },
                                            // onmouseout: (e) => {
                                            //     e.preventDefault()
                                            //     if (item.children && item.children.length > 0) {
                                            //         this.toggle(null)
                                            //     }
                                            // }
                                        }, [
                                            (item.children && item.children.length > 0) ? [

                                                m("a.has-arrow[href='#'][aria-expanded='false'].pl-4", {
                                                    style: {
                                                        borderColor: 'transparent',
                                                        // "border-left": (item.children.findIndex((_child)=>{return _child.href == m.route.get()}) >= 0)? "5px solid #c9d8e6" : "2px solid transparent",
                                                        // "background-color": (item.children.findIndex((_child) => { return _child.href == m.route.get() }) >= 0) ? "#1964a7" : ""
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        if (item.children && item.children.length > 0) {
                                                            this.toggle(index)
                                                        }
                                                        // this.toggle(null)
                                                        // attrs.navbarToggle(false)

                                                        // m.route.set(item.href)
                                                    }
                                                }, [
                                                    m(`i.${item.icon}`),
                                                    m("span.pl-2.align-middle",
                                                        item.title,
                                                    ),
                                                    m("a.drop-arrow", {
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            this.toggle(index)
                                                        }
                                                    }, "+")
                                                ]),
                                                m("ul[aria-expanded='false']", {
                                                    style: {
                                                        display: (this.toggle() == index) ? "block" : "none"
                                                    }
                                                },
                                                    item.children.map((child) => {
                                                        if (this.roles.includes(child.auth)) {
                                                            return [m('a[href="#"].nav-link.text-light', {
                                                                style: {
                                                                    "border-left": (m.route.get() == child.href) ? "5px solid #c9d8e6" : "2px solid transparent",
                                                                    "background-color": (m.route.get() == child.href) ? "#1964a7" : ""
                                                                },
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    // this.toggle(null)
                                                                    if (window.isMobile) {
                                                                        attrs.navbarToggle(false)
                                                                    }
                                                                    m.route.set(child.href)
                                                                }
                                                            }, [
                                                                m(`i.${child.icon}`),
                                                                m("span.pl-2.align-middle",
                                                                    child.title
                                                                )
                                                            ])]
                                                        }
                                                    })
                                                ),

                                            ] : [
                                                m('a[href="#"].nav-link.pl-4', {
                                                    style: {
                                                        "border-left": (m.route.get() == item.href) ? "5px solid #c9d8e6" : "2px solid transparent",
                                                        "background-color": (m.route.get() == item.href) ? "#1964a7" : ""
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        this.toggle(null)
                                                        if (window.isMobile) {
                                                            attrs.navbarToggle(false)
                                                        }
                                                        m.route.set(item.href)
                                                    }
                                                }, [
                                                    m(`i.${item.icon}`),
                                                    m("span.pl-4.align-middle",
                                                        item.title
                                                    )
                                                ])
                                            ]
                                        ])
                                    }
                                } else {
                                    return m("li.submenu", {
                                        class: classNames({
                                            'dropdown': (item.children && item.children.length > 0)
                                        }),
                                        // onmouseover: (e) => {
                                        //     e.preventDefault()
                                        //     if (item.children && item.children.length > 0) {
                                        //         this.toggle(index)
                                        //     }

                                        // },
                                        // onmouseout: (e) => {
                                        //     e.preventDefault()
                                        //     if (item.children && item.children.length > 0) {
                                        //         this.toggle(null)
                                        //     }
                                        // }
                                    }, [
                                        (item.children && item.children.length > 0) ? [

                                            m("a.has-arrow[href='#'][aria-expanded='false'].pl-4", {
                                                style: {
                                                    borderColor: 'transparent',
                                                    // "border-left": (item.children.findIndex((_child)=>{return _child.href == m.route.get()}) >= 0)? "5px solid #c9d8e6" : "2px solid transparent",
                                                    // "background-color": (item.children.findIndex((_child) => { return _child.href == m.route.get() }) >= 0) ? "#1964a7" : ""
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    if (item.children && item.children.length > 0) {
                                                        this.toggle(index)
                                                    }
                                                    // this.toggle(null)
                                                    // attrs.navbarToggle(false)

                                                    // m.route.set(item.href)
                                                }
                                            }, [
                                                m(`i.${item.icon}`),
                                                m("span.pl-2.align-middle",
                                                    item.title,
                                                ),
                                                m("a.drop-arrow", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        this.toggle(index)
                                                    }
                                                }, "+")
                                            ]),
                                            m("ul[aria-expanded='false']", {
                                                style: {
                                                    display: (this.toggle() == index) ? "block" : "none"
                                                }
                                            },
                                                item.children.map((child) => {
                                                    return [m('a[href="#"].nav-link.text-light', {
                                                        style: {
                                                            "border-left": (m.route.get() == child.href) ? "5px solid #c9d8e6" : "2px solid transparent",
                                                            "background-color": (m.route.get() == child.href) ? "#1964a7" : ""
                                                        },
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            // this.toggle(null)
                                                            if (window.isMobile) {
                                                                attrs.navbarToggle(false)
                                                            }
                                                            m.route.set(child.href)
                                                        }
                                                    }, [
                                                        m(`i.${child.icon}`),
                                                        m("span.pl-2.align-middle",
                                                            child.title
                                                        )
                                                    ])]
                                                })
                                            ),

                                        ] : [
                                            m('a[href="#"].nav-link.pl-4', {
                                                style: {
                                                    "border-left": (m.route.get() == item.href) ? "5px solid #c9d8e6" : "2px solid transparent",
                                                    "background-color": (m.route.get() == item.href) ? "#1964a7" : ""
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    this.toggle(null)
                                                    if (window.isMobile) {
                                                        attrs.navbarToggle(false)
                                                    }
                                                    m.route.set(item.href)
                                                }
                                            }, [
                                                m(`i.${item.icon}`),
                                                m("span.pl-4.align-middle",
                                                    item.title
                                                )
                                            ])
                                        ]
                                    ])
                                }
                            }),
                        ]
                    )
                ]
            )
        ]
    }
}

class MenuItemDropDown {
    constructor(vnode) {
        // this.auth = vnode.attrs.auth
        this.toggle = stream()
    }
    view({
        attrs
    }) {
        return
    }
}

class MainMenu {
    view({
        attrs
    }) {
        return [
            m(MenuComponent, {
                navbarToggle: attrs.navbarToggle,
                auth: attrs.auth,
                // isSite: attrs.isSite
            }),
        ]
    }
}

export default MainMenu
import m from 'mithril'
import isMobile from 'lib/detect-mobile'
import * as Page from 'app/pages'
import AjaxLoader from 'components/preloader/ajax_loader'
import Layout from 'app/container/layout'

import {
    User,
} from 'app/models'

const root = document.body

window.isMobile = isMobile()

const managerRoutes = {
    '/': Page.VariablePage,
    '/boxSystem': Page.BoxSystemPage,
    '/paySuccess': Page.PaySuccessPage,
}

export function render() {
    const loader = document.getElementById('loader');

    $("body").css({
        backgroundColor: "#FCFDFE",
        "overflow-y": "scroll",
        "minHeight": "100vh",
        "position": "relative",
        "padding-bottom": "0px"
    });
    if (window.USER_ID == null) {
        managerRoutes['/demo'] = Page.DemoPage
        managerRoutes['/:404...'] = Page.ErrorPage
        m.route(root, '/demo', managerRoutes)

    } else {
        Promise.all([User.load(window.USER_ID)]).then((_response) => {
            managerRoutes['/'] = Page.VariablePage
            managerRoutes['/boxSystem'] = Page.BoxSystemPage
            managerRoutes['/paySuccess'] = Page.PaySuccessPage

            if (_response[0].level == 99) {
                managerRoutes['/rebalanceSetting'] = Page.SettingPage
                managerRoutes['/rebalanceSetting/detail/:id'] = Page.SettingDetailPage
                managerRoutes['/rebalanceSetting/member'] = Layout(Page.MemberPage)

            }
            managerRoutes['/:404...'] = Page.ErrorPage
            m.route(root, '/', managerRoutes)
            if (window.Router == "paySuccess") {
                m.route.set("/paySuccess")
            }
        })
    }

}

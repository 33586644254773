import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea,
    ResultBox,
    QuestionBox
} from 'components'
import * as Components from 'components'
import {
    UserOption,
    Setting,
    User
} from "app/models"
import moment from 'moment'
import classNames from 'classnames';

class ArticleDetailModal {
    constructor(vnode) {
        this.loading = true
        this.article_model = vnode.attrs.model
        this.model = this.article_model.model
        this.select_article = 0
        this.change_article = false
        this.types = []
        this.select_type = null
        this.articles = this.article_model
        Setting.fetchModels().then((_response) => {
            this.types = _response
            this.select_type = _response.map(e => e.id).indexOf(this.article_model.model_id);
            this.step = stream(0)
            this.loading = false
        })
        this.select_user_options = []


    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return m('.modal-dialog.modal-dialog-centered.modal-dialog-scrollable.modal-lg', [
            m('.modal-content', [
                m('.modal-header', {
                    style: {
                        backgroundColor: "#B6B6B6"
                    }
                }, [
                    m('h5.modal-title', [
                        m('span', this.article_model.model_name)
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                (!this.loading) ? [
                    m(".modal-body.px-0", [
                        m(".row.w-100.m-0", [
                            m(".col-md-6.col-12.px-0", [
                                (this.types[this.select_type].steps[this.step()].x == "view") ? [
                                    this.types[this.select_type].steps[this.step()].s.map((_question) => {
                                        return m(QuestionBox, {
                                            question: _question,
                                            model: this.model,
                                            question_type: "record",
                                        })
                                    }),
                                    // (UserOption.datas.length > 0) ?
                                    //     m(".row.w-100.m-0", [
                                    //         m(".col-12.px-0", [
                                    //             m("label.text-dark.mb-1.pl-2", {
                                    //                 style: {
                                    //                     "font-weight": 700
                                    //                 }
                                    //             }, "帶入個人資訊："),
                                    //         ]),
                                    //         UserOption.datas.map((_options, index) => {
                                    //             return m(".col-12.py-2", [
                                    //                 m(".input-group", [
                                    //                     m(".input-group-text", {
                                    //                         style: {
                                    //                             backgroundColor: "#FFFFFF"
                                    //                         }
                                    //                     }, [
                                    //                         m("input.mt-0", {
                                    //                             type: "checkbox",
                                    //                             value: _options.id,
                                    //                             checked: (this.select_user_options.includes(_options.id.toString())) ? true : false,
                                    //                             onclick: (e) => {
                                    //                                 e.preventDefault()
                                    //                                 if (this.select_user_options.includes(_options.id.toString())) {
                                    //                                     this.select_user_options.splice(this.select_user_options.indexOf(_options.id.toString()), 1)
                                    //                                 } else {
                                    //                                     this.select_user_options.push(e.target.value)
                                    //                                 }
                                    //                                 m.redraw()
                                    //                             }
                                    //                         })
                                    //                     ]),
                                    //                     m("input.form-control", {
                                    //                         type: 'text',
                                    //                         value: _options.title,
                                    //                         disable: true,
                                    //                         readonly: true,
                                    //                         style: {
                                    //                             backgroundColor: "#FFFFFF"
                                    //                         }

                                    //                     })
                                    //                 ]),
                                    //             ])
                                    //         })
                                    //     ]) : "",
                                ] : [],

                                (this.types[this.select_type].steps[this.step()].x == "view_suggest" || this.step() >= 1) ? [
                                    (this._user_response && this._user_response.length > 0) ?
                                        m(".row.w-100.m-0", [
                                            this._user_response.map((_question, index) => {
                                                return m(".col-12", [
                                                    m(".row.w-100.m-0.pb-3", [
                                                        m(".col-12.pb-1.d-flex.justify-content-between",
                                                            m("span", {
                                                                style: {
                                                                    fontWeight: 600,
                                                                    fontSize: "1.2rem"
                                                                }
                                                            }, index + 1 + ". " + _question.key),
                                                            m("i.fa.fa-refresh", {
                                                                style: {
                                                                    fontSize: "1.75rem"
                                                                },
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    this.select_question = index
                                                                }
                                                            }),
                                                        ),
                                                        _question.options.map((_option) => {
                                                            return m(".col-12.p-1",
                                                                m(".w-100.text-center.py-2.px-2", {
                                                                    style: {
                                                                        color: (_question.answer.includes(_option)) ? "#000000" : "rgb(109 109 109)",
                                                                        "white-space": "normal",
                                                                        border: "1px solid #ced4da",
                                                                        fontWeight: (_question.answer.includes(_option)) ? 700 : 400,
                                                                        backgroundColor: (_question.answer.includes(_option)) ? "#ced4da" : "#FFFFFF",
                                                                        borderRadius: "5px"
                                                                    },
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        if (_question.answer.includes(_option)) {
                                                                            _question.answer.splice(_question.answer.indexOf(_option), 1)
                                                                        } else {
                                                                            _question.answer.push(_option)
                                                                        }
                                                                        m.redraw()
                                                                    }
                                                                }, m.trust(_option.replace(/ /g, "&nbsp;").replace(/\n/g, "<br>").replace(/\t/g, "&nbsp;&nbsp;")))
                                                            )
                                                        }),
                                                        //原本寫的答案
                                                        (this.model.hasOwnProperty(_question.key)) ?
                                                            m(".col-12.p-1",
                                                                m(".w-100.text-center.py-2.px-2", {
                                                                    style: {
                                                                        "white-space": "normal",
                                                                        color: (_question.answer.includes(this.model[this.model[_question.key]])) ? "#000000" : "rgb(109 109 109)",
                                                                        "white-space": "normal",
                                                                        border: "1px solid #ced4da",
                                                                        fontWeight: (_question.answer.includes(this.model[this.model[_question.key]])) ? 700 : 400,
                                                                        backgroundColor: (_question.answer.includes(this.model[this.model[_question.key]])) ? "#ced4da" : "#FFFFFF",
                                                                        borderRadius: "5px"
                                                                    },
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        if (_question.answer.includes(this.model[this.model[_question.key]])) {
                                                                            _question.answer.splice(_question.answer.indexOf(this.model[this.model[_question.key]]), 1)
                                                                        } else {
                                                                            _question.answer.push(this.model[this.model[_question.key]])
                                                                        }
                                                                        // _question.answer = this.model[this.model[_question.key]]
                                                                    }
                                                                }, this.model[this.model[_question.key]]
                                                                )
                                                            ) : ""
                                                    ]),
                                                ])
                                            })
                                        ]) : "",
                                ] : [],
                            ]),


                            m(".col-md-6.col-12.px-0", [
                                m(ResultBox, {
                                    articles: this.articles,
                                    step_type: this.types[this.select_type].steps[this.types[this.select_type].steps.length - 1].x,
                                    result_type: "godmode",
                                }),
                            ]),
                        ])
                    ]),
                ] : "",
                m('.modal-footer', [
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            fontWeight: 700,
                            fontSize: "1.25rem"
                        },
                        onclick: this.close.bind(this)
                    }, '關閉')
                ]),
            ])
        ])
    }
}

export default ArticleDetailModal
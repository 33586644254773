import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea
} from 'components'
import * as Components from 'components'
import {
    UserOption,
} from "app/models"
import moment from 'moment'
import OptionDetailDialog from './optionsDetail'


class OptionDialog {
    constructor(vnode) {
        UserOption.fetchOptions()
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return m('.modal-dialog.modal-dialog-centered.modal-dialog-scrollable', [
            m('.modal-content', [
                m('.modal-header', {
                    style: {
                        backgroundColor: "#B6B6B6"
                    }
                }, [
                    m('h5.modal-title', [
                        m('span', "預設選項")
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body.px-0", [
                    m(".row.w-100.m-0", [
                        m(".col-md-12.col-12.py-3.text-center", [
                            m("span", { style: { fontSize: "1rem", fontWeight: 500, color: "#8E8E8E" } }, "設定常用資訊，使用功能時可直接勾選帶入"),
                        ]),
                        m(".col-md-12.col-12.p-1.px-3", [
                            m(".w-100.text-center.py-2", {
                                style: {
                                    color: "#000000",
                                    // backgroundColor: "#64AECC",
                                    borderRadius: "25px",
                                    border: "2px solid #000000",
                                    fontWeight: 700,
                                    fontSize: "1.25rem"

                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    Dialog.show(OptionDetailDialog, {
                                        attrs: {
                                            model: new UserOption(),
                                        },
                                        didHide: (returnValue) => {
                                            UserOption.fetchOptions()
                                        }
                                    })
                                },
                            }, "新增資訊"),
                        ]),
                        m(".col-12.pt-2.pb-1.d-flex.justify-content-end.align-items-center", [
                            m("i.fa-regular.fa-hand-point-down", { style: { color: "#3027A1", fontSize: "0.8rem" } }),
                            m("span.font-weight-bold.pl-2", { style: { color: "#3027A1", fontSize: "0.8rem" } }, "點擊即可編輯"),
                        ]),
                        UserOption.datas.map((_option) => {
                            return [
                                m(".col-md-12.col-12.p-1", [
                                    m(".card.position-relative", [
                                        m(".card-body.py-2", {
                                            onclick: (e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                Dialog.show(OptionDetailDialog, {
                                                    attrs: {
                                                        model: new UserOption(_option),
                                                    },
                                                    didHide: (returnValue) => {
                                                        UserOption.fetchOptions()
                                                    }
                                                })
                                            }
                                        }, [
                                            m("h5.card-title.pb-1", _option.title),
                                            m("p.card-text", {
                                                style: {
                                                    fontSize: "0.9rem"
                                                }
                                            }, _option.content),

                                        ]),
                                        m("i.fa-solid.fa-trash.py-1.position-absolute.text-danger", {
                                            style: {
                                                right: "10px",
                                                top: "10px",
                                                fontSize:"1.25rem"
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                Swal.fire({
                                                    title: "你確定要刪除此變數嗎？",
                                                    showCancelButton: true,
                                                    confirmButtonText: "確認",
                                                    cancelButtonText: "取消",
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        UserOption.remove(_option.id).then((_res) => {
                                                            UserOption.fetchOptions()
                                                            m.redraw()

                                                        })
                                                    }
                                                });


                                            }
                                        }),
                                    ]),
                                ]),
                            ]
                        }),
                    ])
                ]),
                m('.modal-footer', [
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            fontWeight: 700,
                            fontSize: "1.25rem"
                        },
                        onclick: this.close.bind(this)
                    }, '關閉')
                ])
            ])
        ])
    }
}

export default OptionDialog
import m from "mithril";
// m("FilerobotImageEditor", {
//     id: "editor_container",
//     oncreate: (e) => {
//         // window.setTimeout((e) => {
//         console.log(window.FilerobotImageEditor)
//         const ImageEditor = new FilerobotImageEditor();
//         ImageEditor.open('https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg');

//         // window.FilerobotImageEditor.open('https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg');
//         // }, 3000);

//     },
//     // onremove:(e)=>{
//     //     console.log('Closing reason', closingReason);
//     //     window.FilerobotImageEditor.terminate();
//     // }
// }),


class Editor {

    constructor(vnode) {
        this.content = vnode.attrs.content
        this.editor = null

        this.config = {
            noCrossOrigin: true,
            tools: ['adjust', 'effects', 'filters', 'crop', 'resize', 'shapes', 'rotate', 'text'],
            source: vnode.attrs.content,
            onSave: (editedImageObject, designState) => console.log('saved', editedImageObject, designState),
            annotationsCommon: {
                fill: '#ff0000'
            },
            Text: { text: 'Filerobot...' },
            Rotate: { angle: 90, componentType: 'slider' },
            tabsIds: [window.FilerobotImageEditor.TABS.ADJUST, window.FilerobotImageEditor.TABS.ANNOTATE, window.FilerobotImageEditor.TABS.FILTERS, window.FilerobotImageEditor.TABS.FINETUNE, window.FilerobotImageEditor.TABS.RESIZE, window.FilerobotImageEditor.TABS.WATERMARK], // or ['Adjust', 'Annotate', 'Watermark']
            defaultTabId: window.FilerobotImageEditor.TABS.ANNOTATE, // or 'Annotate'
            defaultToolId: window.FilerobotImageEditor.TOOLS.TEXT, // or 'Text'
            translations: {
                en: {
                    'header.image_editor_title': '图片调整',
                    'header.toggle_fullscreen': '切换全屏',
                    'header.close_modal': '取消',
                    'header.close': '取消',
                    'orientation.rotate_l': '向左旋转',
                    'orientation.rotate_r': '向右旋转',
                    'orientation.flip_h': '左右翻转',
                    'orientation.flip_v': '上下翻转',
                    'toolbar.save': '嘿嘿',
                    'toolbar.apply': '确定',
                    'toolbar.download': '保存',
                    'toolbar.adjust': '色调',
                    'toolbar.cancel': '取消',
                    'toolbar.filters': '滤镜',
                    'toolbar.orientation': '调整方向',
                    'toolbar.crop': '裁剪',
                    'toolbar.resize': '大小重置',
                    'toolbar.shapes': '形状',
                    'toolbar.text': '添加文字',
                    'toolbar.effects': '特效',
                    'common.text': '文字',
                    'common.opacity': '透明度',
                    'common.stroke-color': '边框色',
                    'footer.reset': '重置',
                    'footer.undo': '向前撤销',
                    'footer.redo': '向后撤销',
                    'adjust.brightness': '亮度',
                    'adjust.contrast': '对比度',
                    'adjust.exposure': '曝光度',
                    'adjust.saturation': '饱和度'
                }
            },
            theme: {
                colors: {
                    primaryBg: '#ffffff',
                    primaryBgHover: '#f2f2f2',
                    secondaryBg: '#F8FAFB',
                    secondaryBgHover: '#DFE7ED',
                    secondaryBgOpacity: 'rgba(255,255,255, 0.75)',
                    text: '#5D6D7E',
                    textHover: '#1a2329',
                    textMute: '#aaa',
                    textWarn: '#f7931e',
                    accent: '#D5D8DC',
                    button: {
                        primary: '#6879EB',
                        secondary: '#ffffff',
                        border: 'transparent',
                        hover: '#5064ea',
                        active: '#3c4ec7'
                    },
                    border: '#DFE7ED',
                    borderLight: '#e1e1e1',
                    disabledBg: 'rgba(255, 0, 0, 0.1)',
                },
                fonts: [
                    { label: 'Arial', value: 'Arial' },
                    { label: 'Tahoma', value: 'Tahoma' },
                    { label: 'Times New Roman', value: 'Times New Roman' },
                    { label: 'Courier', value: 'Courier' },
                    { label: 'Courier New', value: 'Courier New' },
                    { label: 'Verdana', value: 'Verdana' }
                ]
            }
        };
      
    }
   
    oncreate(vnode) {
        this.editor = new window.FilerobotImageEditor(
            document.querySelector('#editor_container'),
            this.config
        );

       
    }

    onremove(vnode) {
    }

    view({
        attrs
    }) {
        return m('', {
            id: "editor_container",
        }, [
            (this.editor != null) ?
                m.trust(this.editor.render()) : ""
        ])
    }
}

export default Editor
import m from 'mithril';
//Googlt GTM
export default class GoogleGTM {
    view({
        attrs
    }) {
        return m("noscript[noscript]", [
            m("iframe", {
                src: "https://www.googletagmanager.com/ns.html?id=GTM-TV6P6QPW",
                height: 0,
                width: 0
            })
        ])
    }
}
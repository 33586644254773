import m from 'mithril';
import { Dialog } from 'components';
import { UserBox } from 'app/models';
import BoxEditComponent from './boxEdit';
import classNames from 'classnames';


class BoxDialog {
    constructor(vnode) {
        this.reSortTree()
        this.boxs = [];
        this.select = null;
        this.currentNode = null;
        this.articleId=vnode.attrs.articleId
        this.index = vnode.attrs.index;
        this.type = vnode.attrs.type ? vnode.attrs.type : 'setting';

    }

    reSortTree() {
        this.items = [];
        this.items_failed = [];
        UserBox.getBox().then((_response) => {
            _response.forEach((_item) => {
                if (_item.is_root) {
                    this.items.push({
                        id: _item.id,
                        type: 'folder',
                        name: _item.title,
                        subtitle: _item.subtitle,
                        items: _item.articles,
                        is_open: false,
                        children: [],
                    });
                } else {
                    let parent = this.findItemById(this.items, _item.parent_id);
                    if (parent) {
                        parent.children.push({
                            id: _item.id,
                            type: 'folder',
                            name: _item.title,
                            subtitle: _item.subtitle,
                            items: _item.articles,
                            is_open: false,
                            children: [],
                        });
                    } else {
                        this.items_failed.push(_item);
                    }
                }
            });
            this.processFailedItems();
        });
    }

    processFailedItems() {
        let retry = [];

        this.items_failed.forEach((_item) => {
            let parent = this.findItemById(this.items, _item.parent_id);
            if (parent) {
                parent.children.push({
                    id: _item.id,
                    type: 'folder',
                    name: _item.title,
                    subtitle: _item.subtitle,
                    items: _item.articles,
                    is_open: false,
                    children: [],
                });
            } else {
                retry.push(_item);
            }
        });

        if (retry.length !== this.items_failed.length) {
            this.items_failed = retry;
            if (this.items_failed.length > 0) {
                this.processFailedItems();
            }
        }
    }

    findItemById(items, id) {
        for (let item of items) {
            if (item.id === id) {
                return item;
            } else if (item.children.length > 0) {
                let found = this.findItemById(item.children, id);
                if (found) return found;
            }
        }
        return null;
    }

    insert() {
        UserBox.inserArticle(this.articleId, this.index, this.select).then(() => {
            setTimeout(() => {
                swal.fire({
                    title: '已存入',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 500,
                });
                Dialog.close(false);
                m.redraw();
            }, 200);
        });
    }

    close(event, value) {
        setTimeout(() => {
            Dialog.close(false);
            m.redraw();
        }, 200);
    }

    renderItems(item, level) {
        if (item.type == "folder") {
            return m(".row.w-100.m-0.pt-2", [
                m(".col-12.px-0", [
                    m("div.select_hover", {
                        style: {
                            paddingLeft: (level * 15) + "px",
                            border: (this.select == item.id) ? "1px solid #000000" : "",
                            backgroundColor: (this.select == item.id) ? "#EFEFEF" : "",
                        },
                        onclick: (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (this.type === 'selectBox') {
                                this.select = item.id;
                            } 
                        }
                    }, [
                        m(".d-flex.justify-content-between", [
                            m(".", {

                            }, [
                                m("i.fa-solid.pr-3", {
                                    style: {
                                        fontSize: "1.25rem"
                                    },
                                    class: classNames({
                                        "fa-chevron-right": !item.is_open,
                                        "fa-angle-down": item.is_open,
                                    }),
                                    onclick: (e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        item.is_open = !item.is_open
                                    }
                                }),
                                m("i.fa.fa-box.text-warning.pr-2", {
                                   
                                    style: {
                                        fontSize: "1.25rem"
                                    },
                                }),
                                m("span", {
                                    style: {
                                        fontSize: "1.25rem"
                                    },
                                }, item.name),

                            ]),
                        ])
                    ]),
                    (item.is_open) ? item.items.map(subItem => this.renderItems(subItem, level + 1)) : null,
                    (item.children.length > 0 && item.is_open) ? item.children.map(child => this.renderItems(child, level + 1)) : null,
                ])
            ]);
        }
    }

    view({ attrs }) {
        return m('.modal-dialog.modal-dialog-centered.modal-dialog-scrollable', [
            m('.modal-content', [
                m('.modal-header', {
                    style: {
                        backgroundColor: '#B6B6B6',
                    },
                }, [
                    m('h5.modal-title', [
                        m('span', '我的盒子'),
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this),
                    }, [
                        m('span', m.trust('&times;')),
                    ]),
                ]),
                m('.modal-body.p-0', [
                    m('.row.m-0.w-100.px-3', [
                        this.items.map(item => this.renderItems(item, 0))
                    ]),
                ]),
                m('.modal-footer', [
                    this.type === 'selectBox' && this.select != null
                        ? m('.text-center.py-1.px-3', {
                            style: {
                                color: '#000000',
                                borderRadius: '25px',
                                border: '2px solid #000000',
                                fontWeight: 700,
                                fontSize: '1.25rem',
                                cursor: 'pointer',
                            },
                            onclick: this.insert.bind(this),
                        }, '選擇')
                        : m('.text-center.py-1.px-3', {
                            style: {
                                color: '#B6B6B6',
                                borderRadius: '25px',
                                border: '2px solid #B6B6B6',
                                fontWeight: 700,
                                fontSize: '1.25rem',
                                cursor: 'pointer',
                            },
                            onclick: this.close.bind(this),
                        }, '關閉'),
                ]),
            ]),
        ]);
    }
}

export default BoxDialog;

import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea
} from 'components'
import {
    UserBox,
} from "app/models"
import moment from 'moment'


class BoxEditDialog {
    constructor(vnode) {
        this.model = vnode.attrs.model
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    save() {
        this.model.save().then((response) => {
            Dialog.close(true)
        })
    }

    view({
        attrs
    }) {
        return m('.modal-dialog.modal-dialog-centered.modal-dialog-scrollable.modal-xl', [
            m('.modal-content', [
                m('.modal-header', {
                    style: {
                        backgroundColor: "#B6B6B6"
                    }
                }, [
                    m('h5.modal-title', [
                        m('span', this.model.id == 0 ? "新增盒子" : "編輯盒子")
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body", [
                    m('.container-fluid', [
                        m('.row', [
                            m('.col-12.pb-3',
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.title = e.target.value
                                    },
                                    value: this.model.title,
                                    type: 'text',
                                    label: "盒子主題",
                                    required: true,
                                    tips: "盒子名稱",
                                    validate: () => {
                                        this.model.valid('title')
                                    },
                                    error: this.model.error('title'),
                                })
                            ),
                            m('.col-12.pb-3',
                                m(TextArea, {
                                    oninput: (e) => {
                                        this.model.subtitle = e.target.value
                                    },
                                    value: this.model.subtitle,
                                    type: 'text',
                                    label: "備註",
                                    required: true,
                                    tips: "此盒子備註",
                                })
                            ),
                            m('.col-12.pb-5', [
                                this.model.articles.map((_article, index) => {
                                    return [
                                        m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                            style: {
                                                // fontSize: "85%",
                                                fontWeight: 700,
                                                lineHeight: 1,
                                                backgroundColor: (index % 2 == 0) ? "#f2f2f2" : "#FFF"
                                            }
                                        }, [
                                            m(".col-1",
                                                m("span", index + 1)
                                            ),
                                            m(".col-3",
                                                m("span", _article.model_name)
                                            ),
                                            m(".col-7.d-flex.justify-content-center.align-items-center",
                                                m("p.m-0",{
                                                    style:{
                                                        overflow: "hidden",
                                                        "white-space": "nowrap",
                                                        "text-overflow": "ellipsis",

                                                    }
                                                }, _article.content)

                                            )],
                                            m(".col-1",
                                                m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                    style: {
                                                        backgroundColor:"#FF0000",
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        swal.fire({
                                                            title: '移除確認',
                                                            text: "請確認是否將文章移出您的盒子!",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Yes!',
                                                            cancelButtonText: 'No!',
                                                            reverseButtons: true
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                UserBox.removeRecord(_article.id).then((_response) => {
                                                                    this.model.articles.splice(index, 1)
                                                                })
                                                            }
                                                        })
                                                    }
                                                }, m("i.fa.fa-trash.text-light")),
                                            )
                                        )
                                    ]
                                })
                            ]),
                            m('.w-100'),
                        ])
                    ])
                ]),
                m('.modal-footer', [
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#000000",
                            borderRadius: "25px",
                            border: "2px solid #000000",
                            fontWeight: 700,
                            fontSize: "1.25rem",
                            cursor: "pointer",
                        },
                        onclick: (e) => {
                            e.preventDefault()
                            if (this.model.validate()) {
                                return false
                            }
                            this.save()
                        }
                    }, '儲存'),
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            fontWeight: 700,
                            fontSize: "1.25rem",
                            cursor: "pointer",
                        },
                        onclick: this.close.bind(this)
                    }, '關閉')
                ])
            ])
        ])
    }
}

export default BoxEditDialog
import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea
} from 'components'
import moment from 'moment'
import classNames from 'classnames'


class StepEditDialog {
    constructor(vnode) {
        this.score_section_options = [
            { key: "分數", datas: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }
        ]
        this.style_section_options = [
            { key: "經濟、科學、商業風格", datas: ['商業寫作', '科普寫作', '經濟寫作', '農產品風格', '採訪風格',] },
            { key: "文學風格", datas: ['敘事寫作', '歷史故事風格', '個人隨筆', '日記體風格', '文青風格', '佛教風格', '基督教風格',] },
            { key: "旅遊相關", datas: ['旅行日記', '紀實攝影寫作', '旅遊寫作', '探險旅行', '旅遊指南'] },
            { key: "靈性與自然", datas: ['靈性寫作', '自然寫作'] },
            { key: "飲食風格", datas: ['食品和烹飪寫作', '美食寫作風格'] },
            { key: "運動", datas: ['運動寫作'] },
            { key: "教育與家庭關係文學", datas: ['親子寫作', '兒童文學', '教育寫作', '育兒寫作'] },
            { key: "輕鬆生活", datas: ['幽默', '時尚風格', '社交媒體風格', '寫作指南', '勵志寫作', '網紅風格', '愛美風格'] },
            { key: "健康", datas: ['健康寫作'] },
            { key: "其他", datas: ['指南風格', '日記風格', '敘事風格', '解惑寫作風格'] },
        ]
        this.options = vnode.attrs.options
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return m('.modal-dialog.modal-xxl', [
            m('.modal-content', [
                m('.modal-header', [
                    m('h5.modal-title', [
                        m('span', "選項設定")
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body", [
                    m('.row.w-100.m-0', [
                        m(".col-12.px-2", [
                            m("span", {
                                style: {
                                    fontSize: "1.2rem",
                                    fontWeight: 700,
                                }
                            }, "預設選項"),
                        ]),
                        m(".col-12.py-2.px-0", [
                            m("span.py-0.px-3", {
                                style: {
                                    color: "#234B74",
                                    borderRadius: "25px",
                                    border: "1px solid #234B74",
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.options(this.score_section_options)
                                }
                            }, "分數(1-10)"),
                            m(".px-2.d-inline"),
                            m("span.py-0.px-3", {
                                style: {
                                    color: "#234B74",
                                    borderRadius: "25px",
                                    border: "1px solid #234B74",
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.options(this.style_section_options)
                                }
                            }, "文章風格"),
                        ]),
                        m(".col-12.px-0", {
                            style: {
                                background: "#EBEBEB",
                                height: "2px"
                            }
                        }),

                        m(".col-12.pt-3.pb-2.px-0", [
                            m("span.py-0.px-3", {
                                style: {
                                    color: "#234B74",
                                    borderRadius: "25px",
                                    border: "1px solid #234B74",
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.options().push({
                                        key: null,
                                        datas: []
                                    })
                                }
                            }, "新增總類"),
                        ]),

                        this.options().map((_options, index) => {
                            return m(".px-0", {
                                class: classNames({
                                    "col": this.options().length > 4,
                                    "col-3": this.options().length <= 4,
                                })
                            }, [
                                m(".row.w-100.m-0", [
                                    m(".col-12", [
                                        m('label.mb-1.pl-1.d-flex.justify-content-between', {
                                            style: {
                                                "font-weight": 700
                                            }
                                        },
                                            m("span", "主分類"),
                                            m("i.fa.fa-trash-o.py-1", {
                                                style: {
                                                    fontSize: "1.2rem",
                                                    color: "#C63333"
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    Swal.fire({
                                                        title: "你確定要刪除此選項？",
                                                        // text: "注意：此流程刪除後，該流程所附屬咒語及問題皆會消失！",
                                                        showCancelButton: true,
                                                        confirmButtonText: "確認",
                                                        cancelButtonText: "取消",
                                                        icon: "warning"

                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            this.options().splice(index, 1)
                                                            m.redraw()
                                                        }
                                                    });
                                                }
                                            }),
                                        ),
                                        m('input.form-control', {
                                            oninput: (e) => {
                                                _options.key = e.target.value
                                            },
                                            value: _options.key
                                        }),
                                    ]),
                                    m(".col-12.py-2", [
                                        m(".col-12.py-1.d-flex.justify-content-center", {
                                            style: {
                                                backgroundColor: "#A8DCF0",
                                                borderRadius: "25px"
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()
                                                _options.datas.push({
                                                    gpt: "",
                                                    value: "",
                                                })
                                            }
                                        }, [
                                            m("span", {
                                                style: {
                                                    fontSize: "1rem",
                                                    fontWeight: 700,
                                                }
                                            }, "選項"),
                                            m("span.text-primary.pl-3", {

                                            }, m("i.fa.fa-plus-circle", {
                                                style: {
                                                    fontSize: "1rem"
                                                },
                                            }),
                                            ),
                                        ]),
                                    ]),
                                    _options.datas.map((_data, _index) => {
                                        return [
                                            m(".col-12", [
                                                m('label.mb-1.pl-1.d-flex.justify-content-between', {
                                                    style: {
                                                        "font-weight": 700
                                                    }
                                                },
                                                    m("span", (_index + 1) + "."),
                                                    m("i.fa.fa-trash-o.py-1", {
                                                        style: {
                                                            fontSize: "1.2rem",
                                                            color: "#C63333"
                                                        },
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            Swal.fire({
                                                                title: "你確定要刪除此選項？",
                                                                // text: "注意：此流程刪除後，該流程所附屬咒語及問題皆會消失！",
                                                                showCancelButton: true,
                                                                confirmButtonText: "確認",
                                                                cancelButtonText: "取消",
                                                                icon: "warning"

                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    this.options()[index]['datas'].splice(_index, 1)
                                                                    m.redraw()
                                                                }
                                                            });
                                                        }
                                                    }),
                                                ),


                                            ]),
                                            m(".col-6.px-0", [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.options()[index]['datas'][_index].value = e.target.value
                                                    },
                                                    value: this.options()[index]['datas'][_index].value,
                                                    type: 'text',
                                                    label: "使用者選項",
                                                }),
                                            ]),
                                            m(".col-6.px-0", [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.options()[index]['datas'][_index].gpt = e.target.value
                                                    },
                                                    value: this.options()[index]['datas'][_index].gpt,
                                                    type: 'text',
                                                    label: "GPT用",
                                                }),
                                            ]),
                                        ]
                                    }),
                                ])
                            ])
                        }),
                    ])
                ]),
            ])
        ])
    }
}

export default StepEditDialog
import m from 'mithril'
import stream from 'mithril/stream'
import classNames from 'classnames';
import {
    GoHeader,
    ResultBox,
    QuestionBox
} from 'components'
import {
    FBPost,
    Setting,
} from 'app/models'


export default class DemoPage {
    constructor() {
        this.model = new FBPost()
        this.types = []
        this.gpt_response = []
        this.step = stream(0)
        this.articles = null
        this.models = []

        this._user_article_end = null
        this.temp_function = null
        this.loading = true

        this.questions = []
        this.expend = true
        this.select_question = 0
        this.select_user_options = []
        this.regen_start = false
        this.record_status = stream(null)

        this.select_type = 0
        Setting.getDemoModel().then((_response) => {
            this.models = _response
            this.types = _response
            this.loading = false
        })
    }

    view() {
        return [
            //滑至頁面最上方
            m(GoHeader),

            //Content
            m(".postition-relative", {
                class: classNames({
                    container: !window.isMobile
                }),
                style: {
                    paddingLeft: (!window.isMobile) ? "10%" : "2px",
                    paddingRight: (!window.isMobile) ? "10%" : "2px",
                }
            }, [
                m(".row.w-100.m-0", [
                    //MENU 我的最愛
                    m(".col-12.pb-1.pt-4", [
                        m("img.w-100", {
                            style: {
                                maxHeight: "150px",
                            },
                            src: window.BASE_URL + "/img/bar.png",
                            onclick:(e)=>{
                                e.preventDefault
                                window.location.href = "https://robot.apeai.com.tw/"
                            }
                        }),
                        m(".w-100.pt-4", {
                            style: {
                                "overflow-x": "auto",
                                "white-space": "nowrap",
                                "webkit-overflow-scrolling": "touch",
                                "-ms-overflow-style": "none",
                                "scrollbar-width": (!window.isMobile) ? "auto" : "none",
                                "-ms-overflow-style": "-ms-autohiding-scrollbar",
                            }
                        }, [
                            this.models.map((_data, index) => {
                                return m(".d-inline-block.px-2", {
                                    style: {
                                        cursor: "pointer",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.loading = true
                                        this.model = new FBPost()
                                        m.redraw()
                                        this.questions = []
                                        this.select_type = index
                                        this.step(0)
                                        this.gpt_response = []
                                        this._user_article_end = null
                                        this.loading = false
                                        m.redraw()
                                    },

                                }, [
                                    m(`i${(_data.icon == null) ? ".fa.fa-user" : "." + _data.icon}.text-center.w-100.py-1`, {
                                        style: {
                                            fontSize: "2rem",
                                            color: (this.select_type == index) ? "#000000" : "#7C7C7C",
                                        },
                                    }),
                                    m(".pb-1.text-center", {
                                        style: {
                                            fontSize: "1rem",
                                            minWidth: "80px",
                                            color: (this.select_type == index) ? "#000000" : "#7C7C7C",
                                            fontWeight: (this.select_type == index) ? 700 : 400,
                                        },

                                    }, (this.select_type == index) ? "【" + _data.name + "】" : _data.name),
                                    (this.select_type == index) ?
                                        m(".w-100.px-3", [
                                            m(".w-100.px-1", {
                                                style: {
                                                    backgroundColor: "#000000",
                                                    minHeight: "2px"
                                                }
                                            },)
                                        ]) : ""
                                ])
                            }),
                        ])
                    ]),
                    //Content
                    (this.loading == false && this.select_type != null) ? [
                        //標題
                        m(".col-12.pb-0.pt-4.position-relative", [
                            m("h4.text-center", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.loading = true
                                    m.redraw()
                                    this.questions = []
                                    this.gpt_response = []
                                    this.step(0)
                                    this._user_article_end = null
                                    this.loading = false
                                    m.redraw()
                                }
                            },
                                this.types[this.select_type].name
                            ),
                            (this.types[this.select_type].subtitle != "" && this.types[this.select_type].subtitle != null) ? [
                                m("h5.text-center.py-3", {
                                    style: {
                                        fontSize: "0.9rem",
                                        color: "#7B7B7B"
                                    }
                                },
                                    m.trust(this.types[this.select_type].subtitle.replace(/ /g, "&nbsp;").replace(/\n/g, "<br>").replace(/\t/g, "&nbsp;&nbsp;"))
                                )] : "",

                            m("span.px-2.position-absolute", {
                                style: {
                                    top: "10px",
                                    right: "10px",
                                    fontWeight: 600,
                                    fontSize: "0.9rem",
                                    color: "#B6B6B6",
                                    borderRadius: "25px",
                                    border: "2px solid #B6B6B6",
                                },
                                onclick: (e) => {
                                    let shareData = {
                                        title: "APE GPT" + this.types[this.select_type].name,
                                        text: "快和朋友一起來玩玩看吧！",
                                        url: "https://liff.line.me/2004293808-R16vpjpV?id=" + this.types[this.select_type].id,
                                    }
                                    navigator.share(shareData)
                                }
                            }, m("i.fa-solid.fa-arrow-up-from-bracket.py-1", {
                                style: {
                                    fontSize: "1.5rem",
                                    color: "#000000",
                                },
                            })),

                        ]),
                        //查看效果
                        m(".col-6.pb-0.pt-1.pb-3.text-left", [
                            m("span.px-2", {
                                style: {
                                    fontWeight: 600,
                                    fontSize: "0.9rem",
                                    color: "#B6B6B6",
                                    borderRadius: "25px",
                                    border: "2px solid #B6B6B6",
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    Swal.fire({
                                        title: "預覽效果",
                                        html: "<div class='text-left'>" + ((this.types[this.select_type].preview != null) ? this.types[this.select_type].preview.replace(/ /g, "&nbsp;").replace(/\n/g, "<br>").replace(/\t/g, "&nbsp;&nbsp;") : "") + "</div>",
                                        confirmButtonText: "關閉"
                                    });
                                }
                            },
                                "範例效果"
                            )
                        ]),

                        //使用者選單
                        (this.types[this.select_type].steps[this.step()].x == "view") ? [
                            this.types[this.select_type].steps[this.step()].s.map((_question) => {
                                return m(QuestionBox, {
                                    question: _question,
                                    model: this.model,
                                    question_type: "production",
                                    record_status: this.record_status,
                                });
                            }),
                            //圖片產出比例
                            this.types[this.select_type].steps.map((_step) => {
                                if (_step.x == "BlackForestLab") {
                                    return [
                                        m(".col-12.px-0.pt-2", [
                                            m("label.text-dark.mb-1.pl-2", {
                                                style: {
                                                    "font-weight": 700
                                                }
                                            }, "圖片產出比例"),
                                        ]),
                                        m('.col-12.pb-3', [
                                            m(".form-group.m-0", [
                                                m("select.form-control", {
                                                    onchange: (e) => {
                                                        this.model['BlackForestLab_Aspect_ratios'] = e.target.value;
                                                    },
                                                    oncreate: (e) => {
                                                        this.model['BlackForestLab_Aspect_ratios'] = _step.o.aspect_ratio;
                                                        m.redraw();
                                                    }
                                                }, [
                                                    m("option[disabled]", {
                                                        value: null,
                                                    }, "請選擇圖片比例"),
                                                    ['1:1', '16:9', '2:3', '3:2', '4:5', '5:4', '9:16'].map((_item) => {
                                                        return m("option", {
                                                            value: _item,
                                                            selected: this.model['BlackForestLab_Aspect_ratios'] == _item
                                                        }, _item);
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]
                                }
                            }),

                        ] : "",
                        //GPT 選單
                        (this.types[this.select_type].steps[this.step()].x == "view_suggest" || this.step() >= 1) ? [
                            (this.gpt_response && this.gpt_response.length > 0) ?
                                m(".row.w-100.m-0[id='focus_suggest']", [
                                    this.gpt_response.map((_question, index) => {
                                        return m(".col-12", [
                                            m(".row.w-100.m-0.pb-3", [
                                                m(".col-12.pb-1.d-flex.justify-content-between",
                                                    m("span", {
                                                        style: {
                                                            fontWeight: 600,
                                                            fontSize: "1.2rem"
                                                        }
                                                    }, index + 1 + ". " + _question.key),
                                                    //Regenerate
                                                    m("i.fa.fa-refresh", {
                                                        style: {
                                                            fontSize: "1.75rem"
                                                        },
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            this.select_question = index
                                                            FBPost.sendToGPTForDemo(this.model, this.questions, this.types[this.select_type].id, _question.step, this.gpt_response, this.select_user_options).then((_response) => {
                                                                if (_response.hasOwnProperty('status') && !_response.status) {
                                                                    swal.fire({
                                                                        title: _response.message,
                                                                        // text: "請確認產品或服務名字!",
                                                                        icon: "error",
                                                                        showCancelButton: true,
                                                                        showConfirmButton: false,
                                                                        timer: 5000
                                                                    });
                                                                    return
                                                                }
                                                                JSON.parse(_response[0].choices[0].message.content).options.map((_question, index) => {
                                                                    Object.keys(_question).map((_anser) => {
                                                                        let _find_index = this.gpt_response.map(e => e.key).indexOf(_anser);
                                                                        if (_find_index > -1 && this.select_question == _find_index) {
                                                                            this.gpt_response[_find_index].options = _question[_anser]
                                                                            this.gpt_response[_find_index].answer = []
                                                                        }
                                                                    })
                                                                })
                                                                m.redraw()
                                                            })
                                                        }
                                                    }),
                                                ),
                                                //建議選項
                                                _question.options.map((_option) => {
                                                    return m(".col-12.p-1",
                                                        m(".w-100.text-center.py-2.px-2", {
                                                            style: {
                                                                color: (_question.answer.includes(_option)) ? "#000000" : "rgb(109 109 109)",
                                                                "white-space": "normal",
                                                                border: "1px solid #ced4da",
                                                                fontWeight: (_question.answer.includes(_option)) ? 700 : 400,
                                                                backgroundColor: (_question.answer.includes(_option)) ? "#ced4da" : "#FFFFFF",
                                                                borderRadius: "5px"
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                if (_question.answer.includes(_option)) {
                                                                    _question.answer.splice(_question.answer.indexOf(_option), 1)
                                                                } else {
                                                                    _question.answer.push(_option)
                                                                }
                                                                m.redraw()
                                                            }
                                                        }, m.trust(_option.replace(/ /g, "&nbsp;").replace(/\n/g, "<br>").replace(/\t/g, "&nbsp;&nbsp;")))
                                                    )
                                                }),
                                                //原本寫的答案
                                                (this.model.hasOwnProperty(_question.key)) ? [
                                                    m(".col-12.p-1",
                                                        m(".w-100.text-center.py-2.px-2", {
                                                            style: {
                                                                "white-space": "normal",
                                                                color: (_question.answer.includes(this.model[this.model[_question.key]])) ? "#000000" : "rgb(109 109 109)",
                                                                "white-space": "normal",
                                                                border: "1px solid #ced4da",
                                                                fontWeight: (_question.answer.includes(this.model[this.model[_question.key]])) ? 700 : 400,
                                                                backgroundColor: (_question.answer.includes(this.model[this.model[_question.key]])) ? "#ced4da" : "#FFFFFF",
                                                                borderRadius: "5px"
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                if (_question.answer.includes(this.model[this.model[_question.key]])) {
                                                                    _question.answer.splice(_question.answer.indexOf(this.model[this.model[_question.key]]), 1)
                                                                } else {
                                                                    _question.answer.push(this.model[this.model[_question.key]])
                                                                }
                                                            }
                                                        }, this.model[this.model[_question.key]]
                                                        )
                                                    )
                                                ] : ""
                                            ]),
                                        ])
                                    })
                                ]) : "",
                        ] : "",
                        //結果查看
                        ((this.types[this.select_type].steps[this.step()].x == "view_article" || this.types[this.select_type].steps[this.step()].x == "view_photo") && this.regen_start == false) ? [
                            m(ResultBox, {
                                articles: this.articles,
                                step_type: this.types[this.select_type].steps[this.step()].x,

                                result_type: "demo",
                                goBack: (e) => {
                                    e.preventDefault()
                                    this.articles = null
                                    this.questions = []
                                    this.step(0)
                                    m.redraw()
                                },
                                regenerate: (e) => {
                                    e.preventDefault()
                                    this.regen_start = true
                                    let _temp_step = 2
                                    if (this.types[this.select_type].steps[this.step() - 2]['x'] == 'dgpt') {
                                        _temp_step = 3
                                    }
                                    FBPost.sendToGPTForDemo(this.model, this.questions, this.types[this.select_type].id, this.step() - _temp_step, this.gpt_response, this.select_user_options).then((_response) => {
                                        if (_response.hasOwnProperty('status') && !_response.status) {
                                            swal.fire({
                                                title: _response.message,
                                                // text: "請確認產品或服務名字!",
                                                icon: "error",
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                timer: 5800
                                            });
                                            return
                                        }
                                        if (this.types[this.select_type].steps[this.step()].x == "view_suggest") {
                                            JSON.parse(_response[0].choices[0].message.content).options.map((_question, index) => {
                                                Object.keys(_question).map((_anser) => {
                                                    this.gpt_response.push({
                                                        key: _anser,
                                                        options: _question[_anser],
                                                        answer: [],
                                                        step: this.step() - 2
                                                    })
                                                })
                                            })
                                            m.redraw()
                                            const $scroll = $('#focus_suggest');

                                            if ($scroll.length) {
                                                $('html,body').animate({
                                                    scrollTop: $scroll.offset().top - 150
                                                }, 'fast');
                                            }
                                        } else if (this.types[this.select_type].steps[this.step()].x == "view_article" || this.types[this.select_type].steps[this.step()].x == "view_photo") {
                                            this.articles = null
                                            m.redraw()
                                            this.articles = _response[3]
                                            m.redraw()
                                            const $scroll = $('#focus');

                                            if ($scroll.length) {
                                                $('html,body').animate({
                                                    scrollTop: $scroll.offset().top - 100
                                                }, 'fast');
                                            }
                                        }
                                        this.regen_start = false
                                        m.redraw()
                                    })
                                }
                            }),

                        ] : "",
                        //下一步
                        (!(this.types[this.select_type].steps[this.step()].x == "view_article" || this.types[this.select_type].steps[this.step()].x == "view_photo")) ? [
                            m('.col-12.px-2.pt-3',
                                m(".w-100.text-center.py-2", {
                                    style: {
                                        color: "#000000",
                                        borderRadius: "25px",
                                        border: "2px solid #000000",
                                        fontWeight: 700,
                                        cursor: "pointer",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        let _check = false
                                        let _message = "請填寫"
                                        if (this.types[this.select_type].steps[this.step()].x == "view") {
                                            this.types[this.select_type].steps[this.step()].s.map((_question) => {
                                                if (_question['required'] && !this.model.hasOwnProperty(_question['variable'])) {
                                                    _check = true
                                                    _message += _question['question'] + "、"
                                                }
                                            })
                                        } else if (this.types[this.select_type].steps[this.step()].x == "suggest") {
                                            this.gpt_response.map((_question, index) => {
                                                if (_question.answer == null) {
                                                    _check = true
                                                }
                                            })
                                        }
                                        this.temp_function = false
                                        if (this.record_status() == "recording") {
                                            this.temp_function = true
                                            var event = new CustomEvent("customer_recording");
                                            document.dispatchEvent(event);
                                            document.addEventListener("customer_recording_done", (e) => {
                                                this.temp_function = false
                                            })
                                        }
                                        let checkwait = () => {
                                            if (!this.temp_function) {
                                                if (_check) {
                                                    swal.fire({
                                                        title: _message,
                                                        icon: "error",
                                                        showCancelButton: false,
                                                        showConfirmButton: false,
                                                        timer: 1800
                                                    });
                                                }
                                                else {
                                                    if (this.types[this.select_type].steps[this.step()].x == "view") {
                                                        this.types[this.select_type].steps[this.step()].s.map((_temp) => {
                                                            this.questions.push(_temp)
                                                        })
                                                    }
                                                    if (this.types[this.select_type].steps[this.step() + 1].g) {
                                                        FBPost.sendToGPTForDemo(this.model, this.questions, this.types[this.select_type].id, this.step(), this.gpt_response, this.select_user_options).then((_response) => {
                                                            while (this.types[this.select_type].steps[this.step() + 1]['x'] == 'dgpt') {
                                                                this.step(this.step() + 1)
                                                            }
                                                            this.step(this.step() + 2)

                                                            if (_response.hasOwnProperty('status') && !_response.status) {
                                                                swal.fire({
                                                                    title: _response.message,
                                                                    // text: "請確認產品或服務名字!",
                                                                    icon: "error",
                                                                    showCancelButton: false,
                                                                    showConfirmButton: false,
                                                                    timer: 5800
                                                                });
                                                                return
                                                            }
                                                            if (this.types[this.select_type].steps[this.step()].x == "view_suggest") {
                                                                JSON.parse(_response[0].choices[0].message.content).options.map((_question, index) => {
                                                                    Object.keys(_question).map((_anser) => {
                                                                        this.gpt_response.push({
                                                                            key: _anser,
                                                                            options: _question[_anser],
                                                                            answer: [],
                                                                            step: this.step() - 2
                                                                        })
                                                                    })
                                                                })
                                                                m.redraw()
                                                                const $scroll = $('#focus_suggest');

                                                                if ($scroll.length) {
                                                                    $('html,body').animate({
                                                                        scrollTop: $scroll.offset().top - 150
                                                                    }, 'fast');
                                                                }
                                                            } else if (this.types[this.select_type].steps[this.step()].x == "view_article" || this.types[this.select_type].steps[this.step()].x == "view_photo") {
                                                                this.articles = _response[3]
                                                                this._user_article_end = (_response[0].hasOwnProperty('choices')) ? _response[0].choices : _response[0].data
                                                                this.expend = false
                                                                m.redraw()

                                                                const $scroll = $('#focus');

                                                                if ($scroll.length) {
                                                                    $('html,body').animate({
                                                                        scrollTop: $scroll.offset().top - 100
                                                                    }, 'fast');
                                                                }
                                                            }
                                                        })
                                                    } else {
                                                        this.step(this.step() + 1)
                                                    }
                                                }
                                            }
                                            else {
                                                setTimeout(checkwait, 1000); // check again in a second
                                            }
                                        }
                                        checkwait()
                                    }
                                },
                                    [
                                        m("span", "下一步")
                                    ])
                            ),
                        ] : "",
                        //Loading
                        (this.regen_start == true) ? [
                            m(".col-12.d-flex.justify-content-center.align-items-center", {
                                style: {
                                    minHeight: "50vh",
                                    // paddingTop:"7rem"
                                }
                            }, [
                                m(".h-100.w-100.d-flex.justify-content-center.align-items-center.position-fixed", [
                                    m("span.loader")
                                ])
                            ]),
                        ] : "",
                    ] : ""
                ]),
            ]),
            m(".py-5"),
        ]
    }
}
import m from 'mithril'
import {
    Button,
    Dialog,
} from 'components'
import QuestionComponent from './dialogs/question_edit'
import PreviewComponent from './dialogs/preview'
import StepEditDialog from './dialogs/step_edit'
import Uploader from 'components/uploader/core'
import {
    Question,
    Model,
} from 'app/models'
import classNames from 'classnames'

export default class SettingDetailPage {
    constructor() {
        this.uploader = new Uploader()
        this.answer_type = [
            { name: "簡答題", key: "TextBox" },
            { name: "詳答題", key: "TextArea" },
            { name: "多選題", key: "CheckBox" },
            { name: "下拉式選單", key: "Select" },
            { name: "選擇題", key: "Radio" },
            { name: "圖片", key: "Photo" },
            { name: "圖片選擇", key: "PhotoSelect" },
            // { name: "Excel", key: "Excel" },
            // { name: "Word", key: "Word" },
            // { name: "PDF", key: "PDF" },
            // { name: "PDF", key: "PDF" },
            { name: "文件上傳", key: "DATA" },
            { name: "顏色選擇", key: "ColorSelect" },
            { name: "錄音", key: "Recording" },
            { name: "圖轉文", key: "GoogleTransferIamge" },
            { name: "上傳影音", key: "MP4Upload" },
            { name: "Dell-E2", key: "DallE2" },
            { name: "BlackForestLab", key: "BlackForestLab" },
            { name: "套版", key: "Template" },
            { name: "Md轉Excel", key: "Markdown2Excel" },

        ]
        this.aspect_ratios = {
            '選擇圖片比例': {
                '1:1': '1:1',
                '16:9': '16:9',
                '2:3': '2:3',
                '3:2': '3:2',
                '4:5': '4:5',
                '5:4': '5:4',
                '9:16': '9:16',
            }
        }
        this.gpt_models = {
            '圖像生成模型': {
                'dall-e-3': 'dall-e-3',
                'dall-e-2': 'dall-e-2'
            },
            // '語音識別與生成模型': {
            //     'whisper-1': 'whisper-1',
            //     'tts-1-hd-1106': 'tts-1-hd-1106',
            //     'tts-1-hd': 'tts-1-hd',
            //     'tts-1-1106': 'tts-1-1106',
            //     'tts-1': 'tts-1'
            // },
            'GPT-4 系列模型': {
                'gpt-4-turbo-2024-04-09': 'gpt-4-turbo-2024-04-09',
                'gpt-4-1106-preview': 'gpt-4-1106-preview',
                'gpt-4o-mini': 'gpt-4o-mini',
                'gpt-4o-mini-2024-07-18': 'gpt-4o-mini-2024-07-18',
                'gpt-4-turbo': 'gpt-4-turbo',
                'gpt-4-turbo-preview': 'gpt-4-turbo-preview',
                'gpt-4-0125-preview': 'gpt-4-0125-preview',
                'gpt-4o-2024-05-13': 'gpt-4o-2024-05-13',
                'gpt-4-0613': 'gpt-4-0613',
                'gpt-4o': 'gpt-4o',
                'gpt-4': 'gpt-4'
            },
            'GPT-3.5 系列模型': {
                'gpt-3.5-turbo-0613': 'gpt-3.5-turbo-0613',
                'gpt-3.5-turbo-0301': 'gpt-3.5-turbo-0301',
                'gpt-3.5-turbo': 'gpt-3.5-turbo',
                'gpt-3.5-turbo-instruct': 'gpt-3.5-turbo-instruct',
                'gpt-3.5-turbo-instruct-0914': 'gpt-3.5-turbo-instruct-0914',
                'gpt-3.5-turbo-16k': 'gpt-3.5-turbo-16k',
                'gpt-3.5-turbo-16k-0613': 'gpt-3.5-turbo-16k-0613',
                'gpt-3.5-turbo-0125': 'gpt-3.5-turbo-0125',
                'gpt-3.5-turbo-1106': 'gpt-3.5-turbo-1106'
            },
            // '文本嵌入模型': {
            //     'text-embedding-3-small': 'text-embedding-3-small',
            //     'text-embedding-3-large': 'text-embedding-3-large',
            //     'text-embedding-ada-002': 'text-embedding-ada-002'
            // },
            // 'davinci 系列模型': {
            //     'babbage-002': 'babbage-002',
            //     'davinci-002': 'davinci-002'
            // }
        }
        this.loading = true
        this.id = m.route.param('id')
        this.model = null
        Model.load(this.id).then((_response) => {
            this.model = new Model(_response)
            this.sentance_models = [
                { display: "當我{x}[y]的時候，y表示我要對x作出的限制，", value: this.insert_text.bind(this) },
                { display: "你是:" + this.model.role + "，你的任務：" + this.model.rule + "，", value: this.insert_text.bind(this) },
                { display: "{插入所有參數}", value: this.insert_variable.bind(this) },
                { display: "###先寫下特色開頭，不要有“特色開頭”這四個字，並用emoji or []標記，並加粗，如果基本資訊有URL，貼文裡面就需要有。有關促銷的資訊要盡量往前放，且促銷相關資訊可以用加粗或是emoji的方式特別強調，貼文要主次分明，且每段都有想強調的主題以及基本資訊中的文章風格，段落之間互相呼應。 Please think step by step but don't need to show me yourthought. 貼文要包含所有有填寫的基本資訊，請用繁體中文，不要用簡體中文###", value: this.insert_text.bind(this) },
            ]
            if (this.model.steps.length == 0) {
                this.model.steps.push({
                    a: 1,
                    f: "使用者填入表單",
                    x: "view",
                    g: false,
                    t: false,
                    ug: "gpt-4o-mini",
                    s: []
                })
            }
            this.loading = false
            this.model.steps.map((_step) => {
                if (!_step.hasOwnProperty("ug")) {
                    _step.ug = "gpt-4o-mini"
                }
            })
        })
        this.spell_models = [
            { display: "FB貼文範例", value: this.fb_post.bind(this) },
            { display: "通知範例", value: this.alert_post.bind(this) },
        ]
        this.sentance_models = []
        this.questions = []
        this.temp_mouse_linsten = []

        this.spell_menu = false
        this.spell_clientX = 0
        this.spell_clientY = 0
        this.forcus = null

        this.user_spell_open = false

        this.system_spell_index = null
        this.user_question_index = null

        this.open_admin_control = false

    }
    insert_text(index, value) {
        if (index) {
            this.model.steps[this.system_spell_index].s.splice(index + 1, 0, {
                t: "T",
                v: value,
            })
        } else {
            this.model.steps[this.system_spell_index].s.push({ t: "T", v: value })
        }
        m.redraw()
    }
    insert_variable(index) {
        if (index) {
            this.model.questions.map((_question, _index) => {
                this.model.steps[this.system_spell_index].s.splice(index + 1, 0, { t: "T", v: (_index + 1) + ".", }, { t: "T", v: _question['question'] }, { t: "T", v: "</br>" })
            })
        } else {
            this.model.questions.map((_question, _index) => {
                this.model.steps[this.system_spell_index].s.push({ t: "T", v: (_index + 1) + "." })
                this.model.steps[this.system_spell_index].s.push({ t: "T", v: _question['question'] })
                this.model.steps[this.system_spell_index].s.push({ t: "T", v: "</br>" })
            })
        }
        m.redraw()
    }
    //FB貼文咒語
    fb_post() {
        this.model.steps[this.system_spell_index].s = []
        this.model.steps[this.system_spell_index].s.push({
            t: "T",
            v: "當我{x}[y]的時候，y表示我要對x作出的限制，你是:" + this.model.role + "，你的任務：" + this.model.rule + "，基本資訊:{\n"
        })
        this.model.steps[this.system_spell_index - 1].s.map((_question, _index) => {
            if (_question['isAdvice']) {
                this.model.steps[this.system_spell_index].s[0].v += (_index + 1) + "." + "{" + _question['title'] + "}[" + _question['adviceComment'] + "]\n"
            } else {
                this.model.steps[this.system_spell_index].s[0].v += (_index + 1) + "." + _question['title'] + "\n"
            }
        })
        this.model.steps[this.system_spell_index].s[0].v += "}[如果有填寫的話貼文一定要有]\n"
        this.model.steps[this.system_spell_index].s[0].v += "###\n先寫下特色開頭，不要有“特色開頭”這四個字，並用emoji or []標記，並加粗，如果基本資訊有URL，貼文裡面就需要有。有關促銷的資訊要盡量往前放，且促銷相關資訊可以用加粗或是emoji的方式特別強調，貼文要主次分明，且每段都有想強調的主題以及基本資訊中的文章風格，段落之間互相呼應。\nPlease think step by step but don't need to show me your thought. \n貼文要包含所有有填寫的基本資訊，請用繁體中文，不要用簡體中文\n##"
        m.redraw()
    }
    //通知咒語
    alert_post() {
        this.model.steps[this.system_spell_index].s = []
        this.model.steps[this.system_spell_index].s.push({
            t: "T",
            v: "當我{x}[y]的時候，y表示我要對x作出的限制，你是:" + this.model.role + "，你的任務：" + this.model.rule +
                "user 會填寫以下基本資訊中某些項目的資訊，之後你要依據有填寫的項目並依據貼文結構寫出一篇通知貼文，注意只能依據有填寫的項目\n" + "，基本資訊:{\n"
        })
        this.model.steps[this.system_spell_index].s[0].v +=

            this.model.steps[this.system_spell_index - 1].s.map((_question, _index) => {
                if (_question['isAdvice']) {
                    this.model.steps[this.system_spell_index].s[0].v += (_index + 1) + "." + "{" + _question['title'] + "}[" + _question['adviceComment'] + "]\n"
                } else {
                    this.model.steps[this.system_spell_index].s[0].v += (_index + 1) + "." + _question['title'] + "\n"
                }
            })
        this.model.steps[this.system_spell_index].s[0].v += "\n}\n1. 先給一個特色開頭。\n2. 寫一個簡短的寒暄。\n3. 然後寫下通知的內容以及相關的資訊。\n4. 寫下祝福語。\n5. 之後再貼文末尾寫一些tag。\n}\n"
        this.model.steps[this.system_spell_index].s[0].v += "###\n1. 用繁體中文回答，不要用簡體中文\n2. 沒有填寫的基本資訊就不需要出現\n3. please think step by step but don’t give me your thoughts.only return your answer\n4. 如果有URL，要整段顯示，不要用hyperlink\n5. 直接給我答案即可，不用列出基本資訊的項目名稱，例如：不要出現「特色開頭」等字眼\n###"
        m.redraw()
    }

    changeDisplayName(value) {
        return this.answer_type[this.answer_type.map(e => e.key).indexOf(value)].name;
    }
    changeArticleDisplayName(value) {
        switch (value) {
            case "view":
                return "使用者輸入參數";
            case "article":
                return "產生GPT文章";
            case "photo":
                return "產生GPT圖片";
            case "suggest":
                return "產生GPT建議";
            case "view_suggest":
                return "使用者選擇建議";
            case "view_article":
                return "使用者查看結果";
            case "view_photo":
                return "使用者查看圖片";
            case "view_data":
                return "使用者查看檔案";
            case "dgpt":
                return "GPT結果->使用者內容";
            case "DallE2":
                return "產生DellE2圖片";
            case "BlackForestLab":
                return "產生BlackForestLab圖片";
            case "Template":
                return "套版咒語填寫";
            case "Markdown2Excel":
                return "Markdown轉Excel";
            default:
                return "Error";
        }
    }

    view(vnode) {
        return [
            (!this.loading) ? [
                //儲存模板
                m(".position-fixed", {
                    style: {
                        bottom: "30px",
                        right: "35px",
                        cursor: "pointer",
                        zIndex: 1024
                    },
                }, [
                    m("div.w-100.px-4.py-2", {
                        onclick: (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.model.save().then((_res) => {
                                Swal.fire({
                                    title: "儲存成功",
                                    text: this.model.name + " 模組已儲存",
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                            })
                        },
                        style: {
                            color: "#FFFFFF",
                            borderRadius: "25px",
                            border: "1px solid #0f2027",
                            fontWeight: 500,
                            fontSize: "1.25rem",
                            background: "#0f2027",
                            background: "-webkit-linear-gradient(to right, #0f2027, #203a43, #2c5364)",
                            background: "linear-gradient(to right, #0f2027, #203a43, #2c5364)",

                        }
                    }, "儲存全部"),
                ]),
                //返回
                m(".position-fixed", {
                    style: {
                        top: "20px",
                        right: "35px",
                        cursor: "pointer",
                        zIndex: 1024
                    },
                }, [
                    m(".col-12.text-center.d-flex.justify-content-end", [
                        m(".text-center.py-1.px-3", {
                            style: {
                                color: "#41BCC3",
                                borderRadius: "25px",
                                border: "2px solid #41BCC3",
                                fontWeight: 700,
                                fontSize: "1.25rem",
                                cursor: "pointer",
                            },
                            onclick: (e) => {
                                e.preventDefault()
                                m.route.set('/rebalanceSetting')
                            }
                        }, '返回')
                    ]),
                ]),

                m(".row.m-0.w-100.position-relative", {
                    style: {
                        minHeight: "100vh"
                    }
                }, [
                    m(".col-md-2.col-12.px-2.py-3.position-relative", {
                        style: {
                            borderRight: "2px solid #F9F9F9",
                            backgroundColor: "#F9F9F9"
                        }
                    }, [
                        //流程表
                        m(".row.w-100.m-0", [
                            m(".col-12", [
                                m("span", {
                                    style: {
                                        fontSize: "1.5rem",
                                        fontWeight: 800,
                                        // backgroundColor: "#E9C9C9",
                                        borderRadius: "25px"
                                    }
                                }, "流程表"),
                            ]),
                            this.model.steps.map((_step, _index) => {
                                return m(".col-12.p-1", {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        if (_step.x == "view_article" || _step.x == "view_photo" || _step.x == "view_data") {

                                        } else {
                                            this.system_spell_index = _index
                                        }

                                    }
                                }, [
                                    m(".row.w-100.m-0.pt-1.pb-2", {
                                        style: {
                                            borderRadius: "5px",
                                            border: (this.system_spell_index == _index) ? "2px solid #26ADFF" : ((_step.g) ? "1px solid #3AA4A0" : "1px solid #CDCDCD")
                                        }
                                    }, [
                                        m(".col-12.py-1.position-relative", [
                                            m("span.position-absolute", {
                                                style: {
                                                    fontSize: "1.25rem",
                                                    fontWeight: 700,
                                                    bottom: "10px",

                                                },
                                            }, _step.a),
                                            m("p.m-0.px-2.text-center", {
                                                style: {
                                                    fontSize: "1.25rem",
                                                    fontWeight: 500,
                                                    overflow: "hidden",
                                                    "white-space": "nowrap",
                                                    "text-overflow": "ellipsis",
                                                }
                                            }, _step.f)
                                        ]),
                                        m(".col-12.text-center", [
                                            m("span.text-muted", this.changeArticleDisplayName(_step.x))
                                        ]),
                                    ])
                                ])
                            }),


                            (this.model.steps.length >= 1 && this.model.steps[this.model.steps.length - 1]) ?
                                ((this.model.steps[this.model.steps.length - 1].x == "view_article" || this.model.steps[this.model.steps.length - 1].x == "view_photo" || this.model.steps[this.model.steps.length - 1].x == "view_data") ? [
                                    m(".col-12.pb-2.text-center.pt-3", [
                                        m("span", {
                                            style: {
                                                fontSize: "1.5rem",
                                                fontWeight: 800,
                                                color: "red"
                                            }
                                        }, "此模型已結束"),
                                    ]),
                                ] : "")
                                : "",
                            //新增步驟
                            m(".position-absolute", {
                                style: {
                                    bottom: "30px",
                                    right: "10px",
                                    cursor: "pointer",
                                }
                            }, [
                                m("span.text-primary", {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        Dialog.show(StepEditDialog, {
                                            didHide: (returnValue) => {
                                                if (returnValue) {
                                                    if (returnValue.type == "suggest") {
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: returnValue.f,
                                                            x: 'suggest',
                                                            t: returnValue.t,
                                                            ug: "gpt-4o-mini",
                                                            g: true,
                                                            s: [{ t: "T", v: "請修改咒語" }]
                                                        })
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: "使用者選擇建議",
                                                            x: 'view_suggest',
                                                            t: returnValue.t,
                                                            ug: "gpt-4o-mini",
                                                            g: false,
                                                            s: []
                                                        })

                                                    } else if (returnValue.type == "article") {
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: returnValue.f,
                                                            x: 'article',
                                                            g: true,
                                                            t: returnValue.t,
                                                            ug: "gpt-4o-mini",
                                                            s: [{ t: "T", v: "請修改咒語" }]
                                                        })
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: "使用者查看結果",
                                                            x: 'view_article',
                                                            g: false,
                                                            t: returnValue.t,
                                                            ug: "gpt-4o-mini",
                                                            s: []
                                                        })

                                                    } else if (returnValue.type == "photo") {
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: returnValue.f,
                                                            x: 'photo',
                                                            g: true,
                                                            t: returnValue.t,
                                                            o: returnValue.o,
                                                            ug: "dall-e-3",
                                                            s: [{ t: "T", v: "請修改咒語" }]
                                                        })
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: "使用者查看圖片",
                                                            x: 'view_photo',
                                                            g: false,
                                                            t: returnValue.t,
                                                            ug: "dall-e-3",
                                                            s: []
                                                        })
                                                    } else if (returnValue.type == "DallE2") {
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: returnValue.f,
                                                            x: 'DallE2',
                                                            g: true,
                                                            t: returnValue.t,
                                                            o: returnValue.o,
                                                            ug: "dall-e-2",
                                                            s: [{ image: null, mask: null }]
                                                        })
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: "使用者查看圖片",
                                                            x: 'view_photo',
                                                            g: false,
                                                            t: returnValue.t,
                                                            ug: "dall-e-2",
                                                            s: []
                                                        })
                                                    } else if (returnValue.type == "BlackForestLab") {
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: returnValue.f,
                                                            x: 'BlackForestLab',
                                                            g: true,
                                                            t: returnValue.t,
                                                            o: returnValue.o,
                                                            ug: "BlackForestLab",
                                                            s: [{ t: "T", v: "請修改咒語" }]
                                                        })
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: "使用者查看圖片",
                                                            x: 'view_photo',
                                                            g: false,
                                                            t: returnValue.t,
                                                            ug: "dall-e-2",
                                                            s: []
                                                        })
                                                    } else if (returnValue.type == "view") {
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: returnValue.f,
                                                            x: 'view',
                                                            g: false,
                                                            t: returnValue.t,
                                                            ug: "gpt-4o-mini",
                                                            s: []
                                                        })
                                                    } else if (returnValue.type == "word" || returnValue.type == "excel" || returnValue.type == "pdf" || returnValue.type == "Template") {
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: returnValue.f,
                                                            x: returnValue.type,
                                                            g: true,
                                                            t: returnValue.t,
                                                            ug: "gpt-4o-mini",
                                                            s: [{ t: "T", v: "請修改咒語" }]
                                                        })
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: "使用者查看檔案",
                                                            x: 'view_data',
                                                            g: false,
                                                            t: returnValue.t,
                                                            ug: "gpt-4o-mini",
                                                            s: []
                                                        })
                                                    } else if (returnValue.type == "dgpt") {
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: returnValue.f,
                                                            x: returnValue.type,
                                                            g: true,
                                                            t: returnValue.t,
                                                            oldData: false,
                                                            ug: "gpt-4o-mini",
                                                            s: [{ t: "T", v: "請修改咒語" }]
                                                        })
                                                    } else if (returnValue.type == "Markdown2Excel") {
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: returnValue.f,
                                                            x: returnValue.type,
                                                            g: true,
                                                            t: returnValue.t,
                                                            ug: "gpt-4o-mini",
                                                            s: [{ t: "T", v: "請修改咒語" }]
                                                        })
                                                        this.model.steps.push({
                                                            a: this.model.steps.length + 1,
                                                            f: "使用者查看Excel",
                                                            x: 'view_data',
                                                            g: false,
                                                            t: returnValue.t,
                                                            ug: "gpt-4o-mini",
                                                            s: []
                                                        })
                                                    }
                                                    m.redraw()
                                                }
                                            }
                                        })
                                    }
                                }, m("i.fa.fa-plus-circle", {
                                    style: {
                                        fontSize: "3rem",
                                        color: "#203a43"
                                    },
                                })
                                ),
                            ]),

                        ]),
                    ]),
                    m(".col-md-10.col-12.px-0", [
                        m("div.right-pane", [
                            //返回 主題
                            m(".row.w-100.m-0.pb-2", [
                                m(".col-12.text-center.pb-3", [
                                    m("span.font-weight-bold", {
                                        style: {
                                            fontSize: "1.75rem"
                                        },
                                        onclick: (e) => {
                                            e.preventDefault()
                                        }
                                    }, this.model.name)
                                ]),

                                //使用著問題
                                m(".col-md-6", [
                                    //Setting
                                    m(".col-md-12.px-0", [
                                        m(".row.w-100.m-0.position-relative", {
                                            style: {
                                                backgroundColor: "#FAFAFA",
                                                boxShadow: "5px 4px 3px #B0B0B2",
                                                borderRadius: "25px",
                                                border: "1px solid #B0B0B2"
                                            }
                                        }, [
                                            m(".col-12.text-center.py-2", [
                                                m("span", {
                                                    style: {
                                                        fontSize: "1.2rem",
                                                        fontWeight: 800
                                                    }
                                                }, "步驟設定"),
                                            ]),
                                            (this.system_spell_index != null) ? [
                                                //步驟
                                                m(".col-4.py-1", [
                                                    m(".w-100.text-center", {
                                                        style: {
                                                            color: "#5780C6",
                                                            borderRadius: "25px",
                                                            border: "2px solid #5780C6",
                                                            fontWeight: 700,
                                                            fontSize: "1rem",
                                                            cursor: "pointer",
                                                        },
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            Swal.fire({
                                                                title: "輸入交換的順序",
                                                                input: "text",
                                                                inputLabel: "輸入交換的順序",
                                                                inputValue: this.model.steps[this.system_spell_index].a,
                                                                showCancelButton: true,
                                                                inputValidator: (value) => {
                                                                    if (!Number.isInteger(parseInt(value))) {
                                                                        return "請輸入數字";
                                                                    }
                                                                    if (parseInt(value) > this.model.steps.length) {
                                                                        return "您所輸入的位置不存在";
                                                                    }
                                                                }
                                                            }).then((_response) => {
                                                                if (_response.isConfirmed) {
                                                                    const switchIndexes = (arr, i, j) => [arr[i], arr[j]] = [arr[j], arr[i]]
                                                                    let _change = parseInt(_response.value)
                                                                    switchIndexes(this.model.steps, this.system_spell_index, _change - 1)
                                                                    this.model.steps.map((_temp, index) => {
                                                                        _temp.a = index + 1
                                                                    })
                                                                    this.system_spell_index = parseInt(_response.value) - 1
                                                                }

                                                                m.redraw()
                                                            });
                                                        }
                                                    }, [
                                                        m("span", "步驟 - " + this.model.steps[this.system_spell_index].a)
                                                    ])
                                                ]),
                                                //步驟名稱
                                                m(".col-4.py-1", [
                                                    m(".w-100.text-center", {
                                                        style: {
                                                            color: "#5780C6",
                                                            borderRadius: "25px",
                                                            border: "2px solid #5780C6",
                                                            fontWeight: 700,
                                                            fontSize: "1rem",
                                                            cursor: "pointer",
                                                        },
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            Swal.fire({
                                                                input: "textarea",
                                                                inputLabel: "請輸入要修改的步驟名稱",
                                                                inputPlaceholder: "請輸入步驟名稱",
                                                                inputValue: this.model.steps[this.system_spell_index].f,
                                                                confirmButtonText: "確認",
                                                                showCancelButton: true,
                                                                cancelButtonText: "取消",
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    this.model.steps[this.system_spell_index].f = result.value,
                                                                        m.redraw()
                                                                }
                                                            });

                                                        }
                                                    }, [
                                                        m("p.m-0.px-2", {
                                                            style: {
                                                                overflow: "hidden",
                                                                "white-space": "nowrap",
                                                                "text-overflow": "ellipsis",
                                                            }
                                                        }, this.model.steps[this.system_spell_index].f)
                                                    ])
                                                ]),
                                                // //GPT4 -- 即將修正
                                                // m(".col-12.py-1", [
                                                //     m(".w-100.text-center", {
                                                //         style: {
                                                //             color: (this.model.steps[this.system_spell_index].hasOwnProperty('t') && this.model.steps[this.system_spell_index].t) ? "#FFFFFF" : "#5780C6",
                                                //             borderRadius: "25px",
                                                //             border: "2px solid #5780C6",
                                                //             fontWeight: 700,
                                                //             fontSize: "1rem",
                                                //             cursor: "pointer",
                                                //             backgroundColor: (this.model.steps[this.system_spell_index].hasOwnProperty('t') && this.model.steps[this.system_spell_index].t) ? "#5780C6" : "#FFFFFF"
                                                //         },
                                                //         onclick: (e) => {
                                                //             e.preventDefault()
                                                //             e.stopPropagation()
                                                //             if (this.model.steps[this.system_spell_index].x == "photo" || this.model.steps[this.system_spell_index].x == "view_photo") {
                                                //                 return
                                                //             }
                                                //             if (!this.model.steps[this.system_spell_index].hasOwnProperty('t')) {
                                                //                 this.model.steps[this.system_spell_index].t = true
                                                //             } else {
                                                //                 this.model.steps[this.system_spell_index].t = !this.model.steps[this.system_spell_index].t
                                                //             }

                                                //         }
                                                //     }, [
                                                //         m("span", (this.model.steps[this.system_spell_index].hasOwnProperty('t') && this.model.steps[this.system_spell_index].t ? "使用" : "不使用") + " GPT4o(即將無用)")
                                                //     ])
                                                // ]),
                                                //GPT Models
                                                (this.model.steps[this.system_spell_index].x != "BlackForestLab") ?
                                                    m(".col-4.py-1", [
                                                        m(".w-100.text-center", {
                                                            style: {
                                                                color: "#5780C6",
                                                                borderRadius: "25px",
                                                                border: "2px solid #5780C6",
                                                                fontWeight: 700,
                                                                fontSize: "1rem",
                                                                cursor: "pointer",
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                Swal.fire({
                                                                    title: "可使用的Model",
                                                                    input: "select",
                                                                    inputOptions: this.gpt_models,
                                                                    inputPlaceholder: "選擇要使用的Model",
                                                                    showCancelButton: true,
                                                                }).then((_response) => {
                                                                    if (_response.isConfirmed) {
                                                                        this.model.steps[this.system_spell_index].ug = _response.value
                                                                        m.redraw()
                                                                    }

                                                                });

                                                            }
                                                        }, [
                                                            m("span", this.model.steps[this.system_spell_index].ug)
                                                        ])
                                                    ]) : "",
                                                //Double GPT4
                                                (this.model.steps[this.system_spell_index].x == "dgpt") ?
                                                    [
                                                        m(".col-4.py-1", [
                                                            m(".w-100.text-center", {
                                                                style: {
                                                                    color: "#5780C6",
                                                                    borderRadius: "25px",
                                                                    border: "2px solid #5780C6",
                                                                    fontWeight: 700,
                                                                    fontSize: "1rem",
                                                                    cursor: "pointer",
                                                                },
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    this.model.steps[this.system_spell_index].oldData = !this.model.steps[this.system_spell_index].oldData

                                                                }
                                                            }, [
                                                                m("span", (this.model.steps[this.system_spell_index].oldData) ? "帶入舊資訊" : "不帶入舊資料")
                                                            ])
                                                        ]),
                                                    ] : "",
                                                //照片設定
                                                (this.model.steps[this.system_spell_index].hasOwnProperty('o')) ? [
                                                    (this.model.steps[this.system_spell_index].x == "BlackForestLab") ? [
                                                        m(".col-4.py-1", [
                                                            m(".w-100.text-center", {
                                                                style: {
                                                                    color: "#5780C6",
                                                                    borderRadius: "25px",
                                                                    border: "2px solid #5780C6",
                                                                    fontWeight: 700,
                                                                    fontSize: "1rem",
                                                                    cursor: "pointer",
                                                                },
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    Swal.fire({
                                                                        title: "請選擇圖片比例",
                                                                        input: "select",
                                                                        inputOptions: this.aspect_ratios,
                                                                        inputPlaceholder: "請選擇圖片比例",
                                                                        showCancelButton: true,
                                                                    }).then((_response) => {
                                                                        if (_response.isConfirmed) {
                                                                            this.model.steps[this.system_spell_index].o.aspect_ratio = _response.value
                                                                            m.redraw()
                                                                        }

                                                                    });

                                                                }
                                                            }, [
                                                                m("span", this.model.steps[this.system_spell_index].o.aspect_ratio)
                                                            ])
                                                        ])
                                                    ] : [
                                                        (this.model.steps[this.system_spell_index].x == "photo") ?
                                                            m(".col-4.py-1", [
                                                                m(".w-100.text-center", {
                                                                    style: {
                                                                        color: "#5780C6",
                                                                        borderRadius: "25px",
                                                                        border: "2px solid #5780C6",
                                                                        fontWeight: 700,
                                                                        fontSize: "1rem",
                                                                        cursor: "pointer",
                                                                    },
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        e.stopPropagation()
                                                                        if (this.model.steps[this.system_spell_index].o.q == "vivid") {
                                                                            this.model.steps[this.system_spell_index].o.q = "natural"
                                                                        } else {
                                                                            this.model.steps[this.system_spell_index].o.q = "vivid"
                                                                        }
                                                                    }
                                                                }, [
                                                                    m("span", this.model.steps[this.system_spell_index].o.q)
                                                                ])
                                                            ]) : "",
                                                        m(".col-4.py-1", [
                                                            m(".w-100.text-center", {
                                                                style: {
                                                                    color: "#5780C6",
                                                                    borderRadius: "25px",
                                                                    border: "2px solid #5780C6",
                                                                    fontWeight: 700,
                                                                    fontSize: "1rem",
                                                                    cursor: "pointer",
                                                                },
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    if (this.model.steps[this.system_spell_index].o.s == "1024x1024") {
                                                                        this.model.steps[this.system_spell_index].o.s = "1792x1024"
                                                                    } else if (this.model.steps[this.system_spell_index].o.s == "1792x1024") {
                                                                        this.model.steps[this.system_spell_index].o.s = "1024x1792"
                                                                    } else {
                                                                        this.model.steps[this.system_spell_index].o.s = "1024x1024"
                                                                    }
                                                                }
                                                            }, [
                                                                m("span", this.model.steps[this.system_spell_index].o.s)
                                                            ])
                                                        ])
                                                    ],
                                                ] : "",
                                                //刪除
                                                (this.model.steps[this.system_spell_index].x == "view_suggest" ||
                                                    this.model.steps[this.system_spell_index].x == "view_article" ||
                                                    this.model.steps[this.system_spell_index].x == "view_photo" ||
                                                    this.model.steps[this.system_spell_index].x == "view_data") ? [] : [
                                                    m(".col-4.pt-1.pb-1", [
                                                        m(".w-100.text-center", {
                                                            style: {
                                                                color: "#FF0000",
                                                                borderRadius: "25px",
                                                                border: "2px solid #FF0000",
                                                                fontWeight: 700,
                                                                fontSize: "1rem",
                                                                cursor: "pointer",
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                Swal.fire({
                                                                    title: "你確定要刪除此流程嗎？",
                                                                    text: "注意：此流程刪除後，該流程所附屬咒語及問題皆會消失！",
                                                                    showCancelButton: true,
                                                                    confirmButtonText: "確認",
                                                                    cancelButtonText: "取消",
                                                                    icon: "warning"
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        if (this.model.steps[this.system_spell_index].x == "article" ||
                                                                            this.model.steps[this.system_spell_index].x == "suggest" ||
                                                                            this.model.steps[this.system_spell_index].x == "photo" ||
                                                                            this.model.steps[this.system_spell_index].x == "DallE2" ||
                                                                            this.model.steps[this.system_spell_index].x == "BlackForestLab" ||
                                                                            this.model.steps[this.system_spell_index].x == "Template" ||
                                                                            this.model.steps[this.system_spell_index].x == "Markdown2Excel"
                                                                        ) {
                                                                            this.model.steps.splice(this.model.steps.indexOf(this.model.steps[this.system_spell_index]), 2)
                                                                        } else {
                                                                            this.model.steps.splice(this.model.steps.indexOf(this.model.steps[this.system_spell_index]), 1)
                                                                        }
                                                                        this.system_spell_index = null
                                                                        this.model.steps.map((_temp, index) => {
                                                                            _temp.a = index + 1
                                                                        })
                                                                        m.redraw()
                                                                    }
                                                                });
                                                            }
                                                        }, "刪除"),
                                                    ]),
                                                ],
                                                m(".col-12.py-2")
                                            ] : ""


                                        ]),

                                    ]),
                                    m(".py-2"),
                                    m(".row.w-100.m-0.position-relative.", {
                                        style: {
                                            backgroundColor: "#FAFAFA",
                                            boxShadow: "5px 4px 3px #B0B0B2",
                                            borderRadius: "25px",
                                            border: "1px solid #B0B0B2"
                                        }
                                    }, [
                                        (this.system_spell_index != null && !this.model.steps[this.system_spell_index].g) ?
                                            m(".text-primary.pl-3.position-absolute", {
                                                style: {
                                                    bottom: "20px",
                                                    right: "20px",
                                                    zIndex: 1024
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    Dialog.show(PreviewComponent, {
                                                        attrs: {
                                                            questions: this.model.steps[this.system_spell_index].s
                                                        },
                                                    })

                                                }
                                            }, m("i.fa.fa-eye", {
                                                style: {
                                                    fontSize: "4rem",
                                                    color: "#A3E0E2"
                                                },
                                            })
                                            ) : "",

                                        m(".col-12.text-center.py-2.d-flex.justify-content-center.align-items-center", [
                                            m("span", {
                                                style: {
                                                    fontSize: "1.2rem",
                                                    fontWeight: 800
                                                }
                                            }, "使用者問題"),
                                            // (this.system_spell_index != null && !this.model.steps[this.system_spell_index].g) ?
                                            (this.system_spell_index != null && !this.model.steps[this.system_spell_index].g) ?
                                                m("span.text-primary.pl-3", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        Dialog.show(QuestionComponent, {
                                                            attrs: {
                                                                model: new Question({
                                                                    model_id: this.model.id
                                                                }),
                                                            },
                                                            didHide: (returnValue) => {
                                                                if (returnValue) {
                                                                    this.model.steps[this.system_spell_index].s.push(returnValue)
                                                                    this.model.steps[this.system_spell_index].s.sort((a, b) => a.sort - b.sort);
                                                                    m.redraw()
                                                                }
                                                            }
                                                        })
                                                    }
                                                }, m("i.fa.fa-plus-circle", {
                                                    style: {
                                                        fontSize: "1.75rem"
                                                    },
                                                })
                                                ) : ""
                                        ]),
                                        (this.system_spell_index != null && !this.model.steps[this.system_spell_index].g) ? [
                                            this.model.steps[this.system_spell_index].s.map((_question, _index) => {
                                                return [
                                                    m(".col-12.py-2", [
                                                        m(".card", [
                                                            m(".card-body.px-0.py-1", [
                                                                m(".row.w-100.m-0", [
                                                                    m(".col-9.d-flex.align-items-center", [
                                                                        m("span", {
                                                                            style: {
                                                                                fontSize: "1rem",
                                                                                fontWeight: 600
                                                                            }
                                                                        }, (_index + 1) + ". "),
                                                                        m("input.form-control.mx-2", {
                                                                            style: {
                                                                                fontSize: "1rem",
                                                                                fontWeight: 600
                                                                            },
                                                                            type: 'text',
                                                                            value: _question.question,
                                                                            placeholder: "問題顯示",
                                                                            oninput: (e) => {
                                                                                _question.question = e.target.value
                                                                            },
                                                                        }),

                                                                    ]),


                                                                    m(".col-3.pr-3.d-flex.justify-content-between.align-items-center", [
                                                                        m("span.card-subtitle.text-muted", this.changeDisplayName(_question.type)),
                                                                        m("i.fa.fa-pencil.py-1.text-primary", {
                                                                            style: {
                                                                                fontSize: "1.5rem"
                                                                            },
                                                                            onclick: (e) => {
                                                                                e.preventDefault()
                                                                                e.stopPropagation()
                                                                                Dialog.show(QuestionComponent, {
                                                                                    attrs: {
                                                                                        model: _question,
                                                                                    },
                                                                                    didHide: (returnValue) => {
                                                                                        if (returnValue) {
                                                                                            _question = returnValue
                                                                                            this.model.steps[this.system_spell_index].s.sort((a, b) => a.sort - b.sort);
                                                                                            m.redraw()
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        }),
                                                                        m("i.fa.fa-trash-o.py-1.text-danger", {
                                                                            style: {
                                                                                fontSize: "1.5rem"
                                                                            },
                                                                            onclick: (e) => {
                                                                                e.preventDefault()
                                                                                e.stopPropagation()
                                                                                Swal.fire({
                                                                                    title: "你確定要刪除此變數嗎？",
                                                                                    showCancelButton: true,
                                                                                    confirmButtonText: "確認",
                                                                                    cancelButtonText: "取消",
                                                                                }).then((result) => {
                                                                                    if (result.isConfirmed) {
                                                                                        this.model.steps[this.system_spell_index].s.splice(_index, 1)
                                                                                        m.redraw()
                                                                                    }
                                                                                });
                                                                            }
                                                                        }),
                                                                    ]),
                                                                    (_question.type != "Photo") ? [
                                                                        m(".col-12.d-flex.align-items-center.pt-1", [
                                                                            m("input.pl-5.form-control.mx-3", {
                                                                                style: {
                                                                                    fontSize: "1rem",
                                                                                    fontWeight: 400
                                                                                },
                                                                                type: 'text',
                                                                                value: _question.title,
                                                                                placeholder: "題目名稱(GPT閱讀用)",
                                                                                oninput: (e) => {
                                                                                    _question.title = e.target.value
                                                                                },
                                                                            }),
                                                                        ])
                                                                    ] : [],
                                                                ]),


                                                            ]),
                                                        ]),

                                                    ]),
                                                ]
                                            }),
                                        ] : "",
                                        m(".col-12.py-5")

                                    ]),

                                ]),
                                (this.system_spell_index != null) ? [
                                    (this.model.steps[this.system_spell_index].x == "DallE2") ? [
                                        m(".col-md-6", [
                                            m(".row.w-100.m-0.border.d-flex.justify-content-start.flex-column", {
                                                style: {
                                                    minHeight: "300px",
                                                    backgroundColor: "#FAFAFA",
                                                    boxShadow: "5px 4px 3px #B0B0B2",
                                                    borderRadius: "25px",
                                                    border: "1px solid #B0B0B2"
                                                },
                                            }, [
                                                m(".col-12.text-center.py-2", [
                                                    m("span", {
                                                        style: {
                                                            fontSize: "1.2rem",
                                                            fontWeight: 800
                                                        }
                                                    }, "DELL-E 2 設定"),
                                                ]),
                                                m(".col-12.py-1", [
                                                    m(".w-100.text-center", {
                                                        style: {
                                                            color: "#5780C6",
                                                            borderRadius: "25px",
                                                            border: "2px solid #5780C6",
                                                            fontWeight: 700,
                                                            fontSize: "1rem",
                                                        },
                                                    }, "Image")
                                                ]),
                                                (this.model.steps[this.system_spell_index].s[0].image) ? [
                                                    m(".col-12.p-2.text-center.position-relative", [
                                                        m("img.img-fluid", {
                                                            src: this.model.steps[this.system_spell_index].s[0].image.path,
                                                            style: {
                                                                maxHeight: "240px"
                                                            },
                                                            oncreate: (e) => {
                                                                var img = new Image();
                                                                img.onload = (e) => { // now you can use e.target
                                                                    this.model.steps[this.system_spell_index].s[0].image.h = e.target.height
                                                                    this.model.steps[this.system_spell_index].s[0].image.w = e.target.width
                                                                    m.redraw()
                                                                };
                                                                img.src = this.model.steps[this.system_spell_index].s[0].image.path;
                                                            }
                                                        }),
                                                        m("span.position-absolute", {
                                                            style: {
                                                                top: "15px",
                                                                right: "15px",
                                                                fontWeight: 600,
                                                                fontSize: "1.25rem"
                                                            }
                                                        }, this.model.steps[this.system_spell_index].s[0].image.h + " * " + this.model.steps[this.system_spell_index].s[0].image.w)
                                                    ])
                                                ] : "",

                                                m('.col-12.pb-4', [
                                                    m(".w-100.text-center.py-2", {
                                                        style: {
                                                            color: "#000000",
                                                            border: "1px solid #ced4da",
                                                            fontWeight: 700,
                                                            cursor: "pointer",
                                                        },
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            $(`.file_image`).click()
                                                        }
                                                    }, m("i.fa-solid.fa-cloud-arrow-up.pr-2"), "上傳Image")
                                                ]),
                                                m(".col-12.py-1", [
                                                    m(".w-100.text-center", {
                                                        style: {
                                                            color: "#5780C6",
                                                            borderRadius: "25px",
                                                            border: "2px solid #5780C6",
                                                            fontWeight: 700,
                                                            fontSize: "1rem",
                                                        },
                                                    }, "Mask")
                                                ]),
                                                (this.model.steps[this.system_spell_index].s[0].mask) ? [
                                                    m(".col-12.p-2.text-center", [
                                                        m("img.img-fluid", {
                                                            src: this.model.steps[this.system_spell_index].s[0].mask.path,
                                                            style: {
                                                                maxHeight: "240px"
                                                            },
                                                            oncreate: (e) => {
                                                                var img = new Image();
                                                                img.onload = (e) => { // now you can use e.target
                                                                    this.model.steps[this.system_spell_index].s[0].mask.h = e.target.height
                                                                    this.model.steps[this.system_spell_index].s[0].mask.w = e.target.width
                                                                    m.redraw()
                                                                };
                                                                img.src = this.model.steps[this.system_spell_index].s[0].mask.path;
                                                            }
                                                        }),
                                                        m("span.position-absolute", {
                                                            style: {
                                                                top: "15px",
                                                                right: "15px",
                                                                fontWeight: 600,
                                                                fontSize: "1.25rem"
                                                            }
                                                        }, this.model.steps[this.system_spell_index].s[0].mask.h + " * " + this.model.steps[this.system_spell_index].s[0].mask.w)
                                                    ])
                                                ] : "",

                                                m('.col-12.pb-4', [
                                                    m(".w-100.text-center.py-2", {
                                                        style: {
                                                            color: "#000000",
                                                            border: "1px solid #ced4da",
                                                            fontWeight: 700,
                                                            cursor: "pointer",
                                                        },
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            $(`.file_mask`).click()
                                                        }
                                                    }, m("i.fa-solid.fa-cloud-arrow-up.pr-2"), "上傳Mask")
                                                ]),


                                                m(`input.g-file_browser.file_image[type="file"][name="files[]"][accept="image/*"]`, {
                                                    onchange: (e) => {
                                                        if (e.target.files.length === 0) {
                                                            return false
                                                        }

                                                        if (!this.uploader.filterImageExtensionByType(e.target.files, [".png"])) {
                                                            alert('不支援上傳的檔案格式, 請選擇圖片檔!')
                                                            return false
                                                        }

                                                        this.uploader.uploadImage(e.target.files[0], {
                                                            url: `${window.BASE_URL}/api/upload/photo`,
                                                            width: 480,
                                                            height: 480,
                                                            folder: "DallE2",
                                                            type: "photo",
                                                            preview: "DallE2"
                                                        }).then((response) => {
                                                            this.model.steps[this.system_spell_index].s[0].image = response
                                                            m.redraw()
                                                        })
                                                    }
                                                }),

                                                m(`input.g-file_browser.file_mask[type="file"][name="files[]"][accept="image/*"]`, {
                                                    onchange: (e) => {
                                                        if (e.target.files.length === 0) {
                                                            return false
                                                        }

                                                        if (!this.uploader.filterImageExtensionByType(e.target.files, [".png"])) {
                                                            alert('不支援上傳的檔案格式, 請選擇圖片檔!')
                                                            return false
                                                        }

                                                        this.uploader.uploadImage(e.target.files[0], {
                                                            url: `${window.BASE_URL}/api/upload/photo`,
                                                            width: 480,
                                                            height: 480,
                                                            folder: "DallE2",
                                                            type: "photo",
                                                            preview: "DallE2"
                                                        }).then((response) => {
                                                            this.model.steps[this.system_spell_index].s[0].mask = response
                                                            m.redraw()
                                                        })
                                                    }
                                                }),





                                            ])
                                        ])
                                    ] : [
                                        //咒語部分
                                        m(".col-md-6", [
                                            m(".row.w-100.m-0.border.d-flex.justify-content-start.flex-column", {
                                                style: {
                                                    minHeight: "300px",
                                                    backgroundColor: "#FAFAFA",
                                                    boxShadow: "5px 4px 3px #B0B0B2",
                                                    borderRadius: "25px",
                                                    border: "1px solid #B0B0B2"
                                                },
                                                // oncontextmenu: (e) => {
                                                //     return false;
                                                // },
                                                onclick: (e) => {
                                                    this.spell_menu = false
                                                    m.redraw()
                                                }

                                            }, [
                                                m(".col-12.text-center.py-2", [
                                                    m("span", {
                                                        style: {
                                                            fontSize: "1.2rem",
                                                            fontWeight: 800
                                                        }
                                                    }, "系統咒語"),
                                                    (this.system_spell_index != null && this.model.steps[this.system_spell_index].g) ?
                                                        m("span", {
                                                            style: {
                                                                fontSize: "1.2rem",
                                                                fontWeight: 800
                                                            }
                                                        }, " - 步驟" + (this.system_spell_index + 1)) : "",
                                                ]),
                                                m(".col-12.py-2", [
                                                    (this.system_spell_index != null && this.model.steps[this.system_spell_index].g) ? [
                                                        m('textarea.form-control.w-100', {
                                                            style: {
                                                                minHeight: '750px'
                                                            },
                                                            oninput: (e) => {
                                                                this.model.steps[this.system_spell_index].s[0].v = e.target.value
                                                            },
                                                        }, this.model.steps[this.system_spell_index].s[0].v),
                                                    ] : [],
                                                ]),
                                            ]),
                                        ]),
                                    ]
                                ] : ""
                            ]),

                        ]),
                    ])
                ]),
            ] : "",
            m(Dialog),
        ]
    }
}
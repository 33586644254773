import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea,
    Paging
} from 'components'
import * as Components from 'components'
import {
    User,
} from "app/models"
import moment from 'moment'
import ArticleDetailComponent from './articleDetail'




class ArticleDialog {
    constructor(vnode) {
        this.records = [];
        User.getArticleRecord()
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return m('.modal-dialog.modal-dialog-centered.modal-dialog-scrollable', [
            m('.modal-content', [
                m('.modal-header', {
                    style: {
                        backgroundColor: "#B6B6B6"
                    }
                }, [
                    m('h5.modal-title', [
                        m('span', "文章紀錄")
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body.p-0", [
                    m(".row.m-0.w-100.pt-2",[
                        m(".col-12.pb-1.d-flex.justify-content-end.align-items-center", [
                            m("i.fa-regular.fa-hand-point-down", { style: { color: "#3027A1", fontSize: "0.8rem" } }),
                            m("span.font-weight-bold.pl-2", { style: { color:"#3027A1",fontSize: "0.8rem" } }, "點擊即可查看紀錄"),
                        ]),
                        User.articleRecord.map((_record, _index) => {
                            return [
                                m(".col-12.pb-1", [
                                    m(".row.m-0.w-100.border.py-1",{
                                        onclick: (e) => {
                                            e.preventDefault()
                                            Dialog.show(ArticleDetailComponent, {
                                                attrs: {
                                                    model: _record,
                                                },
                                            })
                                        }
                                    }, [
                                        m(".col-1.d-flex.justify-content-start.align-items-center", [
                                            m("span.font-weight-bold", { style: { fontSize: "1rem" } }, _index + 1)]),
                                        m(".col-6.d-flex.justify-content-start.align-items-center", [
                                            m("span.font-weight-bold", { style: { fontSize: "1rem" } }, _record.model_name)]),
                                        // m(".col-2.d-flex.justify-content-start.align-items-center",{
                                        //     onclick:(e)=>{
                                        //         e.preventDefault()
                                        //         Dialog.show(ArticleDetailComponent, {
                                        //             attrs: {
                                        //                 model: _record,
                                        //             },
                                        //         })
                                        //     }
                                        // }, [
                                        //     m("span.font-weight-bold", { style: { color: "grey", fontSize: "1rem" } }, "查看")
                                        // ]),
                                        m(".col-5.d-flex.justify-content-end.align-items-center", [
                                            m("span", { style: { color: "grey", fontSize: "0.8rem" } }, moment(_record.created_at).format("Y-MM-DD hh:mm"))
                                        ]),

                                    ]),
                                ])
                            ];
                        })
                    ]),
                    m('.row.no-gutters.px-5', [
                        m('.col-12.pt-3', [
                            m('.d-flex.flex-sm-row.flex-column.justify-content-md-between', [
                                m(Paging.Pagination, {
                                    pageNo: User.paging.pageNo,
                                    pageCount: User.paging.pageCount,
                                    pageUrl: User.getArticleRecord.bind(this)
                                }),
                                // m(Paging.PageItemCount, {
                                //     pageNo: User.paging.pageNo,
                                //     pageSize: User.paging.pageSize,
                                //     total: User.paging.totalRecordCount
                                // })
                            ])
                        ])
                    ]),
                ]),
                m('.modal-footer', [
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            fontWeight: 700,
                            fontSize: "1.25rem"
                        },
                        onclick: this.close.bind(this)
                    }, '關閉')
                ]),
            ])
        ])
    }
}

export default ArticleDialog
import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
    TextBox,
    Button,
    TextArea,
    Paging
} from 'components'
import * as Components from 'components'
import {
    User,
} from "app/models"
import moment from 'moment'
import ArticleDetailComponent from './articleDetail'
import classNames from 'classnames'




class RenewRecordDialog {
    constructor(vnode) {
        this.records = [];
        User.getRenewRecord()
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }
    change_level(level) {
        switch (level) {
            case 2:
                return m("span", { style: { color: "#000000" } }, "入門使用");
            case 3:
                return m("span", { style: { color: "#000000" } }, "進階使用");
            case 4:
                return m("span", { style: { color: "#000000" } }, "專業使用");
        }
    }

    change_status(status) {
        switch (status) {
            case 1:
                return "前往付款";
            case 2:
                return "已完成";
            case 3:
                return "已取消";
            case 4:
                return "申訴中";
            case 5:
                return "已退款";
            case 99:
                return m("span", { style: { color: "#FF0000" } }, "付款失敗");
        }
    }

    view({
        attrs
    }) {
        return m('.modal-dialog.modal-xl.modal-dialog-centered.modal-dialog-scrollable', [
            m('.modal-content', [
                m('.modal-header', {
                    style: {
                        backgroundColor: "#B6B6B6"
                    }
                }, [
                    m('h5.modal-title', [
                        m('span', "續約紀錄")
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body.p-0", [
                    m(".row.m-0.w-100.pt-2", [
                        // m(".col-12.pb-1.d-flex.justify-content-end.align-items-center", [
                        //     m("i.fa-regular.fa-hand-point-down", { style: { color: "#3027A1", fontSize: "0.8rem" } }),
                        //     m("span.font-weight-bold.pl-2", { style: { color:"#3027A1",fontSize: "0.8rem" } }, "點擊即可查看紀錄"),
                        // ]),
                        User.renewRecord.map((_record, _index) => {
                            return [
                                m(".col-12.pb-1", [
                                    m(".row.m-0.w-100.border.py-1", {
                                        // onclick: (e) => {
                                        //     e.preventDefault()
                                        //     Dialog.show(ArticleDetailComponent, {
                                        //         attrs: {
                                        //             model: _record,
                                        //         },
                                        //     })
                                        // }
                                    }, [
                                        m(".col-1.d-flex.justify-content-start.align-items-center", [
                                            m("span.font-weight-bold", { style: { fontSize: "1rem" } },(_index + 1 ) + "." )]),
                                        m(".col-4.d-flex.justify-content-start.align-items-center", [
                                            m("span.font-weight-bold", { style: { fontSize: "1rem" } }, _record.serial)]),

                                        m(".col-4.d-flex.justify-content-center.align-items-center", [
                                            m("span.font-weight-bold", { style: { fontSize: "1rem" } }, "$ " + _record.cost)]),
                                        m(".col-3.d-flex.justify-content-center.align-items-center.py-1.px-0", [
                                            m("span.font-weight-bold.py-0", {
                                                class: classNames({
                                                    'btn': _record.status == 1,
                                                    'btn-outline-danger': _record.status == 1
                                                }),
                                                style: {
                                                    fontSize: ".9rem"
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    if (_record.status == 1) {
                                                        window.location.href = `${window.BASE_URL}/goEcpay?serial=${_record.serial}`
                                                    }
                                                }
                                            }, this.change_status(_record.status))]),


                                        m(".col-6.d-flex.justify-content-center.align-items-center", [
                                            m("span.font-weight-bold", { style: { fontSize: "1rem" } }, this.change_level(_record.level))]),
                                        m(".col-6.d-flex.justify-content-end.align-items-center", [
                                            m("span", { style: { color: "grey", fontSize: "0.8rem" } }, moment(_record.created_at).format("Y-MM-DD hh:mm"))
                                        ]),
                                    ]),
                                ])
                            ];
                        })
                    ]),
                    m('.row.no-gutters.px-5', [
                        m('.col-12.pt-3', [
                            m('.d-flex.flex-sm-row.flex-column.justify-content-md-between', [
                                m(Paging.Pagination, {
                                    pageNo: User.paging.pageNo,
                                    pageCount: User.paging.pageCount,
                                    pageUrl: User.getRenewRecord.bind(this)
                                }),
                                m(Paging.PageItemCount, {
                                    pageNo: User.paging.pageNo,
                                    pageSize: User.paging.pageSize,
                                    total: User.paging.totalRecordCount
                                })
                            ])
                        ])
                    ]),
                ]),
                m('.modal-footer', [
                    m(".text-center.py-1.px-3", {
                        style: {
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            fontWeight: 700,
                            fontSize: "1.25rem"
                        },
                        onclick: this.close.bind(this)
                    }, '關閉')
                ]),
            ])
        ])
    }
}

export default RenewRecordDialog
import m from 'mithril';
import stream from 'mithril/stream';
import { Dialog, TextBox, Button, TextArea } from 'components';
import { Authority } from 'app/models';
import moment from 'moment';
import classNames from 'classnames';

export default class StepEditDialog {
    constructor(vnode) {
        this.types = [
            { name: "GPT 建議", value: "suggest" },
            { name: "GPT 文章", value: "article" },
            { name: "GPT 生圖", value: "photo" },
            { name: "User Input", value: "view" },
            { name: "GPT Word", value: "word" },
            { name: "GPT Excel", value: "excel" },
            { name: "GPT PDF", value: "pdf" },
            { name: "Double GPT", value: "dgpt" },
            { name: "DallE2", value: "DallE2" },
            { name: "BlackForestLab", value: "BlackForestLab" },
            { name: "套版", value: "Template" },
            { name: "Md轉Excel", value: "Markdown2Excel" },
            
        ];
        this.photo_size = ["1024x1024", "1792x1024", "1024x1792"];
        this.photo_style = ["vivid", "natural"];
        this.model = {
            type: null,
            f: null, // 流程名稱
            x: 'view',
            g: false,
            t: false,
            s: [],
        };
    }

    close(event, value) {
        setTimeout(() => {
            Dialog.close(false);
            m.redraw();
        }, 200);
    }

    save() {
        Dialog.close(this.model);
    }

    view({ attrs }) {
        return m('.modal-dialog.modal-dialog-centered', [
            m('.modal-content', [
                m('.modal-header', [
                    m('h5.modal-title', this.model.id == 0 ? "新增模板" : "編輯模板"),
                    m('button[type="button"].close', { onclick: this.close.bind(this) }, m('span', m.trust('&times;')))
                ]),
                m('.modal-body', [
                    m('.container-fluid', [
                        m('.row.d-flex.justify-content-center', [
                            this.types.map(_type => (
                                m('.col-4.p-2', [
                                    m('.py-1.w-100.text-center', {
                                        style: {
                                            color: this.model.type == _type.value ? "#FFFFFF" : "#59B3A7",
                                            borderRadius: "25px",
                                            border: "1px solid #59B3A7",
                                            fontWeight: 500,
                                            fontSize: "1rem",
                                            cursor: "pointer",
                                            background: this.model.type == _type.value ? "#59B3A7" : "#FFFFFF",
                                        },
                                        onclick: () => {
                                            this.model.type = _type.value;
                                            if (_type.value == "photo") {
                                                this.model.t = true;
                                                this.model.o = {
                                                    s: "1024x1024",
                                                    q: "vivid"
                                                };
                                            } else if (_type.value == "DallE2") {
                                                this.model.t = true;
                                                this.model.o = {
                                                    s: "1024x1024",
                                                    q: "vivid",
                                                };
                                            } else if (_type.value == "BlackForestLab") {
                                                this.model.t = true;
                                                this.model.o = {
                                                    guidance: "3.5",
                                                    aspect_ratio: "1:1",
                                                    output_quality:"80"
                                                };
                                            }
                                        }
                                    }, _type.name)
                                ])
                            )),
                            m('.col-12.py-3', m(TextBox, {
                                oninput: e => this.model.f = e.target.value,
                                value: this.model.f,
                                type: 'text',
                                label: "流程名稱",
                                required: true,
                            })),
                            // m('.col-5', m('.form-radio.form-radio-flat.my-2.d-flex.justify-content-center.align-items-center', [
                            //     m('label.form-check-label.pl-2', { style: { fontSize: "1.2rem", fontWeight: 700 } }, [
                            //         m('input.form-check-input[type="radio"]', {
                            //             checked: this.model.t,
                            //             onclick: () => {
                            //                 if (this.model.type != "photo") {
                            //                     this.model.t = !this.model.t;
                            //                 }
                            //             }
                            //         }),
                            //         "使用GPT4",
                            //         m('i.input-helper')
                            //     ])
                            // ])),
                            (this.model.type == "photo" || this.model.type == "DallE2") && [
                                m('.col-12.pb-3', [
                                    m('label.pl-2', { style: { fontSize: "1rem", fontWeight: 700 } }, "Size"),
                                    m('.row.w-100.m-0', this.photo_size.map(_size => (
                                        m('.col-4.p-2', [
                                            m('.py-1.w-100.text-center', {
                                                style: {
                                                    color: this.model.o.s == _size ? "#FFFFFF" : "#59B3A7",
                                                    borderRadius: "25px",
                                                    border: "1px solid #59B3A7",
                                                    fontWeight: 500,
                                                    fontSize: "1rem",
                                                    cursor: "pointer",
                                                    background: this.model.o.s == _size ? "#59B3A7" : "#FFFFFF"
                                                },
                                                onclick: () => this.model.o.s = _size
                                            }, _size)
                                        ])
                                    )))
                                ]),
                                (this.model.type == "photo")?
                                m('.col-12.pb-3', [
                                    m('label.pl-2', { style: { fontSize: "1rem", fontWeight: 700 } }, "Style"),
                                    m('.row.w-100.m-0', this.photo_style.map(_style => (
                                        m('.col-3.p-2', [
                                            m('.py-1.w-100.text-center', {
                                                style: {
                                                    color: this.model.o.q == _style ? "#FFFFFF" : "#59B3A7",
                                                    borderRadius: "25px",
                                                    border: "1px solid #59B3A7",
                                                    fontWeight: 500,
                                                    fontSize: "1rem",
                                                    cursor: "pointer",
                                                    background: this.model.o.q == _style ? "#59B3A7" : "#FFFFFF"
                                                },
                                                onclick: () => this.model.o.q = _style
                                            }, _style)
                                        ])
                                    )))
                                ]):""
                            ],
                            m('.w-100')
                        ])
                    ])
                ]),
                m('.modal-footer', [
                    m('.text-center.py-1.px-3', {
                        style: {
                            color: "#41BCC3",
                            borderRadius: "25px",
                            border: "2px solid #41BCC3",
                            fontWeight: 700,
                            fontSize: "1.25rem",
                            cursor: "pointer",
                        },
                        onclick: e => {
                            e.preventDefault();
                            this.save();
                        }
                    }, '儲存'),
                    m('.p-1'),
                    m('.text-center.py-1.px-3', {
                        style: {
                            color: "#B6B6B6",
                            borderRadius: "25px",
                            border: "2px solid #B6B6B6",
                            fontWeight: 700,
                            fontSize: "1.25rem",
                            cursor: "pointer",
                        },
                        onclick: this.close.bind(this)
                    }, '關閉')
                ])
            ])
        ]);
    }
}
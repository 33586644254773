import m from 'mithril'
import moment from 'moment'
import {
    Dialog,
    TextBox
} from 'components'
import * as Components from 'components'
import Uploader from 'components/uploader/core'
import {
    FBPost,
    User
} from 'app/models'
import classNames from 'classnames';


export default class PaySuccessPage {
    constructor() {
        this.model = JSON.parse(window.Data_Temp)
        dataLayer.push({
            'event': 'payment',
            'user_id': User.Account.id,
            'payment_type': this.model.PaymentType,
            'payment_cost': this.model.TradeAmt,
            'payment_RtnMsg': this.model.RtnMsg,
        });
        
        swal.fire({
            title: "付款完成",
            text: "感謝您的購買，請盡情享受APE AI帶來的便利!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1800
        });
    }
    view() {
        return [
            m(".px-2.py-4",
                m(".row.w-100.m-0", [
                    m(".col-12.d-flex.justify-content-center.py-3", [
                        m("img.img-fluid", {
                            src: `${window.BASE_URL}/img/paymen.webp`,
                            style: {
                                maxHeight: "360px"
                            }
                        })
                    ]), 
                    m(".col-12.d-flex.justify-content-center.py-3", [
                        m("span.pt-2", { style: { fontSize: "1.2rem", color: "#000000", fontWeight: 600 } }, this.model.CustomField1)
                    ]), 
                    m(".col-12.d-flex.justify-content-center.py-3", [
                        m("span.pt-2", { style: { fontSize: "1.2rem", color: "#000000", fontWeight: 600 } }, this.model.RtnMsg)
                    ]),
                    m(".col-12.d-flex.justify-content-center.py-3", [
                        m("span.pt-2", { style: { fontSize: "1.2rem", color: "#000000", fontWeight: 600 } }, this.model.PaymentType)
                    ]),
                    m(".col-12.d-flex.justify-content-center.py-3",[
                        m(".d-inline-block.px-3.py-1", {
                            style: {
                                color: "#3b5998",
                                fontSize: "1rem",
                                borderRadius: "25px",
                                border: "2px solid #3b5998",
                                fontWeight: 700,
                            },
                            onclick: (e) => {
                                e.preventDefault()
                                m.route.set("/")
                            }
                        }, [
                            m("span", "返回首頁"),
                        ]),
                    ]),
                    
                                                
                                            
                ]),

            ),
            m(Dialog),

        ]
    }
}